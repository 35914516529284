<div class="hero ">
</div>

<div class="popular">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <h4>Privacy Policy.</h4>
            </div>
        </div>
    </div>
</div>

<div class="">
    <div class="container pt-5 pb-5">
        <div class="row">

            <div class="col-12">
                <p>
                    <b> Background</b><br>
                    FronteraSky understands and agrees that your privacy is important to you and that you care about how
                    your information is used and shared online. We respect and value the privacy of everyone who visits
                    Our Site and will only collect and use information in ways that are useful to you and in a manner
                    consistent with your rights and our obligations under the law.
                    <br><br>
                    This Policy applies to our use of any and all data collected by us in relation to your use of our
                    site. Please read this Privacy Policy carefully and ensure that you understand it. Your acceptance
                    of our Privacy Policy is deemed to occur upon your first use of our site. If you do not accept and
                    agree with this Privacy Policy, you must stop using our site immediately. This policy is effective
                    August 5, 2020.
                    <br><br>
                    <b>1. Definitions and Interpretation </b><br>
                    In this Policy the following terms shall have the following meanings: <br>
                    “Account” means an account required to access and/or use certain areas and features of Our Site;<br>
                    “Cookie” means a small text file placed on your computer or device by Our Site when you visit
                    certain parts of Our Site and/or when you use certain features of Our Site. Details of the Cookies
                    used by Our Site are set out in section 12, below;
                    <br><br>
                    “Our Site” means the following websites: <br>
                    • fronterasky.com<br>
                    • booking.fronterasky.com<br>
                    “We/Us/Our” means FronteraSky LLC and FronteraSky Aviation Services LLC, limited liability companies
                    incorporated in the state of Florida, United States of America, Registration L20000118441, with a
                    registered address at 6526 S Kanner Hwy. Suite 255, Stuart, FL, United States and in Dubai, United
                    Arab Emirates, license number 891161 with a registered address at Office 3208, MediaOne Tower, Media
                    City, Dubai respectively.<br><br>

                    <b>2. Information About Us </b><br>
                    1. Our Site, www.fronterasky.com, represents FronteraSky LLC and FronteraSky Aviation Services LLC,
                    limited liability companies incorporated
                    in the state of Florida, United States of America, Registration ID L20000118441, with a registered
                    address at 6526 S Kanner Hwy. Suite 255, Stuart, FL 34997, United States and in Dubai, United Arab
                    Emirates, license number 891161 with a registered address at Office 3208, MediaOne Tower, Media
                    City, Dubai respectively.<br>
                    2. We can be contacted at info@fronterasky.com.
                    <br><br>
                    <b> 3. Scope – What Does This Policy Cover? </b><br>
                    This Privacy Policy applies only to your use of Our Site. It does not extend to any external
                    websites that are linked to from Our Site (whether We provide those links or whether they are shared
                    by other users). We have no control over how your data is collected, stored or used by other
                    websites and We advise you to check the privacy policies of any such websites before providing any
                    data to them.
                    <br><br>
                    <b>4. What Data Do We Collect? </b><br>
                    Some data will be collected automatically by Our Site (see our Cookie Policy in section 12), other
                    data will only be collected if you voluntarily submit it and consent to us using it for the purposes
                    set out in section 5, for example, when requesting a quote or when requesting information. Depending
                    upon your use of Our Site, We may collect some or all of the following data:
                    <br><br>
                    1. Client contact information such as Name, address, email addresses, and telephone numbers
                    (collected for each lead).<br>
                    2. Client passenger information such as name, date of birth, place of birth, citizenship, residence,
                    passport numbers (collected only when clients have booked a flight).<br>
                    3. Client financial information such as credit / debit cards, ID, Passport, bank accounts (collected
                    only when clients have booked a flight).<br>
                    4. IP address (automatically collected);<br>
                    5. Web browser type and version (automatically collected);<br>
                    6. Operating system (automatically collected);<br>
                    7. A list of URLs starting with a referring site, your activity on Our Site, and the site you exit
                    to (automatically collected);
                    <br><br>
                    <b> 5. How Do We Use Your Data?</b> <br>
                    1. All personal data is stored securely in accordance with the EU General Data Protection Regulation
                    (Regulation (EU) 2016/679) (GDPR). For more details on security see section 6, below.<br>
                    2. We use your data to provide the best possible journey planning services to you. If you request a
                    quote or service from us, we request certain personally identifiable information from you on our
                    quote form. You must provide contact information such as name, email, and phone number. The
                    information you enter on our quoting system is stored by us. We use this information for quoting,
                    selling and to contact you via phone and/or email to communicate your service requests and process
                    your trip details or other services you request. More specifically, we use your data for the
                    following purposes:<br>
                    1. Responding to client requests for trip services.<br>
                    2. Responding to client requests for leasing or aircraft sales.<br>
                    3. Providing a contractual agreement for trip services and/or leasing and/or aircraft sales.<br>
                    4. Communicating special offers and updates such as charter and empty leg specials.<br>
                    5. Supplying you with emails that you consent to receiving e.g. newsletters, alerts, marketing
                    promotions and market research, etc. You may unsubscribe or opt-out at any time by clicking on the
                    Unsubscribe link on these emails or by contacting us at info@fronterasky.com.<br>
                    6. Communicating service related announcements such as notices by email of temporary suspension of
                    service due to web site maintenance.<br>
                    7. Providing customer service in response to your requests for service.<br>
                    8. Personalizing and tailoring your experience on Our Site.<br>
                    9. Personalizing and tailoring Our products and services for you.<br>
                    10. Analyzing your use of Our Site, and gathering feedback, to enable us to continually improve our
                    site and your user experience.<br>

                    3. In some cases, the collection of data may be a statutory or contractual requirement, and we will
                    be limited in the services we can provide you without your consent for us to be able to use such
                    data.<br>
                    4. With your permission and/or where permitted by law, we may also use your data for marketing
                    purposes which may include contacting you by email and/or telephone and/or text message and/or post
                    with information, news and offers on our products and/or services. We will not, however, send you
                    any unsolicited marketing or spam and will take all reasonable steps to ensure that We fully protect
                    your rights and comply with Our obligations under the GDPR and the Privacy and Electronic
                    Communications (EC Directive) Regulations 2003, as amended in 2004, 2011 and 2015.<br>
                    5. Advertisers whose content appears on Our Site may engage in what is known as “behavioral
                    advertising” – advertising which is tailored to your preferences, based on your activity. Your
                    activity is monitored using Cookies, as detailed below in section 12. You can control and limit your
                    data used in this way by adjusting your web browser’s privacy settings. Please note that we do not
                    control the activities of such advertisers, nor the information they collect and use. Limiting the
                    use of your data in this way will not remove the advertising, but it will make it less relevant to
                    your interests and activities on Our Site.<br>
                    6. If you are providing personal data to us about someone else, that you confirm that they have
                    appointed you to act for them to consent to the processing of their personal data, and that you have
                    informed them of our identity, of this Policy, and of the purposes (as set out in this Policy) for
                    which their personal data will be processed.<br>
                    7. We may monitor or record telephone conversations to enhance your security and ours, to enable us
                    to handle complaints efficiently, improve our client service and for staff training purposes. If
                    your telephone conservation with us will be recorded then you will receive a message to this effect
                    prior to the start of your conservation with us.<br>
                    8. Under GDPR we will ensure that your personal data is processed lawfully, fairly, and
                    transparently, without adversely affecting your rights. We will only process your personal data if
                    at least one of the following basis applies:<br>
                    a) You have given consent to the processing of your personal data for one or more specific
                    purposes;<br>
                    b) Processing is necessary for the performance of a contract to which you are a party or in order to
                    take steps at the request of you prior to entering into a contract;<br>
                    c) Processing is necessary for compliance with a legal obligation to which we are subject;<br>
                    d) Processing is necessary to protect the vital interests of you or of another natural person;<br>
                    e) Processing is necessary for the performance of a task carried out in the public interest or in
                    the exercise of official authority vested in the controller; and/or <br>
                    f) Processing is necessary for the purposes of the legitimate interests pursued by us or by a third
                    party, except where such interests are overridden by the fundamental rights and freedoms of the data
                    subject which require protection of personal data. In particular where the data subject is a child,
                    we will only provide the child’s data when regulated by law or by parental/guardian consent; e.g.
                    passenger data for flights, customs, etc.<br><br>

                    <b>6. How and Where Do We Store Your Data? </b><br>
                    1. We keep your data in order to use it as described above in section 5, and presently we store your
                    data indefinitely. If requested, we will delete your data from our databases and unsubscribe you
                    from our marketing emails and newsletters. In any event, we will conduct an annual review to
                    ascertain whether we need to keep your data. Your date will be deleted if we do not have any contact
                    or communication with you after 24 months.<br>
                    2. We store information that we collect through cookies and log files to create a profile of your
                    service preferences to enhance your user experience on our website and store your data, so you do
                    not need to re-enter data each time you enter our web site or request new services. We store your
                    data permanently for accounting, legal and regulatory requirements. Our user data is stored in the
                    United States and in the United Arab Emirates.<br>
                    3. Data security is of great importance to us, and to protect your data we have put in place
                    suitable physical, electronic and managerial procedures to safeguard and secure data collected
                    through Our Site.<br>
                    4. Notwithstanding the security measures that We take, it is important to remember that the
                    transmission of data via the internet may not be completely secure and that you are advised to take
                    suitable precautions when transmitting data to Us via the internet.<br><br>

                    <b>7. Do We Share Your Data? </b> <br>
                    1. We do not sell or provide your profile and data with third party advertising and marketing
                    entities.<br>
                    2. We may contract with third parties to supply products and services to you. These may include
                    payment processing, provision of services, search engine facilities, and marketing. In some cases,
                    the third parties may require access to some or all of your data. Where any of your data is required
                    for such a purpose, we will take all reasonable steps to ensure that your data will be handled
                    safely, securely, and in accordance with your rights, our obligations, and the obligations of the
                    third party under the law. These third parties are prohibited from using your personal data for any
                    other purposes. We may disclose your personal data (and any third party personal data provided by
                    you to us) to:<br>
                    • We use a bulk email transmission service and share email addresses with them to process
                    newsletters, transactional, and marketing emails to our clients.<br>
                    • We use online storage services to store documents, client data, as well as backups.<br>
                    • We use a telephone service entity to validate phone numbers.<br>
                    • We use an email validation service to verify validity of email addresses.<br>
                    • We provide passenger information as required by regulatory requirements to Aircraft Operators.<br>
                    • We provide client data only to the extent required to arrange White Glove services – in most cases
                    this is only the name and phone number of the primary traveler.<br>
                    • We use credit card to process client credit card payments.<br>
                    • We use third-party customer review services to reach out to clients for feedback on our services.<br>
                    • We use an electronic signature service to process client contracts via electronic devices.<br>
                    • We use and provide data to Accounting software services to maintain and report accounting records
                    and client transactions.<br>
                    • We use banking services to process client incoming and outgoing bank wire transactions.<br><br>

                    3. We may compile statistics about the use of our site including data on traffic, usage patterns,
                    user numbers, and other information. All such data will be anonymized and will not include any
                    personally identifying information. We may from time to time share such data with third parties such
                    as prospective investors, affiliates, partners and advertisers. Data will only be shared and used
                    within the bounds of the law.<br>
                    4. In certain circumstances we may be legally required to share certain data held by us, which may
                    include your personal information, for example, where we are involved in legal proceedings, where we
                    are complying with the requirements of legislation, a court order, or a governmental authority. We
                    do not require any further consent from you in order to share your data in such circumstances and
                    will comply as required with any legally binding request that is made of us.<br><br>

                    <b>8. What Happens If Our Business Changes Hands? </b><br>
                    1. We may, from time to time, expand or reduce our business and this may involve the sale and/or the
                    transfer of control of all or part of our business. Data provided by users will, where it is
                    relevant to any part of our business so transferred, be transferred along with that part and the new
                    owner or newly controlling party will, under the terms of this Privacy Policy, be permitted to use
                    the data for the purposes for which it was originally collected by Us.<br>
                    2. In the event that any of your data is to be transferred in such a manner, you will be contacted
                    and informed of the changes. The new owner or controller will contact you with regards to having
                    your data stored or deleted with your consent.<br><br>

                    <b>9. How Can You Control Your Data? </b><br>
                    1. When you submit information via Our Site, you may be given options to restrict our use of your
                    data and/or provide consent to use your data. We aim to give you strong controls on our use of your
                    data (including the ability to opt-out of receiving emails from us which you may do by unsubscribing
                    using the links provided in our emails, declining consent at the point of providing your details,
                    and/or by sending your data modification/deletion requests to info@fronterasky.com.<br>
                    2. You may also wish to sign up to one or more of the preference services operating in your country.
                    These may help to prevent you receiving unsolicited marketing. Please note, however, that these
                    services will not prevent you from receiving marketing communications that you have consented to
                    receiving.<br><br>

                    <b> 10. Your Right to Withhold Information and Your Right to Withdraw Information After You Have Given
                    it.</b><br>
                    1. You may access certain areas of Our Site without providing any data at all. However, to use all
                    features and functions available on Our Site you may be required to submit or allow for the
                    collection of certain data.<br>
                    2. You may restrict your internet browser's use of Cookies. For more information, see section 12.<br>
                    3. You may withdraw your consent for Us to use your personal data as set out in section 5 at any
                    time by contacting us using the details set out in section 15, and We will delete Your data from Our
                    systems. However, you acknowledge this may limit Our ability to provide the best possible products
                    and services to you.
                    <br><br>
                    <b> 11. How Can You Access Your Data?</b><br>
                    You have the legal right to ask for a copy of any of your personal data held by Us (where such data
                    is held). Please contact us for more details at info@fronterasky.com or using the contact details
                    below in section 14.
                    <br><br>
                    <b> 12. Cookies </b> <br>
                    Our Site may place and access certain first party Cookies on your computer or device. For more
                    details, please refer to our cookie policy.
                    <br><br>
                    <b> 13. Summary of Your Rights under GDPR</b> <br>
                    Under the GDPR, you have:<br>
                    1. The right to request access to, deletion of or correction of, your personal data held by Us;<br>
                    2. The right to complain to a supervisory authority;<br>
                    3. Be informed of what data processing is taking place;<br>
                    4. The right to restrict processing;<br>
                    5. the right to data portability;<br>
                    6. Object to processing of your personal data;<br>
                    7. rights with respect to automated decision-making and profiling (see section 14 below).<br>
                    To enforce any of the foregoing rights or if you have any other questions about Our Site or this
                    Privacy Policy, please contact Us using the details set out in section 15 below.
                    <br><br>
                    <b> 14. Automated Decision-Making and Profiling </b><br>
                    We do not carry out any automated decision-making or profiling at present.
                    <br><br>
                    <b> 15. Contacting Us </b><br>
                    If you have any questions about Our Site or this Privacy Policy, please contact Us by:<br>
                    • Email: info@fronterasky.com<br>
                    • Traditional mail: 6526 S Kanner Hwy. Suite 255, Stuart, FL 34997.<br>
                    Please ensure that your query is clear, particularly if it is a request for information about the
                    data we hold about you (as under section 11, above).
                    <br><br>
                    <b>16. Changes to Our Privacy Policy</b> <br>
                    We may change this Privacy Policy as we may deem necessary from time to time, or as may be required
                    by law. Any changes will be immediately posted on Our Site and you will be deemed to have accepted
                    the terms of the Privacy Policy on your first use of Our Site following the alterations. We
                    recommend that you check this page regularly to keep up-to-date.
                </p>
            </div>
        </div>
    </div>
</div>