import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PalmbeachComponent } from './modules/pages/palmbeach/palmbeach.component';

const routes: Routes = [
  {
      path: 'palmbeach1',
      component: PalmbeachComponent
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/main/main.module').then((m) => m.MainModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      // scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64]
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
