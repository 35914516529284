<section class="hero">
</section>
<section class="container outservice">
    <div class="row">
        <div class="col-7 col-lg-7 col-md-10 col-sm-10 col-10">
            <h1>
                Compare our aircraft and discover <br> how to make your trip an <br>unforgettable journey.
            </h1>
        </div>
        <!-- ---------------------------CAMBIO INICIO--------------------------------- -->
        <div class="takeLook col-5 col-lg-5 col-md-2 col-sm-2 col-2">
            <a [routerLink]="['/air-comparator']" [fragment]="'comparator'">Take a look</a>
        </div>
        <!-- ---------------------------CAMBIO FIN(+CSS)--------------------------------- -->
    </div>

</section>
<section class="pripal">
    <div class="container">
        <div class="row items">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 align-self-center order-1 order-md-0">
                <p>
                    Thanks to our extensive network of quality operators, FronteraSky has access to thousands of
                    aircraft. Take a look at the different types of business jets below. There is an aircraft that can
                    accomplish your mission comfortably and for the right price
                    below.
                </p>
            </div>
            <div class="col-6 col-lg-6 col-md-6 col-sm-12 col-12 order-0 order-md-1">
                <picture>
                    <source type="image/png" srcset="/assets/img/comparator_body1.png">
                    <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Comparator/comparator_body1.jp2">
                    <img src="/assets/img/Escritorio/Comparator/WebP/comparator_body1.webp" alt="FronteraSky Image" class="img-fluid">
                </picture>
            </div>
        </div>
    </div>
</section>

<section class="charters">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Private jets available</h1>
            </div>
        </div>
        <!--M1-->
        <div class="items row pb-3">
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-10 col-10 align-self-center">
                <h2>
                    Very light jets
                </h2>
                <p>
                    This category of aircraft, also known as personal jets, encompasses the entry-level category of
                    private jets. They typically seat up to six passengers often with a maximum takeoff weight (MTOW) of
                    or under 10,000 pounds (4,540 kg). Also, most of these
                    jets are certified to be flown by a single pilot and can operate from small airports. Their range is
                    limited though.
                </p>
                <p>
                    <a href="javascript:void(0)" data-toggle="modal" data-target=".bd-example-modal-lg1">
                        Range
                    </a>
                </p>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2 align-self-center">
                <a href="javascript::void(0)" (click)="veryLightJetsInfo()">
                    <img [src]="veryLightJetsInfoChange ? '/assets/img/icono_plus.png' : '/assets/img/icono_minus.png'"
                        alt="" class ="img-fluid" />
                </a>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div *ngIf="veryLightJetsInfoChange" id="VeryLightJet" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#VeryLightJet" data-slide-to="0" class="active"></li>
                        <li data-target="#VeryLightJet" data-slide-to="1"></li>
                        <li data-target="#VeryLightJet" data-slide-to="2"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <picture>
                                <source type="image/png" srcset="/assets/img/VeryLightJet/es-very-light-jet-1.png">
                                <source type="image/jp2"
                                    srcset="/assets/img/Imágenes JPG 2/Comparator/VeryLightJet/es-very-light-jet-1.jp2">
                                <img src="/assets/img/Escritorio/Comparator/WebP/VeryLightJet/es-very-light-jet-1.webp"
                                    alt="FronteraSky Image" class="d-block w-100 img-fluid">
                            </picture>
                        </div>
                        <div class="carousel-item">
                            <picture>
                                <source type="image/png" srcset="/assets/img/VeryLightJet/es-very-light-jet-2.png">
                                <source type="image/jp2"
                                    srcset="/assets/img/Imágenes JPG 2/Comparator/VeryLightJet/es-very-light-jet-2.jp2">
                                <img src="/assets/img/Escritorio/Comparator/WebP/VeryLightJet/es-very-light-jet-2.webp"
                                    alt="FronteraSky Image" class="d-block w-100 img-fluid">
                            </picture>
                        </div>
                        <div class="carousel-item">
                            <picture>
                                <source type="image/png" srcset="/assets/img/VeryLightJet/es-very-light-jet-3.png">
                                <source type="image/jp2"
                                    srcset="/assets/img/Imágenes JPG 2/Comparator/VeryLightJet/es-very-light-jet-3.jp2">
                                <img src="/assets/img/Escritorio/Comparator/WebP/VeryLightJet/es-very-light-jet-3.webp"
                                    alt="FronteraSky Image" class="d-block w-100 img-fluid">
                            </picture>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#VeryLightJet" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#VeryLightJet" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
                <img *ngIf="!veryLightJetsInfoChange" [src]="veryLightJetsInfoImg" class="img-fluid" alt="" />
            </div>
        </div>
        <hr />

        <!--M2-->

        <div class="row pb-3 pt-3">
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-10 col-10 align-self-center">
                <h2>
                    Light jets
                </h2>
                <p>
                    This category of aircraft is very popular around the world as they can also operate from smaller
                    airports while delivering a much extended range than their smaller counterparts. They typically seat
                    six passengers and have a range of up-to 1,500 miles
                    nonstop. Bombardier Learjet 40 XR, 45XR and 70/75 models; Hawker Beechcraft 400; Embraer Phenom 300;
                    Dassault Falcon 10; and Cessna Citation CJ1-CJ4, Bravo, and Encore models.
                </p>
                <p>
                    <a href="javascript:void(0)" data-toggle="modal" data-target=".bd-example-modal-lg2">
                        Range
                    </a>
                </p>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2 align-self-center">
                <a href="javascript::void(0)" (click)="lightJetsInfo()">
                    <img [src]="lightJetsInfoChange ? '/assets/img/icono_plus.png' : '/assets/img/icono_minus.png'"
                        alt="" class="img-fluid"/>
                </a>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div *ngIf="lightJetsInfoChange" id="LightJet" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#LightJet" data-slide-to="0" class="active"></li>
                        <li data-target="#LightJet" data-slide-to="1"></li>
                        <li data-target="#LightJet" data-slide-to="2"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <picture>
                                <source type="image/png" srcset="/assets/img/LightJet/es-light-jet-1.png">
                                <source type="image/jp2"
                                    srcset="/assets/img/Imágenes JPG 2/Comparator/LightJet/es-light-jet-1.jp2">
                                <img src="/assets/img/Escritorio/Comparator/WebP/LightJet/es-light-jet-1.webp"
                                    alt="FronteraSky Image" class="d-block w-100 img-fluid">
                            </picture>
                        </div>
                        <div class="carousel-item">
                            <picture>
                                <source type="image/png" srcset="/assets/img/LightJet/es-light-jet-2.png">
                                <source type="image/jp2"
                                    srcset="/assets/img/Imágenes JPG 2/Comparator/LightJet/es-light-jet-2.jp2">
                                <img src="/assets/img/Escritorio/Comparator/WebP/LightJet/es-light-jet-2.webp"
                                    alt="FronteraSky Image" class="d-block w-100 img-fluid">
                            </picture>
                        </div>
                        <div class="carousel-item">
                            <picture>
                                <source type="image/png" srcset="/assets/img/LightJet/es-light-jet-3.png">
                                <source type="image/jp2"
                                    srcset="/assets/img/Imágenes JPG 2/Comparator/LightJet/es-light-jet-3.jp2">
                                <img src="/assets/img/Escritorio/Comparator/WebP/LightJet/es-light-jet-3.webp"
                                    alt="FronteraSky Image" class="d-block w-100">
                            </picture>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#LightJet" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#LightJet" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
                <img *ngIf="!lightJetsInfoChange" [src]="lightJetsInfoImg" class="img-fluid" alt="" />
            </div>
        </div>

        <!--M3-->

        <hr />
        <div class="row pb-3 pt-3">
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-10 col-10 align-self-center">
                <h2>
                    Mid size jets
                </h2>
                <p>
                    This type of aircraft was created mainly to perform intercontinental flights across the United
                    States for an increased number of passengers. A very popular category among the business community
                    for its spacious interiors and productivity focused design.
                    They typically seat up-to ten passengers. Cessna Citation XLS, Columbus, and Sovereign; Embraer
                    Legacy 450 and 500; Bombardier Learjet 60 XR or 85; Dassault Falcon 20; Hawker Beechcraft 750,
                    850XP, and 900XP; and Gulfstream 150 and
                    250.
                </p>
                <p>
                    <a href="javascript:void(0)" data-toggle="modal" data-target=".bd-example-modal-lg3">
                        Range
                    </a>
                </p>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2 align-self-center">
                <a href="javascript::void(0)" (click)="midSizeJetsInfo()">
                    <img [src]="midSizeJetsInfoChange ? '/assets/img/icono_plus.png' : '/assets/img/icono_minus.png'"
                        alt="" class ="img-fluid" />
                </a>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div *ngIf="midSizeJetsInfoChange" id="MidsizeJet" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#MidsizeJet" data-slide-to="0" class="active"></li>
                        <li data-target="#MidsizeJet" data-slide-to="1"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <picture>
                                <source type="image/png" srcset="/assets/img/MidSizeJet/es-midsize-jet-1.png">
                                <source type="image/jp2"
                                    srcset="/assets/img/Imágenes JPG 2/Comparator/MidSizeJet/es-midsize-jet-1.jp2">
                                <img src="/assets/img/Escritorio/Comparator/WebP/MidSizeJet/es-midsize-jet-1.webp"
                                    alt="FronteraSky Image" class="d-block w-100">
                            </picture>
                        </div>
                        <div class="carousel-item">
                            <picture>
                                <source type="image/png" srcset="/assets/img/MidSizeJet/es-midsize-jet-2.png">
                                <source type="image/jp2"
                                    srcset="/assets/img/Imágenes JPG 2/Comparator/MidSizeJet/es-midsize-jet-2.jp2">
                                <img src="/assets/img/Escritorio/Comparator/WebP/MidSizeJet/es-midsize-jet-2.webp"
                                    alt="FronteraSky Image" class="d-block w-100">
                            </picture>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#MidsizeJet" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#MidsizeJet" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
                <img *ngIf="!midSizeJetsInfoChange" [src]="midSizeJetsInfoImg" class="img-fluid" alt="" />
            </div>
        </div>

        <hr />

        <div class="row pb-3 pt-3">
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-10 col-10 align-self-center">
                <h2>
                    Large business jets and “bizliner” jets
                </h2>
                <p>
                    The large business jet category encompasses the largest purpose-built aircraft for business travel.
                    The “bizliner” subcategory utilizes converted airliners to be used for ultra-luxury leisure or
                    business travel. However, we also have access to regular
                    (non-converted) airliners that can be chartered for larger groups of passengers. Large cabin jets
                    include Bombardier Global and Challenger models; Dassault Falcon 7x; and Gulfstream G500, G550, and
                    G650. Examples of heavy jets include
                    Boeing Business Jets and Airbus models.
                </p>
                <p>
                    <a href="javascript:void(0)" data-toggle="modal" data-target=".bd-example-modal-lg4">
                        Range
                    </a>
                </p>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2 align-self-center">
                <a href="javascript::void(0)" (click)="largeBusinessJetsInfo()">
                    <img [src]="largeBusinessJetsInfoChange ? '/assets/img/icono_plus.png' : '/assets/img/icono_minus.png'"
                        alt="" class ="img-fluid"/>
                </a>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div *ngIf="largeBusinessJetsInfoChange" id="LargeJet" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#LargeJet" data-slide-to="0" class="active"></li>
                        <li data-target="#LargeJet" data-slide-to="1"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <picture>
                                <source type="image/png" srcset="/assets/img/LargeJet/es-large-jet-1.png">
                                <source type="image/jp2"
                                    srcset="/assets/img/Imágenes JPG 2/Comparator/LargeJet/es-large-jet-1.jp2">
                                <img src="/assets/img/Escritorio/Comparator/WebP/LargeJet/es-large-jet-1.webp"
                                    alt="FronteraSky Image" class="d-block w-100">
                            </picture>
                        </div>
                        <div class="carousel-item">
                            <picture>
                                <source type="image/png" srcset="/assets/img/LargeJet/es-large-jet-2.png">
                                <source type="image/jp2"
                                    srcset="/assets/img/Imágenes JPG 2/Comparator/LargeJet/es-large-jet-2.jp2">
                                <img src="/assets/img/Escritorio/Comparator/WebP/LargeJet/es-large-jet-2.webp"
                                    alt="FronteraSky Image" class="d-block w-100">
                            </picture>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#LargeJet" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#LargeJet" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
                <img *ngIf="!largeBusinessJetsInfoChange" [src]="largeBusinessJetsInfoImg" class="img-fluid" alt="" />
            </div>
        </div>

        <hr />

        <div class="row pb-3 pt-3">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12align-self-center">
                <h2>
                    Helicopters
                </h2>
                <p>
                    Our offering is not limited to fixed wing aircraft. FronteraSky also offers executive helicopter
                    service in many cities around the world for those passengers that need to travel between cities or
                    within the city.
                </p>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <picture>
                    <source type="image/png" srcset="/assets/img/helicopter-1.png">
                    <source type="image/jp2"
                        srcset="/assets/img/Imágenes JPG 2/Comparator/Helicopter/es-helicopter-1.jp2">
                    <img src="/assets/img/Escritorio/Comparator/WebP/Helicopter/es-helicopter-1.webp" alt="FronteraSky Image"
                        class="d-block w-100">
                </picture>
            </div>
        </div>

    </div>

    <a id="comparator"></a>
    <hr />

</section>

<section class="comparator" id="aircraft">
    <!-- ------------------------- CAMBIO --------------------- -->
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="container">
                    <h1>Our aircraft comparator</h1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="content-comparator col-12 m3">
                <form [formGroup]="airForm" (ngSubmit)="bintAirComparator()">
                    <div class="row mt-2">
                        <h4>Select your options to compare</h4>
                    </div>
                    <div class="row mt-4">
                        <div class="col-xl-3 col-lg-3 col-md-5 col-sm-5 col-12">
                            <select class="custom-select custom-select-sm mb-3" formControlName="air1"
                                (change)="air1($event.target.value)">
                                <option selected>Select aircraft type</option>
                                <option *ngFor="let item of category" [value]="item.id">{{
                  item.value
                }}</option>
                            </select>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-5 col-sm-5 col-12">
                            <select class="custom-select custom-select-sm mb-3" formControlName="air11"
                                (change)="air11($event.target.value)">
                                <option selected>Select aircraft model</option>
                                <option *ngFor="let item of airs1" [value]="item.id">{{
                  item.aircraft
                }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-5 col-sm-5 col-12">
                            <select class="custom-select custom-select-sm mb-3" formControlName="air2"
                                (change)="air2($event.target.value)">
                                <option selected>Select aircraft type</option>
                                <option *ngFor="let item of category" [value]="item.id">{{
                  item.value
                }}</option>
                            </select>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-5 col-sm-5 col-12">
                            <select class="custom-select custom-select-sm mb-3" formControlName="air22"
                                (change)="air22($event.target.value)">
                                <option selected>Select aircraft model</option>
                                <option *ngFor="let item of airs2" [value]="item.id">{{
                  item.aircraft
                }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-5 col-sm-5 col-12">
                            <select class="custom-select custom-select-sm mb-3" formControlName="air3"
                                (change)="air3($event.target.value)">
                                <option selected>Select aircraft type</option>
                                <option *ngFor="let item of category" [value]="item.id">{{
                  item.value
                }}</option>
                            </select>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-5 col-sm-5 col-12">
                            <select class="custom-select custom-select-sm mb-3" formControlName="air33"
                                (change)="air33($event.target.value)">
                                <option selected>Select aircraft model</option>
                                <option *ngFor="let item of airs3" [value]="item.id">{{
                  item.aircraft
                }}</option>
                            </select>
                        </div>
                    </div>
                </form>
                <div class="row mt-2 mb-4">
                    <h4>Comparison results</h4>
                </div>
                <div class="row d-none d-lg-block">
                    <div class="col-12">
                        <table class="table table-striped table-bordered ">
                            <thead>
                                <tr>
                                    <td class="noBorder"></td>
                                    <td class="titulosTabla ajusteTitulo">Speed</td>
                                    <td class="titulosTabla ajusteTitulo">Range</td>
                                    <td class="titulosTabla">Maximum Ramp weight</td>
                                    <td class="titulosTabla">Maximum Take Off weight</td>
                                    <td class="titulosTabla">Maximum Landing Weight</td>
                                    <td class="titulosTabla">Max Fuel</td>
                                    <td class="titulosTabla">Pax</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="air111">
                                    <td>{{ air111.aircraft }}</td>
                                    <td>{{ air111.Speed }}</td>
                                    <td>{{ air111.Range }}</td>
                                    <td>{{ air111.MRW }}</td>
                                    <td>{{ air111.MTOW }}</td>
                                    <td>{{ air111.MLW }}</td>
                                    <td>{{ air111.MaxFuel }}</td>
                                    <td>{{ air111.Pax }}</td>
                                </tr>
                                <tr *ngIf="air222">
                                    <td>{{ air222.aircraft }}</td>
                                    <td>{{ air222.Speed }}</td>
                                    <td class="tablaRespuesta">{{ air222.Range }}</td>
                                    <td>{{ air222.MRW }}</td>
                                    <td>{{ air222.MTOW }}</td>
                                    <td>{{ air222.MLW }}</td>
                                    <td>{{ air222.MaxFuel }}</td>
                                    <td>{{ air222.Pax }}</td>
                                </tr>
                                <tr *ngIf="air333">
                                    <td>{{ air333.aircraft }}</td>
                                    <td>{{ air333.Speed }}</td>
                                    <td>{{ air333.Range }}</td>
                                    <td>{{ air333.MRW }}</td>
                                    <td>{{ air333.MTOW }}</td>
                                    <td>{{ air333.MLW }}</td>
                                    <td>{{ air333.MaxFuel }}</td>
                                    <td>{{ air333.Pax }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row d-block d-lg-none tablaRespuesta" *ngIf="air111">
                    <h5>AIRCRAFT CATEGORY1</h5>
                    <p>Aircraft model 1 {{ air111.aircraft }}</p>
                    <br />
                    <p>Speed: {{ air111.Speed }}</p>
                    <p>Range: {{ air111.Range }}</p>
                    <p>Cabin Height: {{ air111.MRW }}</p>
                    <p>Cabin Width: {{ air111.MTOW }}</p>
                    <p>Length: {{ air111.MLW }}</p>
                    <p>Seats: {{ air111.MaxFuel }}</p>
                    <p>Lugagge: {{ air111.Pax }}</p>
                </div>
            </div>
        </div>

        <div class="row fotosGrid">
            <div class="item">
                <picture>
                    <source type="image/png" srcset="/assets/img/comparator_gallery4.png">
                    <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Comparator/comparator_gallery4.jp2">
                    <img src="/assets/img/Escritorio/Comparator/WebP/comparator_gallery4.webp" alt="FronteraSky Image" class="d-block w-100">
                </picture>
            </div>
            <div class="item">
                <picture>
                    <source type="image/png" srcset="/assets/img/comparator_gallery1.png">
                    <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Comparator/comparator_gallery1.jp2">
                    <img src="/assets/img/Escritorio/Comparator/WebP/comparator_gallery1.webp" alt="FronteraSky Image" class="d-block w-100">
                </picture>
            </div>
            <div class="item">
                <picture>
                    <source type="image/png" srcset="/assets/img/comparator_gallery3.png">
                    <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Comparator/comparator_gallery3.jp2">
                    <img src="/assets/img/Escritorio/Comparator/WebP/comparator_gallery3.webp" alt="FronteraSky Image" class="d-block w-100">
                </picture>
            </div>
            <div class="item two-space">
                <picture>
                    <source type="image/png" srcset="/assets/img/comparator_gallery5.png">
                    <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Comparator/comparator_gallery5.jp2">
                    <img src="/assets/img/Escritorio/Comparator/WebP/comparator_gallery5.webp" alt="FronteraSky Image" class="d-block w-100">
                </picture>
            </div>
            <div class="item">
                <picture>
                    <source type="image/png" srcset="/assets/img/comparator_gallery2.png">
                    <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Comparator/comparator_gallery2.jp2">
                    <img src="/assets/img/Escritorio/Comparator/WebP/comparator_gallery2.webp" alt="FronteraSky Image" class="d-block w-100">
                </picture>
            </div>
        </div>
    </div>
</section>

<!-- Large modal -->

<div class="modal fade bd-example-modal-lg4" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div id="cm4" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#cm4" data-slide-to="0" style="background-color: #382985;" class="active"></li>
                    <li data-target="#cm4" data-slide-to="1" style="background-color: #382985;"></li>
                    <li data-target="#cm4" data-slide-to="2" style="background-color: #382985;"></li>
                    <li data-target="#cm4" data-slide-to="3" style="background-color: #382985;"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <picture>
                            <source type="image/png" srcset="/assets/img/Range/new/es_range-large-americas.png">
                            <source type="image/jp2"
                                srcset="/assets/img/Imágenes JPG 2/Escritorio/Comparator/LargeJet/es_range-large-americas.jp2">
                            <img src="/assets/img/Escritorio/Comparator/WebP/LargeJet/es_range-large-americas.webp"
                                alt="FronteraSky Image" class="d-block w-100">
                        </picture>
                    </div>
                    <div class="carousel-item">
                        <picture>
                            <source type="image/png" srcset="/assets/img/Range/new/es_range-large-eastasia.png">
                            <source type="image/jp2"
                                srcset="/assets/img/Imágenes JPG 2/Escritorio/Comparator/LargeJet/es_range-large-eastasia.jp2">
                            <img src="/assets/img/Escritorio/Comparator/WebP/LargeJet/es_range-large-eastasia.webp"
                                alt="FronteraSky Image" class="d-block w-100">
                        </picture>
                    </div>
                    <div class="carousel-item">
                        <picture>
                            <source type="image/png" srcset="/assets/img/Range/new/es_range-large-europe.png">
                            <source type="image/jp2"
                                srcset="/assets/img/Imágenes JPG 2/Escritorio/Comparator/LargeJet/es_range-large-europe.jp2">
                            <img src="/assets/img/Escritorio/Comparator/WebP/LargeJet/es_range-large-europe.webp"
                                alt="FronteraSky Image" class="d-block w-100">
                        </picture>
                    </div>
                    <div class="carousel-item">
                        <picture>
                            <source type="image/png" srcset="/assets/img/Range/new/es_range-large-middleeast.png">
                            <source type="image/jp2"
                                srcset="/assets/img/Imágenes JPG 2/Escritorio/Comparator/LargeJet/es_range-large-middleeast.jp2">
                            <img src="/assets/img/Escritorio/Comparator/WebP/LargeJet/es_range-large-middleeast.webp"
                                alt="FronteraSky Image" class="d-block w-100">
                        </picture>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#cm4" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" style="background-color: #382985;"
                        aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#cm4" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" style="background-color: #382985;"
                        aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>
</div>

<!-- Midsize modal -->

<div class="modal fade bd-example-modal-lg3" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div id="cm3" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#cm3" data-slide-to="0" style="background-color: #382985;" class="active"></li>
                    <li data-target="#cm3" data-slide-to="1" style="background-color: #382985;"></li>
                    <li data-target="#cm3" data-slide-to="2" style="background-color: #382985;"></li>
                    <li data-target="#cm3" data-slide-to="3" style="background-color: #382985;"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <picture>
                            <source type="image/png" srcset="/assets/img/Range/new/es_range-midsize-americas.png">
                            <source type="image/jp2"
                                srcset="/assets/img/Imágenes JPG 2/Escritorio/Comparator/MidSizeJet/es_range-midsize-americas.jp2">
                            <img src="/assets/img/Escritorio/Comparator/WebP/MidSizeJet/es_range-midsize-americas.webp"
                                alt="FronteraSky Image" class="d-block w-100">
                        </picture>
                    </div>
                    <div class="carousel-item">
                        <picture>
                            <source type="image/png" srcset="/assets/img/Range/new/es_range-midsize-eastasia.png">
                            <source type="image/jp2"
                                srcset="/assets/img/Imágenes JPG 2/Escritorio/Comparator/MidSizeJet/es_range-midsize-eastasia.jp2">
                            <img src="/assets/img/Escritorio/Comparator/WebP/MidSizeJet/es_range-midsize-eastasia.webp"
                                alt="FronteraSky Image" class="d-block w-100">
                        </picture>
                    </div>
                    <div class="carousel-item">
                        <picture>
                            <source type="image/png" srcset="/assets/img/Range/new/es_range-midsize-europe.png">
                            <source type="image/jp2"
                                srcset="/assets/img/Imágenes JPG 2/Escritorio/Comparator/MidSizeJet/es_range-midsize-europe.jp2">
                            <img src="/assets/img/Escritorio/Comparator/WebP/MidSizeJet/es_range-midsize-europe.webp"
                                alt="FronteraSky Image" class="d-block w-100">
                        </picture>
                    </div>
                    <div class="carousel-item">
                        <picture>
                            <source type="image/png" srcset="/assets/img/Range/new/es_range-midsize-middleeast.png">
                            <source type="image/jp2"
                                srcset="/assets/img/Imágenes JPG 2/Escritorio/Comparator/MidSizeJet/es_range-midsize-middleeast.jp2">
                            <img src="/assets/img/Escritorio/Comparator/WebP/MidSizeJet/es_range-midsize-middleeast.webp"
                                alt="FronteraSky Image" class="d-block w-100">
                        </picture>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#cm3" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"
                        style="background-color: #382985"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#cm3" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" style="background-color: #382985;"
                        aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>
</div>

<!-- Light modal -->

<div class="modal fade bd-example-modal-lg2" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div id="cm2" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#cm2" data-slide-to="0" style="background-color: #382985;" class="active"></li>
                    <li data-target="#cm2" data-slide-to="1" style="background-color: #382985;"></li>
                    <li data-target="#cm2" data-slide-to="2" style="background-color: #382985;"></li>
                    <li data-target="#cm2" data-slide-to="3" style="background-color: #382985;"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <picture>
                            <source type="image/png" srcset="/assets/img/Range/new/es_range-light-americas.png">
                            <source type="image/jp2"
                                srcset="/assets/img/Imágenes JPG 2/Escritorio/Comparator/LightJet/es_range-light-americas.jp2">
                            <img src="/assets/img/Escritorio/Comparator/WebP/LightJet/es_range-light-americas.webp"
                                alt="FronteraSky Image" class="d-block w-100">
                        </picture>
                    </div>
                    <div class="carousel-item">
                        <picture>
                            <source type="image/png" srcset="/assets/img/Range/new/es_range-light-eastasia.png">
                            <source type="image/jp2"
                                srcset="/assets/img/Imágenes JPG 2/Escritorio/Comparator/LightJet/es_range-light-eastasia.jp2">
                            <img src="/assets/img/Escritorio/Comparator/WebP/LightJet/es_range-light-eastasia.webp"
                                alt="FronteraSky Image" class="d-block w-100">
                        </picture>
                    </div>
                    <div class="carousel-item">
                        <picture>
                            <source type="image/png" srcset="/assets/img/Range/new/es_range-light-europe.png">
                            <source type="image/jp2"
                                srcset="/assets/img/Imágenes JPG 2/Escritorio/Comparator/LightJet/es_range-light-europe.jp2">
                            <img src="/assets/img/Escritorio/Comparator/WebP/LightJet/es_range-light-europe.webp"
                                alt="FronteraSky Image" class="d-block w-100">
                        </picture>
                    </div>
                    <div class="carousel-item">
                        <picture>
                            <source type="image/png" srcset="/assets/img/Range/new/es_range-light-middleeast.png">
                            <source type="image/jp2"
                                srcset="/assets/img/Imágenes JPG 2/Escritorio/Comparator/LightJet/es_range-light-middleeast.jp2">
                            <img src="/assets/img/Escritorio/Comparator/WebP/LightJet/es_range-light-middleeast.webp"
                                alt="FronteraSky Image" class="d-block w-100">
                        </picture>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#cm2" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" style="background-color: #382985;"
                        aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#cm2" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" style="background-color: #382985;"
                        aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>
</div>

<!-- Very Light modal -->

<div class="modal fade bd-example-modal-lg1" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div id="cm1" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#cm1" data-slide-to="0" style="background-color: #382985;" class="active"></li>
                    <li data-target="#cm1" data-slide-to="1" style="background-color: #382985;"></li>
                    <li data-target="#cm1" data-slide-to="2" style="background-color: #382985;"></li>
                    <li data-target="#cm1" data-slide-to="3" style="background-color: #382985;"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <picture>
                            <source type="image/png" srcset="/assets/img/Range/new/es_range-very-light-americas.png">
                            <source type="image/jp2"
                                srcset="/assets/img/Imágenes JPG 2/Escritorio/Comparator/VeryLightJet/es_range-very-light-americas.jp2">
                            <img src="/assets/img/Escritorio/Comparator/WebP/VeryLightJet/es_range-very-light-americas.webp"
                                alt="FronteraSky Image" class="d-block w-100">
                        </picture>
                    </div>
                    <div class="carousel-item">
                        <picture>
                            <source type="image/png" srcset="/assets/img/Range/new/es_range-very-light-eastasia.png">
                            <source type="image/jp2"
                                srcset="/assets/img/Imágenes JPG 2/Escritorio/Comparator/VeryLightJet/es_range-very-light-eastasia.jp2">
                            <img src="/assets/img/Escritorio/Comparator/WebP/VeryLightJet/es_range-very-light-eastasia.webp"
                                alt="FronteraSky Image" class="d-block w-100">
                        </picture>
                    </div>
                    <div class="carousel-item">
                        <picture>
                            <source type="image/png" srcset="/assets/img/Range/new/es_range-very-light-europe.png">
                            <source type="image/jp2"
                                srcset="/assets/img/Imágenes JPG 2/Escritorio/Comparator/VeryLightJet/es_range-very-light-europe.jp2">
                            <img src="/assets/img/Escritorio/Comparator/WebP/VeryLightJet/es_range-very-light-europe.webp"
                                alt="FronteraSky Image" class="d-block w-100">
                        </picture>
                    </div>
                    <div class="carousel-item">
                        <picture>
                            <source type="image/png" srcset="/assets/img/Range/new/es_range-very-light-middleeast.png">
                            <source type="image/jp2"
                                srcset="/assets/img/Imágenes JPG 2/Escritorio/Comparator/VeryLightJet/es_range-very-light-middleeast.jp2">
                            <img src="/assets/img/Escritorio/Comparator/WebP/VeryLightJet/es_range-very-light-middleeast.webp"
                                alt="FronteraSky Image" class="d-block w-100">
                        </picture>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#cm1" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" style="background-color: #382985;"
                        aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#cm1" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" style="background-color: #382985;"
                        aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>
</div>