import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-helicopter-reservation',
  templateUrl: './helicopter-reservation.component.html',
  styleUrls: ['./helicopter-reservation.component.sass']
})
export class HelicopterReservationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
