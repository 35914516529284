import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-booking-chair-selection',
  templateUrl: './booking-chair-selection.component.html',
  styleUrls: ['./booking-chair-selection.component.sass'],
})
export class BookingChairSelectionComponent implements OnInit {
  chairForm = new FormGroup({
    chairSel: new FormControl('', [Validators.required]),
  });

  chairs: any;
  filas: any;
  otherFilas: any;
  seats = 0;
  seatsCount = 0;
  seatsSelected: any = [];
  model = 'embreaer';
  constructor(
    private readonly router: Router,
    private readonly service: AppService
  ) {}

  async ngOnInit() {
    const data: any = JSON.parse(
      localStorage.getItem('BOOKYNGSYSTEM::paymentInfo')
    );
    if (!data) {
      this.router.navigate([`/booking/`]);
    }
    const fligthId = data.departureSchedule;
    const toSchedule = data.returnSchedule;
    const passengers = data.passengers;
    this.seats = data.passengers.length;
    const obj: any = await this.service.getSeatsAvailable(fligthId);
    this.changeModel(obj.airplaneModel);
    // console.log(obj);
    this.chairs = obj.seats;
    // console.log(this.chairs);
    const isGovernment: any = await this.service.isGovernment(fligthId);
    let isGovernmentReturn: any = false;
    if (toSchedule) {
      isGovernmentReturn = await this.service.isGovernment(toSchedule);
    }
    if (
      isGovernment.governmentContractorFlight ||
      isGovernmentReturn.governmentContractorFlight
    ) {
      for (var j of passengers) {
        if (!j.nameOfEmployer || !j.POCPhone || !j.POCEmail || !j.POCName) {
          this.router.navigate([`/booking/`]);
          Swal.fire(
            'Error',
            'Government contractor flight, all fields is required, please try again',
            'error'
          );
          return;
        }
      }
    }
  }

  changeModel(model: string) {
    console.log(model);
    switch (model) {
      case 'Boeing737':
        this.model = 'boeing';
        this.filas = [...Array(32).keys()].map((x) => x + 1);
        this.filas.shift();
        break;
      case 'ACJ320':
        this.model = 'acj320';
        break;
      case 'ACJ321':
        this.model = 'acj321';
        this.filas = [...Array(32).keys()].map((x) => x + 1);
        this.filas.shift();
        break;
      case 'ACJ330':
        this.model = 'acj330';
        break;
      case 'ACJ340':
        this.model = 'acj340';
        break;
      case 'ACJ340v1':
        this.model = 'acj340v1';
        break;
      case 'ACJ350':
        this.model = 'acj350';
        this.otherFilas = [...Array(9).keys()].map((x) => x);
        this.filas = [...Array(44).keys()].map((x) => x + 1);
        this.filas.shift();
        break;
      case 'AirbusA319-100':
        this.model = 'airbus';
        this.filas = [...Array(26).keys()].map((x) => x + 1);
        this.filas.shift();
        break;
      case 'ANTONOV148':
        this.model = 'antonov';
        this.filas = [...Array(17).keys()].map((x) => x + 1);
        this.filas.shift();
        break;
      case 'ATR42-600':
        this.model = 'atr42';
        this.filas = [...Array(18).keys()].map((x) => x + 1);
        this.filas.shift();
        break;
      case 'ATR72-600':
        this.model = 'atr72';
        this.filas = [...Array(18).keys()].map((x) => x + 1);
        this.filas.shift();
        break;
      case 'BA146':
        this.model = 'ba';
        this.filas = [...Array(20).keys()].map((x) => x + 1);
        this.filas.shift();
        break;
      case 'DC9':
        this.model = 'dc';
        this.filas = [...Array(18).keys()].map((x) => x + 1);
        this.filas.shift();
        break;
      case 'Embraer145':
        this.model = 'embreaer';
        this.filas = [...Array(18).keys()].map((x) => x + 1);
        this.filas.shift();
        break;
      case 'Jet328':
        this.filas = [...Array(12).keys()].map((x) => x + 1);
        this.filas.shift();
        this.model = 'jet';
        break;
      case 'Airbus320-200':
        this.filas = [...Array(30).keys()].map((x) => x + 1);
        this.filas.shift();
        this.model = 'a320';
        break;
      case 'Airbus321-211':
        this.filas = [...Array(37).keys()].map((x) => x + 1);
        this.filas.shift();
        this.model = 'a321';
        break;
      case 'CRJ-700':
        this.filas = [...Array(18).keys()].map((x) => x + 1);
        this.filas.shift();
        this.model = 'crj700';
        break;
      case 'CRJ-900':
        this.filas = [...Array(19).keys()].map((x) => x + 1);
        this.filas.shift();
        this.model = 'crj900';
        break;
      case 'EmbraerE-190':
        this.filas = [...Array(27).keys()].map((x) => x + 1);
        this.filas.shift();
        this.model = 'embraerE190';
        break;
        case 'Boeing737-2':
          this.filas = [...Array(27).keys()].map((x) => x + 1);
          this.filas.shift();
          this.model = 'boeing2';
          break;
    }
  }

  saveForm() {
    const data: any = JSON.parse(
      localStorage.getItem('BOOKYNGSYSTEM::paymentInfo')
    );
    data.seatsSelected = this.seatsSelected;
    data.seats = this.formatSeats(this.seatsSelected);
    localStorage.setItem('BOOKYNGSYSTEM::paymentInfo', JSON.stringify(data));
    this.router.navigate([`/booking-pay/`]);
  }

  formatPassengers(passengers: any, seats: any) {
    const arrayObject = [];
    for (const i in passengers) {
      const tempObj = passengers[i];
      tempObj.seat = seats[i];
      arrayObject.push(tempObj);
    }
    return arrayObject;
  }

  formatSeats(seats: any) {
    const arraySeats = [];
    for (const i in seats) {
      const newObj: any = new Object();
      newObj.code = seats[i];
      arraySeats.push(newObj);
    }
    return arraySeats;
  }

  checkAvailable(key, hiden = false, hidenSeat = false) {
    const available: any = this.chairs.filter(
      (item: any) => item.place === key
    );
    if (available.length > 0) {
      if (hidenSeat) {
        return '';
      } else {
        return 'es-seat_availabe.png';
      }
    } else {
      if (hiden) {
        return '';
      } else {
        return 'es-seat_occupied.png';
      }
    }
  }

  checkAvailableCJ350(key, item, letter, hiden = false, hidenSeat = false) {
    // console.log(key);
    const available: any = this.chairs.filter(
      (item: any) => item.place === key
    );
    if (available.length > 0) {
      if (hidenSeat) {
        return '';
      } else {
        if (item < 10) {
          if (letter == 'A' || letter == 'F') {
            return 'seats/es-seat_acj350-1_available.png';
          } else {
            return 'seats/es-seat_acj350-2_available.png';
          }
        } else {
          return 'es-seat_availabe.png';
        }
      }
    } else {
      if (hiden) {
        return '';
      } else {
        if (item < 10) {
          if (letter == 'A' || letter == 'F') {
            return 'seats/es-seat_acj350-1_occupied.png';
          } else {
            return 'seats/es-seat_acj350-2_occupied.png';
          }
        } else {
          return 'es-seat_occupied.png';
        }
      }
    }
  }

  checkAvailableStatus(key) {
    const available: any = this.chairs.filter(
      (item: any) => item.place === key
    );
    if (available.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  select(key) {
    console.log(key);
    if (this.seatsCount < this.seats) {
      if (this.checkAvailableStatus(key)) {
        const img = document.getElementById(key);
        if (img instanceof HTMLImageElement) {
          img.src = '/assets/img/es-seat_selected.png';
          this.seatsCount = this.seatsCount + 1;
          this.seatsSelected.push(key);
        }
      }
    }
  }

  async reset() {
    await this.router.navigateByUrl('/', { skipLocationChange: true });
    this.router.navigateByUrl('/booking-chair');
  }
}
