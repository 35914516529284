<a id="whiteGlode"></a>

<div class="hero ">
    <img src="./assets/img/WhiteGloves/es-noticias-white-gloves.png" alt="">
</div>

<div class="mediacalourism pt-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Every client is unique and looking for something different in a private jet charter.</h1>
            </div>
        </div>

    </div>
</div>

<div class="popular">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <p> Looking for transport from the airport to the hotel? Or perhaps you need transport to your important meeting? Frontera Sky can arrange all your transport needs from a simple taxi services to luxury limos and assist you while in the country,
                    book hotels, entertainment, golf and more. Our ground transportation team will take care of your every need once you have landed, taking you wherever you need to go in one of our luxury chauffeured vehicles.

                </p>
                <p>
                    From impressive stand-up cabins with stunning interiors, to hand-picked catering and state-of-the-art entertainment systems, we pay attention to every detail. We work with a global network of prestigious vehicle operators who only use best-in-class vehicles.
                </p>
                <p>
                    All our drivers are highly trained, courteous and well-presented and will be as chatty or detached as you wish. Many are multi-lingual too, so if you’d like a speaker of any particular language, just let us know. We’ll even provide a personal security
                    guard at your request.
                </p>
            </div>

        </div>


    </div>
</div>

<div class="">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <p>
                    Whether requesting an aircraft for an intercontinental business trip with multiple destinations or a quaint, romantic weekend away, FronteraSky outclasses the competition in all aspects of customer service. Our private charter consultants will lead you
                    through the entire charter booking process, attend to all your requests, anticipate future needs and offer personalized service.
                </p>
                <p>
                    'White glove' has always been a symbol of luxurious service and elegance. FronteraSky provides our clients with the most exclusive spectrum of White Glove Services that include seamless travel arrangements, catering, including special meal and beverage
                    requests, airport meet and greet welcoming services, limousine chauffeur services to your intermediate or final destination, personal close protection bodyguards, exclusive accommodation booking, battler, shopping assistant and concierge
                    services, branded goods door-to-door delivery, helicopter and yacht reservation, an exclusive access to golf clubs, wellness and recreation facilities and much more to create a traveling experience when luxury is felt.
                </p>
                <p>
                    Guided by global health and safety recommendations, our White Glove Service assistants are keeping the distance without being far away.
                </p>
            </div>

            <div class="col-12">
                <ul>
                    <li>While performing their duties, FronteraSky White Glove Service assistants wear the mask, face shield and gloves when welcoming you.</li>
                    <li>The distance of 2 meters is respected.</li>
                    <li>Enhanced cleaning and disinfection procedures are constantly implemented in the aircrafts, vehicles, yachts and helicopters Nevertheless, we remain attentive and on hand to craft wonderful moments for each and every one of you. FronteraSky
                        White Glove Service assistants will be near to help you to unwind and relax while feeling safe and taken care of. If you are planning a family getaway to a tropical island or a long-range business journey, contact one of our representatives
                        to experience the trip that transforms the destination with FronteraSky.
                    </li>
                </ul>
            </div>

        </div>
    </div>
</div>
<a id="helicopter"></a>
<app-helicopter-reservation></app-helicopter-reservation>
<a id="bodyguard"></a>
<app-bodyguard></app-bodyguard>
<app-exclusive-catering></app-exclusive-catering>
<app-yacht-reservation></app-yacht-reservation>
<app-vip-airport></app-vip-airport>