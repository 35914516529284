import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
@Component({
  selector: 'app-air-comparator',
  templateUrl: './air-comparator.component.html',
  styleUrls: ['./air-comparator.component.sass'],
})
export class AirComparatorComponent implements OnInit {
  airForm = new FormGroup({
    air1: new FormControl(''),
    air11: new FormControl(''),
    air2: new FormControl(''),
    air22: new FormControl(''),
    air3: new FormControl(''),
    air33: new FormControl(''),
  });

  category = [
    {
      id: 1,
      value: 'Very Light Aircraft',
    },
    {
      id: 2,
      value: 'Light Aircraft',
    },
    {
      id: 3,
      value: 'Medium Size Aircraft',
    },
    {
      id: 4,
      value: 'Helicopters',
    },
  ];

  aircrafts = [
    {
      idCategory: 1,
      id: 1,
      aircraft: 'Cessna Citation Mustang',
      MRW: '8730 lbs',
      MTOW: '8645 lbs',
      MLW: '8000 lbs',
      Speed: '340 kts',
      Mach: '.63',
      Range: '1150 nm',
      MaxiAltitude: 'FL410   ',
      MaxFuel: '385 Gallons',
      Pax: '5 plus 1 Pilot',
    },

    {
      idCategory: 1,
      id: 2,
      aircraft: 'Embraer Phenom 100',
      MRW: '10 626 lbs',
      MTOW: '10 582 lbs',
      MLW: '9 877 lbs',
      Speed: '340 kts',
      Mach: '.63',
      Range: '2 182nm',
      MaxiAltitude: 'FL410',
      MaxFuel: '418 Gallons',
      Pax: '6 plus 1 Pilot',
    },

    {
      idCategory: 1,
      id: 3,
      aircraft: 'Eclipse 500',
      MRW: '6 034 lbs',
      MTOW: '6 000 lbs',
      MLW: '5 600 lbs',
      Speed: '375 kts',
      Mach: '.64',
      Range: '2 182nm',
      MaxiAltitude: 'FL350',
      MaxFuel: '250 Gallons',
      Pax: '6 plus 1 Pilot',
    },
    {
      idCategory: 2,
      id: 4,
      aircraft: 'Bombardier Learjet 40 XR',
      MRW: '11 783 lbs',
      MTOW: '17 968 lbs',
      MLW: '16 865 lbs',
      Speed: '430 kts',
      Mach: '',
      Range: '1 811 nm',
      MaxiAltitude: 'FL450',
      MaxFuel: '799 Gallons',
      Pax: '11 plus 1 Pilot',
    },

    {
      idCategory: 2,
      id: 5,
      aircraft: 'Embraer Phenom 300',
      MRW: '18 078 lbs',
      MTOW: '17 968 lbs',
      MLW: '16 865 lbs',
      MZFW: '13 999 lbs',
      Speed: '453 kts',
      Mach: '.78',
      Range: '1 971 nm',
      MaxiAltitude: 'FL450',
      MaxFuel: '799 Gallons',
      Pax: '11 plus 1 Pilot',
    },

    {
      idCategory: 2,
      id: 6,
      aircraft: 'Dassault Falcon 7X',
      MRW: '',
      MTOW: '70 000 lbs',
      MLW: '62 400 lbs',
      MZFW: '35 685 lbs',
      Speed: '548 kts',
      Mach: '.80',
      Range: '6 160 nm',
      MaxiAltitude: 'FL440',
      MaxFuel: '799 Gallons',
      Pax: '12 plus 1 Pilot',
    },

    {
      idCategory: 3,
      id: 7,
      aircraft: 'Bombardier Learjet 60XR',
      MRW: '21 785 lbs',
      MTOW: '21 000 lbs',
      MLW: '19 200 lbs',
      MOW: '13 600 lbs',
      MZFW: '',
      Speed: '485 kts',
      Mach: '.78',
      Range: '2 199 nm',
      MaxiAltitude: 'FL510',
      MaxFuel: '799 Gallons',
      Pax: '6 plus 1 Pilot',
    },

    {
      idCategory: 3,
      id: 8,
      aircraft: 'Embraer Legacy 450',
      MRW: '',
      MTOW: '35 350 lbs',
      MLW: '32 500 lbs',
      MOW: ' 23 350 lbs',
      MZFW: '',
      Speed: '1 004 km/km',
      Mach: '.80',
      Range: '4 260 km',
      MaxiAltitude: 'FL450',
      MaxFuel: '12 940 lbs',
      Pax: '9 plus 1 Pilot',
    },

    {
      idCategory: 3,
      id: 9,
      aircraft: 'Cessna Citation XLS',
      MRW: '',
      MTOW: '20 200 lbs',
      MLW: '18 700 lbs',
      MOW: '12 480 lbs',
      MZFW: '',
      Speed: '494 mph',
      Mach: '.80',
      Range: '2 217 miles',
      MaxiAltitude: 'FL450',
      MaxFuel: '6 740 lbs',
      Pax: '8 plus 1 Pilot',
    },

    {
      idCategory: 4,
      id: 10,
      aircraft: 'Sikorskyg S—76D',
      MRW: '',
      MTOW: '11 700 lbs',
      MLW: '',
      MZFW: '',
      Speed: '155 kts',
      Mach: '',
      Range: '441 nm',
      MaxiAltitude: '',
      MaxFuel: '296 gallons',
      Pax: '12 -13 plus 1 Pilot',
    },

    {
      idCategory: 4,
      id: 11,
      aircraft: 'Bell 429 WL9',
      MRW: '',
      MTOW: '7 000 lbs',
      MOW: '4 647 lbs',
      MLW: '',
      MZFW: '',
      Speed: '273 km',
      Mach: '',
      Range: '722 km',
      MaxiAltitude: '',
      MaxFuel: '',
      Pax: '5 plus 1 Pilot',
    },

    {
      idCategory: 4,
      id: 12,
      aircraft: 'Airbus AS365N3+ Panther – Medvac Aircraft',
      MRW: '',
      MTOW: '5 647 lbs',
      MLW: '',
      MZFW: '',
      Speed: '306 km',
      Mach: '',
      Range: '614 km',
      MaxiAltitude: '',
      MaxFuel: '',
      Pax: '2 plus 1 Pilot',
    },
  ];

  airs1;
  airs2;
  airs3;
  air111;
  air222;
  air333;

  veryLightJetsInfoChange = true;
  veryLightJetsInfoImg = '/assets/img/Very light jet/es-very-light-jet-1.png';

  lightJetsInfoChange = true;
  lightJetsInfoImg = '/assets/img/Light jet/es-light-jet-1.png';

  midSizeJetsInfoChange = true;
  midSizeJetsInfoImg = '/assets/img/Midsize jet/es-midsize-jet-1.png';

  largeBusinessJetsInfoChange = true;
  largeBusinessJetsInfoImg = '/assets/img/Large jet/es-large-jet-1.png';
  constructor() {}

  ngOnInit(): void {}

  bintAirComparator() {}

  air1($event) {
    this.airs1 = this.aircrafts.filter((item) => item.idCategory == $event);
  }

  air11($event) {
    const air = this.aircrafts.filter((item) => item.id == $event);
    this.air111 = air[0];
  }

  air2($event) {
    this.airs2 = this.aircrafts.filter((item) => item.idCategory == $event);
  }

  air22($event) {
    const air = this.aircrafts.filter((item) => item.id == $event);
    this.air222 = air[0];
  }

  air3($event) {
    this.airs3 = this.aircrafts.filter((item) => item.idCategory == $event);
  }

  air33($event) {
    const air = this.aircrafts.filter((item) => item.id == $event);
    this.air333 = air[0];
  }

  veryLightJetsInfo() {
    if (this.veryLightJetsInfoChange) {
      this.veryLightJetsInfoChange = !this.veryLightJetsInfoChange;
      this.veryLightJetsInfoImg =
        '/assets/img/informacion jet/es_very-light-jet.png';
    } else {
      this.veryLightJetsInfoChange = !this.veryLightJetsInfoChange;
      this.veryLightJetsInfoImg =
      '/assets/img/informacion jet/es_very-light-jet.png';
    }
  }

  lightJetsInfo() {
    if (this.lightJetsInfoChange) {
      this.lightJetsInfoChange = !this.lightJetsInfoChange;
      this.lightJetsInfoImg = '/assets/img/informacion jet/es_light-jet.png';
    } else {
      this.lightJetsInfoChange = !this.lightJetsInfoChange;
      this.lightJetsInfoImg = '/assets/img/informacion jet/es_light-jet.png';
    }
  }

  midSizeJetsInfo() {
    if (this.midSizeJetsInfoChange) {
      this.midSizeJetsInfoChange = !this.midSizeJetsInfoChange;
      this.midSizeJetsInfoImg = '/assets/img/informacion jet/es_midsize-jet.png';
    } else {
      this.midSizeJetsInfoChange = !this.midSizeJetsInfoChange;
      this.midSizeJetsInfoImg = '/assets/img/informacion jet/es_midsize-jet.png';
    }
  }

  largeBusinessJetsInfo() {
    if (this.largeBusinessJetsInfoChange) {
      this.largeBusinessJetsInfoChange = !this.largeBusinessJetsInfoChange;
      this.largeBusinessJetsInfoImg =
        '/assets/img/informacion jet/es_large-jet.png';
    } else {
      this.largeBusinessJetsInfoChange = !this.largeBusinessJetsInfoChange;
      this.largeBusinessJetsInfoImg =
        '/assets/img/informacion jet/es_large-jet.png';
    }
  }
}
