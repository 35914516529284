import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bodyguard',
  templateUrl: './bodyguard.component.html',
  styleUrls: ['./bodyguard.component.sass']
})
export class BodyguardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
