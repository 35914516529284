import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from '../../../services/app.service';
import { environment } from 'src/environments/environment';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.sass']
})
export class PaymentComponent implements OnInit {

  public payPalConfig?: IPayPalConfig;
  cliendId: string;
  totalAmounth: any;
  totalAmounthValue: any;
  paymentForm = new FormGroup({
    bookingRef: new FormControl('', Validators.required)
  });
  constructor(private readonly _appService: AppService) {
    this.cliendId = environment.paypalcliend;
  }

  ngOnInit(): void {
  }

  search() {
    const { bookingRef } = this.paymentForm.value;
    this._appService.getValuePaymentByBookingRef(bookingRef).then((response: any)=> {
      console.log(response);
      if (response.paymentReference) {
        Swal.fire({
          title: '',
          text: 'This reservation has already been paid',
          icon: 'info'
        });
      } else {
        this.totalAmounth = response.totalAmounth;
        this.totalAmounthValue = this.totalAmounth;
        this.payPalConfig = {
          currency: 'USD',
          clientId: this.cliendId,
          createOrderOnClient: (data) =>
            <ICreateOrderRequest>{
              intent: 'CAPTURE',
              purchase_units: [
                {
                  amount: {
                    currency_code: 'USD',
                    value: this.totalAmounth,
                    breakdown: {
                      item_total: {
                        currency_code: 'USD',
                        value: this.totalAmounth,
                      },
                    },
                  },
                  items: [
                    {
                      name: 'Frontera SKY Booking' ,
                      quantity: '1',
                      category: 'DIGITAL_GOODS',
                      unit_amount: {
                        currency_code: 'USD',
                        value: this.totalAmounth,
                      },
                    },
                  ],
                },
              ],
            },
          advanced: {
            commit: 'true',
          },
          style: {
            label: 'paypal',
            layout: 'vertical',
          },
          onApprove: (data, actions) => {
            console.log(
              'onApprove - transaction was approved, but not authorized',
              data,
              actions
            );
            actions.order.get().then((details) => {
              console.log(
                'onApprove - you can get full order details inside onApprove: ',
                details
              );
              if (details.status === "APPROVED") {
                this._appService.paymentbooking({ code: bookingRef, paymentReference: details.id }).then((response) => {
                  console.log(response);
                  Swal.fire('Success', 'Your payment has been success', 'success');
                  this.paymentForm.reset();
                  this.payPalConfig = null;
                  this.totalAmounthValue = null;
                });
              }
            });
          },
          onClientAuthorization: (data) => {
            console.log(
              'onClientAuthorization - you should probably inform your server about completed transaction at this point',
              data
            );
            //this.showSuccess = true;
          },
          onCancel: (data, actions) => {
            console.log('OnCancel', data, actions);
            //this.showCancel = true;
          },
          onError: (err) => {
            console.log('OnError', err);
            //Swal.fire();
            //this.showError = true;
          },
          onClick: (data, actions) => {
            console.log('onClick', data, actions);
            //this.resetStatus();
          },
        };
      }
    });
  }
}
