<footer class="pt-5 pb-5">
    <div class="container-footer">
        <div class="row">
            <div class="col-xl-2"></div>
            <div class="col-xl-3">
                <img src="./assets/img/logo.png" alt="" class="fluid-img" />
            </div>
            <div class="col-xl-7">
                <div class="menu">
                    <ul>
                        <li class="nav-item">
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active" [routerLink]="'/aboutus'">ABOUT US</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active" [routerLink]="'/services'">SERVICES</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active" [routerLink]="'/air-comparator'">AIRCRAFT
                                COMPARATOR</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active" [routerLink]="'/pricing'">PRICING</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active" [routerLink]="'/booking'">SCHEDULED
                                FLIGHTS</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active" [routerLink]="'/payment'">PAYMENT</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active" [routerLink]="'/contact'">CONTACT</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-2">
            </div>
            <div class="col-xl-3">
                <p class="title-footer">
                    Feel free to contact our charter consultants<br />
                </p>
                <p class="phones">
                    <br /> Americas <a href="tel:+15619029035">+1 561 902 9035</a>
                    <br /><a href="mailto:info@fronterasky.com" target="_blank" class="phone-spaces">
                        Contact Us</a>
                    <br />
                    <br /> Middle East <a href="tel:+971552784112">+971 55 278 4112 </a><br />
                    <a href="mailto:info@fronterasky.com" class="phone-spaces">
                        Contact Us</a>
                    <br />
                    <br /> Europe and U.K. <a href="tel:+15619029040">+1 561 902 9040
                    </a> <br />
                    <a href="info@fronterasky.com" target="_blank" class="phone-spaces">
                        Contact Us</a>
                    <br />
                    <br /> East Asia <a href="tel:+63282713040">+63 28 271 3040</a><br />
                    <a href="mailto:info@fronterasky.com" class="phone-spaces">
                        Contact Us
                    </a>
                    <br>
                </p>
            </div>
            <div class="col-xl-7">
                <div class="row">
                    <div class="col-xl-5">
                        <p class="title-footer">
                            Subscribe to our newsletter to learn about our newest programs
                            <!-- Let us know what your next business destination will be, we will inform you the most convenient service. -->
                        </p>
                        <form [formGroup]="contactFrom" (ngSubmit)="sendData()">
                            <select class="custom-select custom-select-sm mb-3" formControlName="title">
                                <option value="null" disabled selected>Title</option>
                                <option value="Mr">Mr.</option>
                                <option value="Mrs">Mrs.</option>
                                <option value="Ms">Ms.</option>
                                <option value="Dr">Dr.</option>
                                <option value="Prof">Prof. </option>
                            </select>
                            <input type="text" formControlName="name" placeholder="Name" class="mb-3" />
                            <input type="text" formControlName="company" placeholder="Company" class="mb-3" />
                            <input type="email" formControlName="email" placeholder="Email address" class="mb-3" />
                            <input type="email" formControlName="phone" placeholder="Phone number" class="mb-3" />
                            <button type="submit" [disabled]="!contactFrom.valid">Send</button>
                        </form>
                    </div>
                    <div class="col-xl-5 icons-container">
                        <div>
                            <p class="title-redes">
                                Follow us and stay informed about FronteraSky on our social channels
                            </p>
                            <div class="icons">
                                <a href="https://www.linkedin.com/company/fronterasky/about/" target="_blank" rel="noopener noreferrer">
                                    <img src="/assets/img/ico_in-navbar.png" alt="" />
                                </a>
                                <a href="https://www.facebook.com/FronteraSky/" target="_blank" rel="noopener noreferrer">
                                    <img src="/assets/img/ico_fb-navbar.png" alt="" />
                                </a>
                                <a href="https://www.youtube.com/channel/UCbzcY1ps2LsCoBLem_Ys7Sw/videos" target="_blank" rel="noopener noreferrer">
                                    <img src="/assets/img/ico_yt-navbar.png" alt="" />
                                </a>
                                <a href="https://www.instagram.com/fronterasky/" target="_blank" rel="noopener noreferrer">
                                    <img src="/assets/img/ico_ig-navbar.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <div>

                        </div>
                        <div class="botonFooter">
                            <button type="button" class="btn btn-link botonFooter" data-toggle="modal" data-target="#exampleModal" routerLink="/policy-privacy">
                                Policy privacy
                            </button>
                            <button type="button" class="btn btn-link botonFooter" data-toggle="modal" data-target="#exampleModal" routerLink="/terms-of-use">
                                Terms of use
                            </button>
                            <!-- Button trigger modal
                            <button type="button" class="btn btn-link botonFooter" data-toggle="modal"
                                data-target="#staticBackdrop">
                                Cookies
                            </button>
                            -->

                        </div>
                        <div>
                          <input class="checkbox-cb" id="checkbox-cb" type="checkbox" />
                          <div class="cookie-bar" id="cookiesBar" *ngIf="cookie">
                            <span class="message">Your personal information is important to us, learn more about our cookie and privacy policy on this <a [routerLink]="'/cookies-policy'" >link</a>.
                             </span>
                             <!--  If you have read and accept our terms please accept by clicking "Accept". -->
                           <!--  <label for="checkbox-cb" class="close-cb">x</label> -->

                           <!-- <label for="checkbox-cb" class="close-cb" (click)="saveCookies()"> Accept </label>
                           <label for="checkbox-cb" class="close" >X</label> -->
                          </div>

                            <!-- Modal -->

                            <!-- <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="staticBackdropLabel">Cookies policy</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body textoAzul">
                                            <p class="parrafos">
                                                Your personal information is important to us, learn more about our cookie and privacy policy in this <a data-dismiss="modal" [routerLink]="'/cookies-policy'" style="color:#007bff">link</a>. If you have read and accept our terms please accept by clicking "Accept".
                                            </p> -->

                                            <!-- <p class="parrafos">
                                                To make this site work properly, we sometimes place small data files called cookies on your device. Most big websites do this too.
                                                <br><br>
                                                <b> What are cookies?</b><br> A cookie is a small text file that a website saves on your computer or mobile device when you visit the site. It enables the website to remember your actions and preferences
                                                (such as login, language, font size and other display preferences) over a period of time, so you don’t have to keep re-entering them whenever you come back to the site or browse from one page to another.
                                                <br><br>
                                                <b> How do we use cookies?</b><br> FronteraSky uses or may use cookies and/or web beacons to help us identify repeat visitors, the time users spend on different areas of our Site, as well as specific functionalities
                                                that users choose to use. If cookies data constitutes personally identifiable information, we process such data on the basis of your consent.
                                                <br><br>
                                            </p>
                                            <p class="textoAzul">
                                                <b> Example:</b><br> A number of our pages use cookies to remember:<br> • your display preferences, such as contrast color settings or font size.
                                                <br> • If you have already replied to a survey pop-up that asks you if the content was helpful or not (so you won't be asked again).<br> • If you have agreed (or not) to our use of cookies on this site.
                                                Also, some videos embedded in our pages use a cookie to anonymously gather statistics on how you got there and what videos you visited.<br> Enabling these cookies is not strictly necessary for the website
                                                to work but it will provide you with a better browsing experience. You can delete or block these cookies, but if you do that some features of this site may not work as intended.<br> The cookie-related information is
                                                not used to identify you personally and the pattern data is fully under our control. These cookies are not used for any purpose other than those described here.
                                            </p>
                                            <p class="parrafos">
                                                <b> How to control cookies</b><br> You can control and/or delete cookies as you wish – for details, see aboutcookies.org. You can delete all cookies that are already on your computer and you can set most
                                                browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.
                                            </p> -->
                                       <!--  </div> -->
                                      <!--   <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-primary" (click)="saveCookies()">Accept</button>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-xl-1">

                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="divider">
            </div>
            <div class="col-xl-12 text-center">
                <p class="copy"> © FronteraSky {{year}}, All Rights Reserved.</p>
            </div>
            <div class="col-xl-10 offset-xl-1 text-center">
                <p>
                    Disclaimer: FronteraSky is a flight broker and as such arranges carriage by air by chartering aircraft from third-party aircraft operators, acting as agent, in the name and on behalf of its customers. FronteraSky only acts as an intermediary, does not
                    itself operate aircraft and is not a contracting or an indirect carrier.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 text-center">
                <p class="alunideas">
                    Made with love by <a href="https://alunideas.com/es/home" target="_blank">alunideas.com</a>
                </p>
            </div>
        </div>
    </div>
</footer>
