import { Injectable } from '@angular/core';
const myScripts = [
  {
    name: 'avinode',
    src:
      'https://apps.avinode.com/webapp/rest/bootstrap?Avinode-WEB-APP=eyJraWQiOiIxNkVBQkQ5RS1BRjYyLTQ4NTEtODk5Qi1BM0UwMThGRjYxNDciLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJjZGJmNTJiOC01N2FhLTRlODgtODRhOC0yODk5ZWQ0YmEyNjIiLCJhdmlkb21haW4iOiIuYXZpbm9kZS5jb20iLCJhdml0ZW5hbnQiOjEyMTA0LCJpc3MiOiJhdmlub2RlIiwiYXZpdHlwZSI6MTAsImF2aW5vbmNlIjoiYjFkZDAyYjQtMzk1OC00ODU1LWJlMzUtYWZlNDM0NDgzYjBiIn0.KvJuiXeUT6Up3VinxOTNcLAUEeItjv3PAVO2Bnypdac_Ir_-BApRnpnfd8XrSStuZXJePsyzXWV7cjqXH1IAWIcpiVzHH3mkA8F-0fFS5RVG1ba2gIrgYihEYccrblRdBDKjh_8A7bMEk9LF8Vfcn3CZhMJe5qU7-LIHtisq_CS2TkyhN1issP-846OesgbtkU9ASApVRP_AGlnAQI3vUr4Ygh9DnjeH-hNMwn1OvS564RmNNiZwOgEu_gawEmIQCA46h1AQcsapMTmpgae_-2bqZk1grpPEQJOcl27sgjTayUU9dELJoRYJCarldpCNwZH2c_FW1TYx_v5wiWsn0g',
  },
];
@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  private scripts: any = {};
  constructor() {
    myScripts.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  // load a single or multiple scripts
  load(...scripts: string[]) {
    const promises: any[] = [];
    // push the returned promise of each loadScript call
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    // return promise.all that resolves when all promises are resolved
    return Promise.all(promises);
  }

  // load the script
  loadScript(name: string) {
    // console.log('pass here');
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      if (this.scripts[name].loaded) {
        console.log('Already Loaded')
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        // load script
        const script: any = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        // cross browser handling of onLoaded event
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          // Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: 'Loaded' });
        // finally append the script tag in the DOM
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}
