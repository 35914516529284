import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-palmbeach',
  templateUrl: './palmbeach.component.html',
  styleUrls: ['./palmbeach.component.sass']
})

export class PalmbeachComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
    this.router.navigate(['/']);
  }

}
