<div class="banner">
  
</div>
<div class="container p-5">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h1 class="text-center" style="margin: 50px 0">
                Seat selection
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <div class="container">
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="chairSel"
                  >Seats Selected
                  <span class="badge badge-info"
                    >{{ seatsCount }} / {{ seats }}</span
                  ></label
                >
                <br />
                <span
                  class="badge badge-primary mr-2"
                  *ngFor="let item of seatsSelected"
                  >{{ item }}</span
                >
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <button
                  type="button"
                  [disabled]="seatsCount !== seats"
                  (click)="saveForm()"
                  class="btn btn-success btn-sm btn-block"
                >
                  Continue
                </button>
              </div>
            </div>
            <div class="col-6 float-right">
              <button
                type="button"
                (click)="reset()"
                class="btn btn-success btn-sm btn-block"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12" *ngIf="chairs">
      <div class="card">
        <div class="container">
          <div [class]="model">
            <table *ngIf="model == 'boeing'">
              <tr>
                <td>
                  <img
                    id="A1"
                    (click)="select('A1')"
                    src="/assets/img/{{ checkAvailable('A1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="B1"
                    (click)="select('B1')"
                    src="/assets/img/{{ checkAvailable('B1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="C1"
                    (click)="select('C1')"
                    src="/assets/img/{{ checkAvailable('C1') }}"
                    alt=""
                  />
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr *ngFor="let item of filas">
                <td>
                  <img
                    style="margin-bottom: 3px"
                    id="A{{ item }}"
                    (click)="select('A' + item)"
                    src="/assets/img/{{ checkAvailable('A' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 3px"
                    id="B{{ item }}"
                    (click)="select('B' + item)"
                    src="/assets/img/{{ checkAvailable('B' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 3px"
                    id="C{{ item }}"
                    (click)="select('C' + item)"
                    src="/assets/img/{{ checkAvailable('C' + item) }}"
                    alt=""
                  />
                </td>
                <td class="pasillo">
                  <img
                    style="margin-bottom: 3px"
                    id="D{{ item }}"
                    (click)="select('D' + item)"
                    src="/assets/img/{{ checkAvailable('D' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 3px"
                    id="E{{ item }}"
                    (click)="select('E' + item)"
                    src="/assets/img/{{ checkAvailable('E' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 3px"
                    id="F{{ item }}"
                    (click)="select('F' + item)"
                    src="/assets/img/{{ checkAvailable('F' + item) }}"
                    alt=""
                  />
                </td>
              </tr>
            </table>
            <table *ngIf="model == 'jet'">
              <tr>
                <td></td>
                <td></td>
                <td class="pasillo">
                  <img
                    id="C1"
                    (click)="select('C1')"
                    src="/assets/img/{{ checkAvailable('C1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="D1"
                    (click)="select('D1')"
                    src="/assets/img/{{ checkAvailable('D1') }}"
                    alt=""
                  />
                </td>
              </tr>
              <tr *ngFor="let item of filas">
                <td>
                  <img
                    [style]="
                      'A' + item == 'A9'
                        ? 'margin-bottom: 3px; margin-top: 10px'
                        : 'margin-bottom: 3px;'
                    "
                    id="A{{ item }}"
                    (click)="select('A' + item)"
                    src="/assets/img/{{ checkAvailable('A' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 3px"
                    id="B{{ item }}"
                    (click)="select('B' + item)"
                    src="/assets/img/{{
                      checkAvailable('B' + item, item > 11 ? false : true)
                    }}"
                    alt=""
                  />
                </td>
                <td class="pasillo">
                  <img
                    [style]="
                      item >= 9
                        ? 'margin-bottom: 3px; margin-top: -16px'
                        : 'margin-bottom: 3px;'
                    "
                    id="C{{ item }}"
                    (click)="select('C' + item)"
                    src="/assets/img/{{
                      checkAvailable('C' + item, item > 10 ? true : false)
                    }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    [style]="
                      item >= 9
                        ? 'margin-bottom: 3px; margin-top: -16px'
                        : 'margin-bottom: 3px;'
                    "
                    id="D{{ item }}"
                    (click)="select('D' + item)"
                    src="/assets/img/{{
                      checkAvailable('D' + item, item > 10 ? true : false)
                    }}"
                    alt=""
                  />
                </td>
              </tr>
            </table>
            <table *ngIf="model == 'embreaer'">
              <tr>
                <td style="text-align: left">
                  <img
                    id="A1"
                    (click)="select('A1')"
                    src="/assets/img/{{ checkAvailable('A1') }}"
                    alt=""
                  />
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr *ngFor="let item of filas">
                <!-- <div *ngIf="item < 12 || item > 17"> -->
                <div>
                  <td>
                    <img
                      [style]="
                        'A' + item == 'A12'
                          ? 'margin-bottom: 1px; margin-top: 13px'
                          : 'margin-bottom: 1px;'
                      "
                      id="A{{ item }}"
                      (click)="select('A' + item)"
                      src="/assets/img/{{ checkAvailable('A' + item) }}"
                      alt=""
                    />
                  </td>
                  <td class="pasillo">
                    <img
                      [style]="
                        'A' + item == 'A12'
                          ? 'margin-bottom: 1px; margin-top: 13px'
                          : 'margin-bottom: 1px;'
                      "
                      id="C{{ item }}"
                      (click)="select('C' + item)"
                      src="/assets/img/{{
                        checkAvailable('C' + item, item > 2 ? false : true)
                      }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="
                        'A' + item == 'A12'
                          ? 'margin-bottom: 1px; margin-top: 13px'
                          : 'margin-bottom: 1px;'
                      "
                      id="D{{ item }}"
                      (click)="select('D' + item)"
                      src="/assets/img/{{
                        checkAvailable('D' + item, item > 2 ? false : true)
                      }}"
                      alt=""
                    />
                  </td>
                </div>
              </tr>
            </table>
            <!-- <table *ngIf="model == 'dc'">
              <tr>
                <td>
                  <img
                    id="A1"
                    (click)="select('A1')"
                    src="/assets/img/{{ checkAvailable('A1', true) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="C1"
                    (click)="select('C1')"
                    src="/assets/img/{{ checkAvailable('C1', true) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="D1"
                    (click)="select('D1')"
                    src="/assets/img/{{ checkAvailable('D1', true) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="E1"
                    (click)="select('E1')"
                    src="/assets/img/{{ checkAvailable('E1', true) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="F1"
                    (click)="select('F1')"
                    src="/assets/img/{{ checkAvailable('F1', true) }}"
                    alt=""
                  />
                </td>
              </tr>
              <tr *ngFor="let item of filas">
                <div *ngIf="item != 4">
                  <td>
                    <img
                      [style]="
                        'A' + item == 'A5'
                          ? 'margin-bottom: 1px; margin-top: 6px'
                          : 'margin-bottom: 1px;'
                      "
                      id="A{{ item }}"
                      (click)="select('A' + item)"
                      src="/assets/img/{{
                        checkAvailable(
                          'A' + item,
                          item == 1 || item == 4 ? true : false
                        )
                      }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="
                        'C' + item == 'C5'
                          ? 'margin-bottom: 1px; margin-top: 6px'
                          : 'margin-bottom: 1px;'
                      "
                      (click)="select('C' + item)"
                      src="/assets/img/{{
                        checkAvailable(
                          'C' + item,
                          item == 1 || item == 4 ? true : false
                        )
                      }}"
                      alt=""
                    />
                  </td>
                  <td class="pasillo">
                    <img
                      [style]="
                        item >= 5
                          ? 'margin-bottom: 1px; margin-top: -7px'
                          : 'margin-bottom: 1px;'
                      "
                      id="D{{ item }}"
                      (click)="select('D' + item)"
                      src="/assets/img/{{
                        checkAvailable(
                          'D' + item,
                          item == 1 || item == 4 || item == 2 || item == 3
                            ? true
                            : false,
                          item >= 18 ? true : false
                        )
                      }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="
                        item >= 5
                          ? 'margin-bottom: 1px; margin-top: -7px'
                          : 'margin-bottom: 1px;'
                      "
                      id="E{{ item }}"
                      (click)="select('E' + item)"
                      src="/assets/img/{{
                        checkAvailable(
                          'E' + item,
                          item == 1 || item == 4 ? true : false,
                          item >= 18 ? true : false
                        )
                      }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="
                        item >= 5
                          ? 'margin-bottom: 1px; margin-top: -7px'
                          : 'margin-bottom: 1px;'
                      "
                      id="F{{ item }}"
                      (click)="select('F' + item)"
                      src="/assets/img/{{
                        checkAvailable(
                          'F' + item,
                          item == 1 || item == 4 ? true : false,
                          item >= 18 ? true : false
                        )
                      }}"
                      alt=""
                    />
                  </td>
                </div>
              </tr>
            </table> -->
            <table *ngIf="model == 'ba'">
              <tr>
                <td></td>
                <td></td>
                <td class="pasillo">
                  <img
                    id="C1"
                    (click)="select('C1')"
                    src="/assets/img/{{ checkAvailable('C1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="D1"
                    (click)="select('D1')"
                    src="/assets/img/{{ checkAvailable('D1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="E1"
                    (click)="select('E1')"
                    src="/assets/img/{{ checkAvailable('E1') }}"
                    alt=""
                  />
                </td>
              </tr>
              <tr *ngFor="let item of filas">
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="A{{ item }}"
                    (click)="select('A' + item)"
                    src="/assets/img/{{ checkAvailable('A' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="B{{ item }}"
                    (click)="select('B' + item)"
                    src="/assets/img/{{
                      checkAvailable('B' + item, item > 11 ? false : true)
                    }}"
                    alt=""
                  />
                </td>
                <td class="pasillo">
                  <img
                    style="margin-bottom: 1px"
                    id="C{{ item }}"
                    (click)="select('C' + item)"
                    src="/assets/img/{{
                      checkAvailable('C' + item, item > 10 ? true : false)
                    }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="D{{ item }}"
                    (click)="select('D' + item)"
                    src="/assets/img/{{
                      checkAvailable('D' + item, item > 10 ? true : false)
                    }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="E{{ item }}"
                    (click)="select('E' + item)"
                    src="/assets/img/{{
                      checkAvailable('D' + item, item > 10 ? true : false)
                    }}"
                    alt=""
                  />
                </td>
              </tr>
            </table>
            <table *ngIf="model == 'atr72'">
              <tr>
                <td>
                  <img
                    id="A1"
                    (click)="select('A1')"
                    src="/assets/img/{{ checkAvailable('A1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="B1"
                    (click)="select('B1')"
                    src="/assets/img/{{ checkAvailable('B1') }}"
                    alt=""
                  />
                </td>
                <td class="pasillo">
                  <img
                    id="C1"
                    (click)="select('C1')"
                    src="/assets/img/{{ checkAvailable('C1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="D1"
                    (click)="select('D1')"
                    src="/assets/img/{{ checkAvailable('D1') }}"
                    alt=""
                  />
                </td>
              </tr>
              <tr *ngFor="let item of filas">
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="A{{ item }}"
                    (click)="select('A' + item)"
                    src="/assets/img/{{ checkAvailable('A' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="B{{ item }}"
                    (click)="select('B' + item)"
                    src="/assets/img/{{ checkAvailable('B' + item) }}"
                    alt=""
                  />
                </td>
                <td class="pasillo">
                  <img
                    style="margin-bottom: 1px"
                    id="C{{ item }}"
                    (click)="select('C' + item)"
                    src="/assets/img/{{ checkAvailable('C' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="D{{ item }}"
                    (click)="select('D' + item)"
                    src="/assets/img/{{ checkAvailable('D' + item) }}"
                    alt=""
                  />
                </td>
              </tr>
            </table>
            <table *ngIf="model == 'atr42'">
              <tr>
                <td>
                  <img
                    id="A1"
                    (click)="select('A1')"
                    src="/assets/img/{{ checkAvailable('A1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="B1"
                    (click)="select('B1')"
                    src="/assets/img/{{ checkAvailable('B1') }}"
                    alt=""
                  />
                </td>
                <td class="pasillo">
                  <img
                    id="C1"
                    (click)="select('C1')"
                    src="/assets/img/{{ checkAvailable('C1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="D1"
                    (click)="select('D1')"
                    src="/assets/img/{{ checkAvailable('D1') }}"
                    alt=""
                  />
                </td>
              </tr>
              <tr *ngFor="let item of filas">
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="A{{ item }}"
                    (click)="select('A' + item)"
                    src="/assets/img/{{ checkAvailable('A' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="B{{ item }}"
                    (click)="select('B' + item)"
                    src="/assets/img/{{ checkAvailable('B' + item) }}"
                    alt=""
                  />
                </td>
                <td class="pasillo">
                  <img
                    style="margin-bottom: 1px"
                    id="C{{ item }}"
                    (click)="select('C' + item)"
                    src="/assets/img/{{
                      checkAvailable('C' + item, item > 17 ? true : false)
                    }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="D{{ item }}"
                    (click)="select('D' + item)"
                    src="/assets/img/{{
                      checkAvailable('D' + item, item > 17 ? true : false)
                    }}"
                    alt=""
                  />
                </td>
              </tr>
            </table>
            <table *ngIf="model == 'antonov'">
              <tr>
                <td></td>
                <td></td>
                <td class="pasillo">
                  <img
                    id="C1"
                    (click)="select('C1')"
                    src="/assets/img/{{ checkAvailable('C1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="D1"
                    (click)="select('D1')"
                    src="/assets/img/{{ checkAvailable('D1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="E1"
                    (click)="select('E1')"
                    src="/assets/img/{{ checkAvailable('E1') }}"
                    alt=""
                  />
                </td>
              </tr>
              <tr *ngFor="let item of filas">
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="A{{ item }}"
                    (click)="select('A' + item)"
                    src="/assets/img/{{ checkAvailable('A' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="B{{ item }}"
                    (click)="select('B' + item)"
                    src="/assets/img/{{ checkAvailable('B' + item) }}"
                    alt=""
                  />
                </td>
                <td class="pasillo">
                  <img
                    style="margin-bottom: 1px"
                    id="C{{ item }}"
                    (click)="select('C' + item)"
                    src="/assets/img/{{ checkAvailable('C' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="D{{ item }}"
                    (click)="select('D' + item)"
                    src="/assets/img/{{ checkAvailable('D' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="E{{ item }}"
                    (click)="select('E' + item)"
                    src="/assets/img/{{ checkAvailable('E' + item) }}"
                    alt=""
                  />
                </td>
              </tr>
            </table>
            <table *ngIf="model == 'airbus'">
              <tr>
                <td>
                  <img
                    id="A1"
                    (click)="select('A1')"
                    src="/assets/img/{{ checkAvailable('A1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="B1"
                    (click)="select('B1')"
                    src="/assets/img/{{ checkAvailable('B1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="C1"
                    (click)="select('C1')"
                    src="/assets/img/{{ checkAvailable('C1') }}"
                    alt=""
                  />
                </td>
                <td class="pasillo">
                  <img
                    id="D1"
                    (click)="select('D1')"
                    src="/assets/img/{{ checkAvailable('D1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="E1"
                    (click)="select('E1')"
                    src="/assets/img/{{ checkAvailable('E1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="F1"
                    (click)="select('F1')"
                    src="/assets/img/{{ checkAvailable('F1') }}"
                    alt=""
                  />
                </td>
              </tr>
              <tr *ngFor="let item of filas">
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="A{{ item }}"
                    (click)="select('A' + item)"
                    src="/assets/img/{{ checkAvailable('A' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="B{{ item }}"
                    (click)="select('B' + item)"
                    src="/assets/img/{{ checkAvailable('B' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="C{{ item }}"
                    (click)="select('C' + item)"
                    src="/assets/img/{{ checkAvailable('C' + item) }}"
                    alt=""
                  />
                </td>
                <td class="pasillo">
                  <img
                    style="margin-bottom: 1px"
                    id="D{{ item }}"
                    (click)="select('D' + item)"
                    src="/assets/img/{{ checkAvailable('D' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="E{{ item }}"
                    (click)="select('E' + item)"
                    src="/assets/img/{{ checkAvailable('E' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 1px"
                    id="F{{ item }}"
                    (click)="select('F' + item)"
                    src="/assets/img/{{ checkAvailable('F' + item) }}"
                    alt=""
                  />
                </td>
              </tr>
            </table>
            <table *ngIf="model == 'acj321'">
              <tr style="display: flex; margin-top: 10px;">
                <div >
                  <img id="A1" (click)="select('A1')" src="/assets/img/{{ checkAvailable('A1') }}" alt="" class="bigger"/>
                </div>
                <div style="margin-left: 8px;">
                  <img id="B1" (click)="select('B1')" src="/assets/img/{{ checkAvailable('B1') }}" alt=""/>
                </div>
                <div style="margin-left: 20px;">
                  <img style="margin-left: 20px;" id="E1" (click)="select('E1')" src="/assets/img/{{ checkAvailable('E1') }}" alt=""/>
                </div>
                <div style="margin-left: 8px;">
                  <img id="F1" (click)="select('F1')" src="/assets/img/{{ checkAvailable('F1') }}" alt=""/>
                </div>
              </tr>
              <tr style="display: flex; margin-top: 26px;">
                <div >
                  <img id="A2" (click)="select('A2')" src="/assets/img/{{ checkAvailable('A2') }}" alt="" class="bigger"/>
                </div>
                <div style="margin-left: 8px;">
                  <img id="B2" (click)="select('B2')" src="/assets/img/{{ checkAvailable('B2') }}" alt=""/>
                </div>
                <div style="margin-left: 20px;">
                  <img style="margin-left: 20px;" id="E2" (click)="select('E2')" src="/assets/img/{{ checkAvailable('E2') }}" alt=""/>
                </div>
                <div style="margin-left: 8px;">
                  <img id="F2" (click)="select('F2')" src="/assets/img/{{ checkAvailable('F2') }}" alt=""/>
                </div>
              </tr>
              <tr style="display: flex; margin-top: 26px;">
                <div >
                  <img id="A3" (click)="select('A3')" src="/assets/img/{{ checkAvailable('A3') }}" alt="" class="bigger"/>
                </div>
                <div style="margin-left: 8px;">
                  <img id="B3" (click)="select('B3')" src="/assets/img/{{ checkAvailable('B3') }}" alt=""/>
                </div>
                <div style="margin-left: 20px;">
                  <img style="margin-left: 20px;" id="E3" (click)="select('E3')" src="/assets/img/{{ checkAvailable('E3') }}" alt=""/>
                </div>
                <div style="margin-left: 8px;">
                  <img id="F3" (click)="select('F3')" src="/assets/img/{{ checkAvailable('F3') }}" alt=""/>
                </div>
              </tr>
              <tr *ngFor="let item of filas">
                <div *ngIf="item > 3">
                  <td>
                    <img
                      [style]="
                        'A' + item == 'A4'
                          ? 'margin-bottom: 3px; margin-top: 29px'
                          : item == 8 || item == 21 ? 'margin-bottom: 3px; width: 17px': 'margin-bottom: 3px;'
                      "
                      id="A{{ item }}"
                      (click)="select('A' + item)"
                      src="/assets/img/{{ checkAvailable( 'A' + item, item == 8 || item == 21 ? true : false) }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="
                        'B' + item == 'B4'
                          ? 'margin-bottom: 3px; margin-top: 29px'
                          : 'margin-bottom: 3px;'
                      "
                      id="B{{ item }}"
                      (click)="select('B' + item)"
                      src="/assets/img/{{ checkAvailable('B' + item) }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="
                        'C' + item == 'C4'
                          ? 'margin-bottom: 3px; margin-top: 29px'
                          : 'margin-bottom: 3px;'
                      "
                      id="C{{ item }}"
                      (click)="select('C' + item)"
                      src="/assets/img/{{
                        checkAvailable('C' + item, item < 4 ? true : false)
                      }}"
                      alt=""
                    />
                  </td>
                  <td class="pasillo">
                    <img
                      [style]="
                        'D' + item == 'D4'
                          ? 'margin-bottom: 3px; margin-top: 29px'
                          : 'margin-bottom: 3px;'
                      "
                      id="D{{ item }}"
                      (click)="select('D' + item)"
                      src="/assets/img/{{
                        checkAvailable('D' + item, item < 4 ? true : false)
                      }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="
                        'E' + item == 'E4'
                          ? 'margin-bottom: 3px; margin-top: 29px'
                          : 'margin-bottom: 3px;'
                      "
                      id="E{{ item }}"
                      (click)="select('E' + item)"
                      src="/assets/img/{{ checkAvailable('E' + item) }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="
                        'F' + item == 'F4'
                          ? 'margin-bottom: 3px; margin-top: 29px'
                          : 'margin-bottom: 3px;'
                      "
                      id="F{{ item }}"
                      (click)="select('F' + item)"
                      src="/assets/img/{{
                        checkAvailable(
                          'F' + item,
                          item == 8 || item == 21 ? true : false
                        )
                      }}"
                      alt=""
                    />
                  </td>
                </div>
              </tr>
            </table>
            <table *ngIf="model == 'acj350'">
              <tr *ngFor="let item of otherFilas">
                <div>
                  <td>
                    <img
                      [style]="
                        'A' + item == 'A6'
                          ? 'margin-bottom: 22px; margin-top: 78px'
                          : 'margin-bottom: 22px'
                      "
                      id="A1"
                      (click)="select('A1')"
                      src="/assets/img/{{
                        checkAvailableCJ350('A' + item, item, 'A')
                      }}"
                      alt=""
                    />
                  </td>
                  <td style="width: 25px;"></td>
                  <td style="width: 25px;"></td>
                  <td>
                    <img
                      [style]="
                        'E' + item == 'E6'
                          ? 'margin-bottom: 22px; margin-top: 78px'
                          : 'margin-bottom: 22px;'
                      "
                      id="E1"
                      (click)="select('E1')"
                      src="/assets/img/{{
                        checkAvailableCJ350('E' + item, item, 'E')
                      }}"
                      alt=""
                    />
                  </td>
                  <td style="width: 25px;"></td>
                  <td>
                    <img
                      [style]="
                        'F' + item == 'F6'
                          ? 'margin-bottom: 22px; margin-top: 78px'
                          : 'margin-bottom: 22px;'
                      "
                      id="F1"
                      (click)="select('F1')"
                      src="/assets/img/{{
                        checkAvailableCJ350('F' + item, item, 'F')
                      }}"
                      alt=""
                    />
                  </td>
                  <td style="width: 25px;"></td>
                  <td style="width: 25px;"></td>
                  <td>
                    <img
                      [style]="
                        'K' + item == 'K6'
                          ? 'margin-bottom: 22px; margin-top: 78px'
                          : 'margin-bottom: 22px;'
                      "
                      id="K1"
                      (click)="select('K1')"
                      src="/assets/img/{{
                        checkAvailableCJ350('K' + item, item, 'K')
                      }}"
                      alt=""
                    />
                  </td>
                </div>
              </tr>
              <tr *ngFor="let item of filas">
                <div *ngIf="item > 15">
                  <td>
                    <img
                      [style]="
                        'A' + item == 'A30'
                          ? 'margin-bottom: 1px; margin-top: 85px'
                          : (item == 44) ? 'margin-bottom: 1px; width: 17px' : 'margin-bottom: 1px;'
                      "
                      id="A{{ item }}"
                      (click)="select('A' + item)"
                      src="/assets/img/{{
                        checkAvailable('A' + item, item < 16 ? false : true)
                      }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="
                        'B' + item == 'B30'
                          ? 'margin-bottom: 1px; margin-top: 85px'
                          : (item == 44) ? 'margin-bottom: 1px; width: 17px' : 'margin-bottom: 1px;'
                      "
                      id="B{{ item }}"
                      (click)="select('B' + item)"
                      src="/assets/img/{{
                        checkAvailable('B' + item, item < 16 ? false : true)
                      }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="
                        'C' + item == 'C30'
                          ? 'margin-bottom: 1px; margin-top: 85px'
                          : (item == 44 || item == 16) ? 'margin-bottom: 1px; width: 17px' : 'margin-bottom: 1px;'
                      "
                      id="C{{ item }}"
                      (click)="select('C' + item)"
                      src="/assets/img/{{
                        checkAvailable('C' + item, item < 16 ? false : true)
                      }}"
                      alt=""
                    />
                  </td>
                  <td class="pasillo">
                    <img
                      [style]="
                        'D' + item == 'D30'
                          ? 'margin-bottom: 1px; margin-top: 85px'
                          : (item == 16) ? 'margin-bottom: 1px; width: 17px' : 'margin-bottom: 1px;'
                      "
                      id="D{{ item }}"
                      (click)="select('D' + item)"
                      src="/assets/img/{{
                        checkAvailable(
                          'D' + item,
                          item < 17 ? true : false,
                          item < 17 || item == 30 ? true : false
                        )
                      }}"
                      alt=""
                    />
                  </td>
                  <td [style]="item == 30 ? 'width: 25px':''">
                    <img
                      [style]="
                        'E' + item == 'E30'
                          ? 'margin-bottom: 1px; margin-top: 85px'
                          : (item == 16) ? 'margin-bottom: 1px; width: 17px' : 'margin-bottom: 1px;'
                      "
                      id="E{{ item }}"
                      (click)="select('E' + item)"
                      src="/assets/img/{{
                        checkAvailable(
                          'E' + item,
                          item < 17 ? true : false,
                          item < 17 || item == 30 ? true : false
                        )
                      }}"
                      alt=""
                    />
                  </td>
                  <td [style]="item == 30 ? 'width: 26px':''">
                    <img
                      [style]="
                        'F' + item == 'F30'
                          ? 'margin-bottom: 1px; margin-top: 85px'
                          : (item == 16) ? 'margin-bottom: 1px; width: 17px' : 'margin-bottom: 1px;'
                      "
                      id="F{{ item }}"
                      (click)="select('F' + item)"
                      src="/assets/img/{{
                        checkAvailable(
                          'F' + item,
                          item < 17 ? true : false,
                          item < 17 || item == 30 ? true : false
                        )
                      }}"
                      alt=""
                    />
                  </td>
                  <td class="pasillo">
                    <img
                      [style]="
                        'H' + item == 'H30'
                          ? 'margin-bottom: 1px; margin-top: 85px'
                          : (item == 16) ? 'margin-bottom: 1px; width: 17px' : 'margin-bottom: 1px;'
                      "
                      id="H{{ item }}"
                      (click)="select('H' + item)"
                      src="/assets/img/{{
                        checkAvailable('H' + item, item < 16 ? false : true)
                      }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="
                        'J' + item == 'J30'
                          ? 'margin-bottom: 1px; margin-top: 85px'
                          : 'margin-bottom: 1px;'
                      "
                      id="J{{ item }}"
                      (click)="select('J' + item)"
                      src="/assets/img/{{
                        checkAvailable('J' + item, item < 16 ? false : true)
                      }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="
                        'K' + item == 'K30'
                          ? 'margin-bottom: 1px; margin-top: 85px'
                          : 'margin-bottom: 1px;'
                      "
                      id="K{{ item }}"
                      (click)="select('K' + item)"
                      src="/assets/img/{{
                        checkAvailable('K' + item, item < 16 ? false : true)
                      }}"
                      alt=""
                    />
                  </td>
                </div>
              </tr>
            </table>
            <table *ngIf="model == 'a320'">
              <tr>
                <td>
                  <img id="A1" (click)="select('A1')" src="/assets/img/{{ checkAvailable('A1') }}" alt=""/>
                </td>
                <td>
                  <img id="B1" (click)="select('B1')" src="/assets/img/{{ checkAvailable('B1') }}" alt=""/>
                </td>
                <td>
                  <img id="C1" (click)="select('C1')" src="/assets/img/{{ checkAvailable('C1') }}" alt=""/>
                </td>
                <td class="pasillo">
                  <img id="D1" (click)="select('D1')" src="/assets/img/{{ checkAvailable('D1') }}" alt=""/>
                </td>
                <td>
                  <img id="E1" (click)="select('E1')" src="/assets/img/{{ checkAvailable('E1') }}" alt=""/>
                </td>
                <td>
                  <img id="F1" (click)="select('F1')" src="/assets/img/{{ checkAvailable('F1') }}" alt=""/>
                </td>
              </tr>
              <tr *ngFor="let item of filas">
                <td>
                  <img [style]="'A' + item == 'A12' || 'A' + item == 'A25' ? 'margin-bottom: 3px; margin-top: 9px' : 'margin-bottom: 3px;'" id="A{{ item }}" (click)="select('A' + item)" src="/assets/img/{{ checkAvailable('A' + item, item == 11 || item == 24 ? true : false) }}" alt=""/>
                </td>
                <td>
                  <img [style]="'B' + item == 'B12' || 'B' + item == 'B25' ? 'margin-bottom: 3px; margin-top: 9px' : 'margin-bottom: 3px;'" id="B{{ item }}" (click)="select('B' + item)" src="/assets/img/{{ checkAvailable('B' + item) }}" alt=""/>
                </td>
                <td>
                  <img [style]="'C' + item == 'C12' || 'C' + item == 'C25' ? 'margin-bottom: 3px; margin-top: 9px' : 'margin-bottom: 3px;'" id="C{{ item }}" (click)="select('C' + item)" src="/assets/img/{{ checkAvailable('C' + item) }}" alt=""/>
                </td>
                <td class="pasillo">
                  <img [style]="'D' + item == 'D12' || 'D' + item == 'D25' ? 'margin-bottom: 3px; margin-top: 9px' : 'margin-bottom: 3px;'" id="D{{ item }}" (click)="select('D' + item)" src="/assets/img/{{ checkAvailable('D' + item)}}"alt=""/>
                </td>
                <td>
                  <img [style]="'E' + item == 'E12' || 'E' + item == 'E25' ? 'margin-bottom: 3px; margin-top: 9px' : 'margin-bottom: 3px;'" id="E{{ item }}" (click)="select('E' + item)" src="/assets/img/{{ checkAvailable('E' + item) }}" alt=""/>
                </td>
                <td>
                  <img [style]="'F' + item == 'F12' || 'F' + item == 'F25' ? 'margin-bottom: 3px; margin-top: 9px' : 'margin-bottom: 3px;'" id="F{{ item }}" (click)="select('F' + item)" src="/assets/img/{{ checkAvailable('F' + item, item == 11 || item == 24 ? true : false) }}" alt=""/>
                </td>
              </tr>
            </table>
            <table *ngIf="model == 'a321'">
              <tr>
                <td>
                  <img id="A1" (click)="select('A1')" src="/assets/img/{{ checkAvailable('A1') }}" alt=""/>
                </td>
                <td>
                  <img id="B1" (click)="select('B1')" src="/assets/img/{{ checkAvailable('B1') }}" alt=""/>
                </td>
                <td>
                  <img id="C1" (click)="select('C1')" src="/assets/img/{{ checkAvailable('C1') }}" alt=""/>
                </td>
                <td class="pasillo">
                  <img id="D1" (click)="select('D1')" src="/assets/img/{{ checkAvailable('D1') }}" alt=""/>
                </td>
                <td>
                  <img id="E1" (click)="select('E1')" src="/assets/img/{{ checkAvailable('E1') }}" alt=""/>
                </td>
                <td>
                  <img id="F1" (click)="select('F1')" src="/assets/img/{{ checkAvailable('F1') }}" alt=""/>
                </td>
              </tr>
              <tr *ngFor="let item of filas">
                <td>
                  <img [style]="'A' + item == 'A10' || 'A' + item == 'A24' ? 'margin-bottom: 1px; margin-top: 9px' : 'margin-bottom: 1px;'" id="A{{ item }}" (click)="select('A' + item)" src="/assets/img/{{ checkAvailable('A' + item, item == 10 || item == 24 || item == 37 ? true : false) }}" alt=""/>
                </td>
                <td>
                  <img [style]="'B' + item == 'B10' || 'B' + item == 'B24' ? 'margin-bottom: 1px; margin-top: 9px' : 'margin-bottom: 1px;'" id="B{{ item }}" (click)="select('B' + item)" src="/assets/img/{{ checkAvailable('B' + item, item == 37 ? true: false) }}" alt=""/>
                </td>
                <td>
                  <img [style]="'C' + item == 'C10' || 'C' + item == 'C24' ? 'margin-bottom: 1px; margin-top: 9px' : 'margin-bottom: 1px;'" id="C{{ item }}" (click)="select('C' + item)" src="/assets/img/{{ checkAvailable('C' + item, item == 37 ? true: false) }}" alt=""/>
                </td>
                <td class="pasillo">
                  <img [style]="'D' + item == 'D10' || 'D' + item == 'D24' ? 'margin-bottom: 1px; margin-top: 9px' : 'margin-bottom: 1px;'" id="D{{ item }}" (click)="select('D' + item)" src="/assets/img/{{ checkAvailable('D' + item)}}"alt=""/>
                </td>
                <td>
                  <img [style]="'E' + item == 'E10' || 'E' + item == 'E24' ? 'margin-bottom: 1px; margin-top: 9px' : 'margin-bottom: 1px;'" id="E{{ item }}" (click)="select('E' + item)" src="/assets/img/{{ checkAvailable('E' + item) }}" alt=""/>
                </td>
                <td>
                  <img [style]="'F' + item == 'F10' || 'F' + item == 'F24' ? 'margin-bottom: 1px; margin-top: 9px' : 'margin-bottom: 1px;'" id="F{{ item }}" (click)="select('F' + item)" src="/assets/img/{{ checkAvailable('F' + item, item == 10 || item == 24 ? true : false) }}" alt=""/>
                </td>
              </tr>
            </table>
            <table *ngIf="model == 'crj700'">
              <tr style="display: flex; margin-top: 15px;">
                <div >
                  <img id="A1" (click)="select('A1')" src="/assets/img/{{ checkAvailable('A1') }}" alt="" class="bigger"/>
                </div>
                <div>
                </div>
                <div style="margin-left: 10px;">
                  <img style="margin-left: 20px;" id="C1" (click)="select('C1')" src="/assets/img/{{ checkAvailable('C1') }}" alt=""/>
                </div>
                <div>
                  <img id="D1" (click)="select('D1')" src="/assets/img/{{ checkAvailable('D1') }}" alt=""/>
                </div>
              </tr>
              <tr style="display: flex; margin-top: 15px;">
                <div >
                  <img id="A2" (click)="select('A2')" src="/assets/img/{{ checkAvailable('A2') }}" alt="" class="bigger"/>
                </div>
                <div>
                </div>
                <div style="margin-left: 10px;">
                  <img style="margin-left: 20px;" id="C2" (click)="select('C2')" src="/assets/img/{{ checkAvailable('C2') }}" alt=""/>
                </div>
                <div>
                  <img id="D2" (click)="select('D2')" src="/assets/img/{{ checkAvailable('D2') }}" alt=""/>
                </div>
              </tr>
              <tr style="display: flex; margin-top: 15px;">
                <div>
                  <img id="A3" (click)="select('A3')" src="/assets/img/{{ checkAvailable('A3') }}" alt="" class="bigger"/>
                </div>
                <div>
                </div>
                <div style="margin-left: 10px;">
                  <img style="margin-left: 20px;" id="C3" (click)="select('C3')" src="/assets/img/{{ checkAvailable('C3') }}" alt=""/>
                </div>
                <div>
                  <img id="D3" (click)="select('D3')" src="/assets/img/{{ checkAvailable('D3') }}" alt=""/>
                </div>
              </tr>
              <tr *ngFor="let item of filas">
                <div *ngIf="item > 3">
                  <td>
                    <img
                      [style]="'A' + item == 'A4' ? 'margin-bottom: 2px; margin-top: 14px' : item == 12 ? 'margin-bottom: 2px; margin-top: 7px': 'margin-bottom: 2px;'"
                      id="A{{ item }}"
                      (click)="select('A' + item)"
                      src="/assets/img/{{ checkAvailable( 'A' + item, item == 8 || item == 21 ? true : false) }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="'B' + item == 'B4' ? 'margin-bottom: 2px; margin-top: 14px': item == 12 ? 'margin-bottom: 2px; margin-top: 7px': 'margin-bottom: 2px;'"
                      id="B{{ item }}"
                      (click)="select('B' + item)"
                      src="/assets/img/{{ checkAvailable('B' + item) }}"
                      alt=""
                    />
                  </td>
                  <td class="pasillo">
                    <img
                      [style]="'C' + item == 'C4' ? 'margin-bottom: 2px; margin-top: 14px': item == 12 ? 'margin-bottom: 2px; margin-top: 7px': 'margin-bottom: 2px;'"
                      id="C{{ item }}"
                      (click)="select('C' + item)"
                      src="/assets/img/{{
                        checkAvailable('C' + item)
                      }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="'D' + item == 'D4' ? 'margin-bottom: 2px; margin-top: 14px' : item == 12 ? 'margin-bottom: 2px; margin-top: 7px': 'margin-bottom: 2px;'"
                      id="D{{ item }}"
                      (click)="select('D' + item)"
                      src="/assets/img/{{
                        checkAvailable('D' + item)
                      }}"
                      alt=""
                    />
                  </td>
                </div>
              </tr>
            </table>
            <table *ngIf="model == 'crj900'">
              <tr style="display: flex; margin-top: 27px;">
                <div >
                  <img id="A1" (click)="select('A1')" src="/assets/img/{{ checkAvailable('A1') }}" alt="" class="bigger"/>
                </div>
                <div>
                </div>
                <div style="margin-left: 10px;">
                  <img style="margin-left: 20px;" id="C1" (click)="select('C1')" src="/assets/img/{{ checkAvailable('C1') }}" alt=""/>
                </div>
                <div>
                  <img id="D1" (click)="select('D1')" src="/assets/img/{{ checkAvailable('D1') }}" alt=""/>
                </div>
              </tr>
              <tr style="display: flex; margin-top: 10px;">
                <div >
                  <img id="A2" (click)="select('A2')" src="/assets/img/{{ checkAvailable('A2') }}" alt="" class="bigger"/>
                </div>
                <div>
                </div>
                <div style="margin-left: 10px;">
                  <img style="margin-left: 20px;" id="C2" (click)="select('C2')" src="/assets/img/{{ checkAvailable('C2') }}" alt=""/>
                </div>
                <div>
                  <img id="D2" (click)="select('D2')" src="/assets/img/{{ checkAvailable('D2') }}" alt=""/>
                </div>
              </tr>
              <tr style="display: flex; margin-top: 10px;">
                <div>
                  <img id="A3" (click)="select('A3')" src="/assets/img/{{ checkAvailable('A3') }}" alt="" class="bigger"/>
                </div>
                <div>
                </div>
                <div style="margin-left: 10px;">
                  <img style="margin-left: 20px;" id="C3" (click)="select('C3')" src="/assets/img/{{ checkAvailable('C3') }}" alt=""/>
                </div>
                <div>
                  <img id="D3" (click)="select('D3')" src="/assets/img/{{ checkAvailable('D3') }}" alt=""/>
                </div>
              </tr>
              <tr style="display: flex; margin-top: 10px;">
                <div>
                  <img id="A4" (click)="select('A4')" src="/assets/img/{{ checkAvailable('A4') }}" alt="" class="bigger"/>
                </div>
                <div>
                </div>
                <div style="margin-left: 10px;">
                  <img style="margin-left: 20px;" id="C4" (click)="select('C4')" src="/assets/img/{{ checkAvailable('C4') }}" alt=""/>
                </div>
                <div>
                  <img id="D4" (click)="select('D4')" src="/assets/img/{{ checkAvailable('D4') }}" alt=""/>
                </div>
              </tr>
              <tr *ngFor="let item of filas">
                <div *ngIf="item > 4">
                  <td>
                    <img
                      [style]="'A' + item == 'A5' ? 'margin-bottom: 1px; margin-top: 20px' : item == 10 ? 'margin-bottom: 1px; margin-top: 5px': item == 12 || item == 13 ? 'margin-bottom: 1px; margin-top: 5px': 'margin-bottom: 1px;'"
                      id="A{{ item }}"
                      (click)="select('A' + item)"
                      src="/assets/img/{{ checkAvailable( 'A' + item) }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="'B' + item == 'B5' ? 'margin-bottom: 1px; margin-top: 20px': item == 10 ? 'margin-bottom: 1px; margin-top: 5px': item == 12 || item == 13 ? 'margin-bottom: 1px; margin-top: 5px': 'margin-bottom: 1px;'"
                      id="B{{ item }}"
                      (click)="select('B' + item)"
                      src="/assets/img/{{ checkAvailable('B' + item) }}"
                      alt=""
                    />
                  </td>
                  <td class="pasillo">
                    <img
                      [style]="'C' + item == 'C5' ? 'margin-bottom: 1px; margin-top: 20px': item == 10 ? 'margin-bottom: 1px; margin-top: 5px': item == 12 || item == 13 ? 'margin-bottom: 1px; margin-top: 5px': 'margin-bottom: 1px;'"
                      id="C{{ item }}"
                      (click)="select('C' + item)"
                      src="/assets/img/{{
                        checkAvailable('C' + item, item == 19 ? true : false)
                      }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="'D' + item == 'D5' ? 'margin-bottom: 1px; margin-top: 20px' : item == 10 ? 'margin-bottom: 1px; margin-top: 5px': item == 12 || item == 13 ? 'margin-bottom: 1px; margin-top: 5px': 'margin-bottom: 1px;'"
                      id="D{{ item }}"
                      (click)="select('D' + item)"
                      src="/assets/img/{{
                        checkAvailable('D' + item, item == 19 ? true : false)
                      }}"
                      alt=""
                    />
                  </td>
                </div>
              </tr>
            </table>
            <table *ngIf="model == 'embraerE190'">
              <tr style="display: flex; margin-top: 17px;">
                <div>
                </div>
                <div>
                </div>
                <div style="margin-left: 35px;">
                  <img style="margin-left: 20px; height: 23px;" id="C1" (click)="select('C1')" src="/assets/img/{{ checkAvailable('C1') }}" alt=""/>
                </div>
                <div>
                  <img style="height: 23px;" id="D1" (click)="select('D1')" src="/assets/img/{{ checkAvailable('D1') }}" alt=""/>
                </div>
              </tr>
              <tr style="display: flex; margin-top: 18px;">
                <div>
                  <img style="height: 23px;" id="A2" (click)="select('A2')" src="/assets/img/{{ checkAvailable('A2') }}" alt=""/>
                </div>
                <div>
                  <img style="height: 23px;" id="B2" (click)="select('B2')" src="/assets/img/{{ checkAvailable('B2') }}" alt=""/>
                </div>
                <div style="margin-left: 1px;">
                  <img style="margin-left: 15px; height: 23px;" id="C2" (click)="select('C2')" src="/assets/img/{{ checkAvailable('C2') }}" alt=""/>
                </div>
                <div>
                  <img style="height: 23px;" id="D2" (click)="select('D2')" src="/assets/img/{{ checkAvailable('D2') }}" alt=""/>
                </div>
              </tr>
              <tr *ngFor="let item of filas">
                <div *ngIf="item > 2">
                  <td>
                    <img
                      [style]="'A' + item == 'A3' ? 'margin-bottom: 0px; margin-top: 25px' : item == 13 ? 'margin-bottom: 0px; margin-top: 12px':'margin-bottom: 0px;'"
                      id="A{{ item }}"
                      (click)="select('A' + item)"
                      src="/assets/img/{{ checkAvailable( 'A' + item) }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="'B' + item == 'B3' ? 'margin-bottom: 0px; margin-top: 25px' : item == 13 ? 'margin-bottom: 0px; margin-top: 12px': 'margin-bottom: 0px;'"
                      id="B{{ item }}"
                      (click)="select('B' + item)"
                      src="/assets/img/{{ checkAvailable('B' + item) }}"
                      alt=""
                    />
                  </td>
                  <td class="pasillo">
                    <img
                      [style]="'C' + item == 'C3' ? 'margin-bottom: 0px; margin-top: 25px' : item == 13 ? 'margin-bottom: 0px; margin-top: 12px': 'margin-bottom: 0px;'"
                      id="C{{ item }}"
                      (click)="select('C' + item)"
                      src="/assets/img/{{
                        checkAvailable('C' + item, item == 19 ? true : false)
                      }}"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      [style]="'D' + item == 'D3' ? 'margin-bottom: 0px; margin-top: 25px' : item == 13 ? 'margin-bottom: 0px; margin-top: 12px':'margin-bottom: 0px;'"
                      id="D{{ item }}"
                      (click)="select('D' + item)"
                      src="/assets/img/{{
                        checkAvailable('D' + item, item == 19 ? true : false)
                      }}"
                      alt=""
                    />
                  </td>
                </div>
              </tr>
            </table>
            <table *ngIf="model == 'boeing2'">
              <tr>
                <td>
                  <img
                    id="A1"
                    (click)="select('A1')"
                    src="/assets/img/{{ checkAvailable('A1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="B1"
                    (click)="select('B1')"
                    src="/assets/img/{{ checkAvailable('B1') }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    id="C1"
                    (click)="select('C1')"
                    src="/assets/img/{{ checkAvailable('C1') }}"
                    alt=""
                  />
                </td>
                <td class="pasillo"><img
                  id="D1"
                  (click)="select('D1')"
                  src="/assets/img/{{ checkAvailable('D1') }}"
                  alt=""
                /></td>
                <td><img
                  id="E1"
                  (click)="select('E1')"
                  src="/assets/img/{{ checkAvailable('E1') }}"
                  alt=""
                /></td>
                <td><img
                  id="F1"
                  (click)="select('F1')"
                  src="/assets/img/{{ checkAvailable('F1') }}"
                  alt=""
                /></td>
              </tr>
              <tr *ngFor="let item of filas">
                <td>
                  <img
                    style="margin-bottom: 3px"
                    id="A{{ item }}"
                    (click)="select('A' + item)"
                    src="/assets/img/{{ checkAvailable('A' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 3px"
                    id="B{{ item }}"
                    (click)="select('B' + item)"
                    src="/assets/img/{{ checkAvailable('B' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 3px"
                    id="C{{ item }}"
                    (click)="select('C' + item)"
                    src="/assets/img/{{ checkAvailable('C' + item) }}"
                    alt=""
                  />
                </td>
                <td class="pasillo">
                  <img
                    style="margin-bottom: 3px"
                    id="D{{ item }}"
                    (click)="select('D' + item)"
                    src="/assets/img/{{ checkAvailable('D' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 3px"
                    id="E{{ item }}"
                    (click)="select('E' + item)"
                    src="/assets/img/{{ checkAvailable('E' + item) }}"
                    alt=""
                  />
                </td>
                <td>
                  <img
                    style="margin-bottom: 3px"
                    id="F{{ item }}"
                    (click)="select('F' + item)"
                    src="/assets/img/{{ checkAvailable('F' + item) }}"
                    alt=""
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
