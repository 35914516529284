<div class="pricing">
  <div class="banner">
  </div>
  <div class="info mt-5 mb-5 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-9 col-md-10">
          <h1>
            <!-- It is important to us that our clients know the value of our services -->
            Transparency is one of our core values. We want our customers to
            fully understand all the variables of private charter pricing.
          </h1>
        </div>
      </div>
      <div class="row mt-5 pb-5">
        <div class="col-xl-7 col-lg-9 col-md-12">
          <h1>The FronteraSky Promise</h1>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12">
          <p>
            Rest assured that FronteraSky’s charter consultants will review all
            possible cost-saving measu- res to make your journey as efficient as
            possible. Some of these measures may include availa- ble empty legs
            (one way charter flights by aircraft that are returning empty to
            their base airport), multiple aircraft in order to avoid overnight
            expenses, or locating diverse types of aircraft that may be located
            closer to your departure airport.
          </p>
        </div>
      </div>
      <div class="row pt-5">
        <div class="col-xl-2 col-lg-2 col-md-2 image">
          <img src="./assets/img/icono_basic-pricing.png" alt="" class="img-fluid" />
        </div>
        <div class="col-xl-10 col-lg-10 col-md-10 info">
          <h6>
            Basic pricing
          </h6>
          <p>
            Typically, air charter operations are priced by hour of operation
            plus additional costs such as landing fees, fuel surcharges,
            overnight and per diem costs for the crew if necessary, etc.On
            average the basic hourly flying rate for turboprops and very light
            jets fluctuates between $1,500 to $3,000; light and midsize jets can
            vary between $4,000 to 6,500 per flying hour; large jets can cost
            between $7,000 to $12,500 or more per flying hour.
          </p>
        </div>
      </div>
      <div class="row pt-5">
        <div class="col-xl-2 col-lg-2 col-md-2 image">
          <img src="./assets/img/icono_additional-fees.png" alt="" class="img-fluid padding-top" />
        </div>
        <div class="col-xl-10 col-lg-10 col-md-10 info">
          <h6>
            Aditional fees
          </h6>
          <p>
            Landing fees: These are required by most airports around the world
            to cover their own maintenance of the runways, safety equipment, and
            terminals. Generally vary between $100 to $500 per landing. Federal
            excise and segment taxes: Applied, in the United States, by the
            Federal Government. The excise tax is 7.5% of each flight and the
            segment taxes are calculated on a per passenger basis at around $8
            per person.Ramp fees: Charged by the airport when the aircraft must
            stay for an extend period of time.Fuel: Although the basic hourly
            price covers fuel consumed between the departure and destination, a
            fuel surcharge may be charged to cover the actual consumption if it
            exceeded the estimated quantity.Wait time fees: These fees are
            billed for the time the aircraft is waiting for you at the
            destination airport.Crew-related fees: Additional fees may be
            incurred in connection with the time the crew assigned to you
            actually remains dedicated to your trip. Overnight fees and per diem
            are customary when the crew has to stay at your destination city
            overnight and cover lodging and food. These fees typically range
            between $200 to $400 per night per crew member.
          </p>
        </div>
      </div>
      <div class="row pt-5">
        <div class="col-xl-2 col-lg-2 col-md-2 image">
          <img src="./assets/img/icono_fees-region.png" alt="" class="img-fluid" />
        </div>
        <div class="col-xl-10 col-lg-10 col-md-10 info">
          <h6>
            Different fees per region
          </h6>
          <p>
            Prices can vary, sometimes significantly, depending on the region of
            the world where your journey takes place. Also, cost variables such
            as local fuel prices, landing fees, and cost of living for the crew,
            will dictate the differences in price.
          </p>
        </div>
      </div>
      <div class="row pt-5">
        <div class="col-xl-2 col-lg-2 col-md-2 image">
          <img src="./assets/img/icono_challenging-destinations.png" alt="" class="img-fluid width-different" />
        </div>
        <div class="col-xl-10 col-lg-10 col-md-10 info">
          <h6>
            Challenging environments
          </h6>
          <p>
            FronteraSky has experience and presence in locations that can be
            consideredchallenging environments such as Iraq, Afghanistan, and
            Colombia. Operating in such environments brings a series of
            challenges of their own like security requirements, coordination
            with local and international authorities, need for armored
            transportation, etc. These destinations will require different price
            considerations.
          </p>
        </div>
      </div>
      <!-- 
      <div class="row pt-5">
        <div class="container" style="width: 50%; margin: 0 auto;">
          <div class="col-xl-12 col-lg-12 col-md-12">
            <form [formGroup]="payFrom" class="form">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-2 col-form-label"
                  >Email</label
                >
                <div class="col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    id="staticEmail"
                    value="email@example.com"
                    formControlName="email"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="value" class="col-sm-2 col-form-label"
                  >Password</label
                >
                <div class="col-sm-10">
                  <input
                    type="number"
                    class="form-control"
                    id="value"
                    placeholder="Value"
                    formControlName="value"
                  />
                </div>
              </div>

              <ngx-paypal
                *ngIf="payFrom.valid"
                [config]="payPalConfig"
              ></ngx-paypal>
            </form>
          </div>
        </div>
      </div>
    -->
    </div>
  </div>
</div>