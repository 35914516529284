<section class="hero"></section>
<!-- <section class="container outservice">
    <div class="row">
        <div class="col-xl-7 col-lg-9 col-md-9 col-sm-9 col-12">
            <h1>FronteraSky</h1>
            <p>
                FronteraSky is a flight broker and as such arranges carriage by air by chartering aircraft from third-party aircraft operators, acting as agent, in the name and on behalf of its customers. FronteraSky only acts as an intermediary, does not itself operate
                aircraft and is not a contracting or an indirect carrier.
            </p>


        </div>
    </div>
</section> -->
<div class="info">
  <div class="container info_container">
  <div class="row mt-5 opt3 pt-5">
    <div class="col-lg-6 president">
      <picture>
        <source type="image/png" srcset="./assets/img/ceo-carlosbarbosa3.png">
        <source type="image/webp" srcset="./assets/img/Escritorio/Inicio/WebP/es-ceo-carlosbarbosa3.webp">
        <img src="/assets/img/Imágenes JPG 2/Escritorio/Inicio/es-ceo-carlosbarbosa3.jp2" alt="FronteraSky Image">
      </picture>
    </div>
    <div class="col-lg-6">
      <p>
        Our main objective at FronteraSky is to provide safe and reliable private aviation experiences for our clients. With dedicated presence in four continents, we assure quick response times regardless of where in the world you happen to be today. Our network of hand-picked operators complies with all international aviation regulations and utilize a fleet of modern aircraft. Our team of experienced charter consultants is ready to support you today, wherever you are. We have significant experience with local regulations, legislations, cultures, and languages. Whether you need an emergency evacuation from a challenging location or desire a family or business getaway on a private jet, for safety or security reasons, FronteraSky can help. 
        <br /><br /> Carlos Barbosa
        <br> President, FronteraSky
      </p>
    </div>
  </div>
</div>
</div>
<!-- <div class="primary pb-5">
  <section class="container team mt-5">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-10 col-sm-10 col-12 texto">
        <h1>Meet the team</h1>
        <p class="row">
          Our team of experienced charter consultants are ready to support you
          today, wherever you are. We have significant experience with local
          regulations, legislations, cultures, and languages.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
        <div class="card">
          <picture>
            <source
              type="image/png"
              srcset="./assets/img/es-carlos-barbosa.png"
            />
            <source
              type="image/jp2"
              srcset="/assets/img/Imágenes JPG 2/Escritorio/About Us/es-carlos-barbosa.jp2"
            />
            <img
              src="./assets/img/Escritorio/About Us/WebP/es-carlos-barbosa.webp"
              alt="FronteraSky Image"
              class="img-fluid"
            />
          </picture>
          <div class="card-body row">
            <p class="card-text">
              Carlos J. Barbosa <br />President <br />
              <a href="mailto:cjbarbosa@fronterasky.com">Contact Now</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
        <div class="card">
          <picture>
            <source
              type="image/png"
              srcset="./assets/img/es-mohammed-zubair.png"
            />
            <source
              type="image/jp2"
              srcset="/assets/img/Imágenes JPG 2/Escritorio/About Us/mo-mohammed-zubair.jp2"
            />
            <img
              src="./assets/img/Escritorio/About Us/WebP/mo-mohammed-zubair.webp"
              alt="FronteraSky Image"
              class="img-fluid"
            />
          </picture>
          <div class="card-body row">
            <p class="card-text">
              Mohammed Zubair <br />Operations Manager <br />
              <a href="mailto:mohammed@fronterasky.com">Contact Now</a>
            </p>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
        <div class="card">
          <picture>
            <source
              type="image/png"
              srcset="./assets/img/es-fabricia-galvao.png"
            />
            <source
              type="image/jp2"
              srcset="/assets/img/Imágenes JPG 2/Escritorio/About Us/es-fabricia-galvao.jp2"
            />
            <img
              src="./assets/img/Escritorio/About Us/WebP/es-fabricia-galvao.webp"
              alt="FronteraSky Image"
              class="img-fluid"
            />
          </picture>
          <div class="card-body row">
            <p class="card-text">
              Fabricia Galvao <br />Charter Consultant<br />
              <a href="mailto:fgalvao@fronterasky.com">Contact Now</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
        <div class="card">
          <picture>
            <source
              type="image/png"
              srcset="./assets/img/es-kristina-mitic.png"
            />
            <source
              type="image/jp2"
              srcset="/assets/img/Imágenes JPG 2/Escritorio/About Us/es-kristina-mitic.jp2"
            />
            <img
              src="./assets/img/Escritorio/About Us/WebP/es-kristina-mitic.webp"
              alt="FronteraSky Image"
              class="img-fluid"
            />
          </picture>
          <div class="card-body row">
            <p class="card-text">
              Kristina Mitic <br />Charter Consultant
              <br />
              <a href="mailto:kmitic@fronterasky.com">Contact Now</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
        <div class="card">
          <picture>
            <source
              type="image/png"
              srcset="./assets/img/es-saniya -zeinekeshova.png"
            />
            <img
              src="./assets/img/Escritorio/About Us/WebP/es-saniya -zeinekeshova.webp"
              alt="FronteraSky Image"
              class="img-fluid"
            />
          </picture>
          <div class="card-body row">
            <p class="card-text">
              Saniya Zeinekeshovac <br />Charter Consultant
              <br />
              <a href="mailto:szeinek@fronterasky.com">Contact Now</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div> -->
<section class="fs">
  <div class="row">
    <div class="container">
      <div>
        <p class="textoFinal">
          FronteraSky LLC and FronteraSky Aviation Services LLC, are limited
          liability companies incorporated in the state of Florida, United
          States of America, with a registered address at 6526 S Kanner Hwy.
          Suite 255, Stuart, FL 34997, and in Dubai, United Arab Emirates, with
          a registered address at Office 3208, MediaOne Tower, Media City, Dubai
          respectively.
        </p>
        <br />
      </div>
      <div class="col-xl-7 col-lg-7 col-md-9 col-sm-10 col-12 no-padding">
        <h1>
          Journeys <br />
          that transform <br />
          the destination.
        </h1>
      </div>
      <div class="col-7 no-padding">
        <img
          src="/assets/img/logo_seccion-aboutus.png"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>
  </div>
</section>
