import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vip-airport',
  templateUrl: './vip-airport.component.html',
  styleUrls: ['./vip-airport.component.sass']
})
export class VipAirportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
