<div class="hero ">
    <img src="./assets/img/WhiteGloves/es_catering.png" class="img-fluid" alt="">
</div>

<div class="privateAviation pt-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Exclusive catering
                    on board</h1>
            </div>

            <div class="col-12 pt-5 pb-5 parrafo">
                <h5>
                    Dining on board the private jet is one of the highlights of the business aviation experience. The
                    objective of our team is to meet your gourmet expectations and do it as efficiently as possible.
                    FronteraSky partners with top star restaurants and chefs all over the globe. The purpose of
                    FronteraSky’s VIP catering is to highlight the bespoke atmosphere of your journey by providing the
                    option of selecting an exclusive on board menu according to your preferences.
                </h5>
            </div>
        </div>
        <div class="row pt-5 pb-5">
            <div class="col-12">
                <h1> VIP catering menu on board of the private jet
                    has no limitations.</h1>
            </div>
            <div class="col-12">
                <p>
                    FronteraSky team listens to the most demanding requests of our passengers and delivers the menu
                    prepared with care and love to each
                    of our clients. Whether it is a surprise birthday cake, your favorite black caviar matched with a
                    bottle of champagne, or sandwiches with Nutella and strawberries on gluten-free bread for your
                    children, FronteraSky has you covered!
                </p>
                <p>
                    We create the atmosphere of the restaurant above the clouds where world famous sky chefs are
                    following rare recipes perfectly combined with traditional notes and modern nuances. A wide
                    selection of global cuisine is offered, including vegetarian, gluten-free, kosher, halal, low
                    calorie, and special meals for children. After the necessary processing, the dishes are delivered to
                    your private jet following the protocol of extensive safety and cleanliness in order to retain the
                    flawless flavor qualities and impeccable restaurant look. Place your order and we will do our best
                    to deliver it on board.
                </p>
            </div>
        </div>
    </div>
</div>
