import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ScriptService } from '../../../services/script.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
})
export class HomeComponent implements OnInit {
  avinodeApiLoaded = false;
  @ViewChild('videoContainer', { static: true }) videoContainer: ElementRef;
  @ViewChild('imageContainer', { static: true }) imageContainer: ElementRef;

  public idVideo: any = 'this.videoContainer';
  constructor(private scriptService: ScriptService, private route: Router) {
    // route.events.subscribe((val) => {
      // see also
      // console.log('Hola mundo');
      this.loadAvinode()
    // });
  }

  ngOnInit():void { 

  }

  changeIframeVideo():void{
    console.log('object');
    this.videoContainer.nativeElement.style.display='block';
    this.imageContainer.nativeElement.style.display='none';
  }

  loadAvinode(): void {
    this.scriptService
      // one or more arguments
      .load('avinode')
      .then((data) => {
        // script is loaded, use it
        this.avinodeApiLoaded = true;
      });
  }
}
