<div class="hero ">
    <img src="./assets/img/WhiteGloves/es_meetandgreet.png" class="img-fluid" alt="">
</div>

<div class="privateAviation pt-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>VIP/VVIP airport <br>
                    meet and greet</h1>
            </div>

            <div class="col-12 pt-5 pb-5 parrafo">
                <p class="tituloSecundario">FronteraSky provides Airport Meet and Greet <br>
                    as a part of our exclusive White Glove Service.</p>
                <p>
                    <b>*On Arrival</b><br>
                    The guest is greeted by the personal assistant at the point of arrival and accompanied by the
                    limousine driver to the VIP executive lounge of the airport in case the immigration formalities
                    cannot be completed inside your private chartered jet. The executive lounge on arrival is a
                    beautiful suite, where our passenger is offered drinks while the concierge is taking care of
                    passport control and luggage. If the customs officers or immigration officials have any additional
                    questions, the process will be completed at the VIP lounge room. Then the passenger is escorted to
                    the chauffeur who is waiting at the entrance to the VIP lounge. The luggage porter will take
                    extensive care of the valuable belongings and load your premium vehicle to deliver the suitcases to
                    your preferred accommodation.
                </p>
                <h5>In order to proceed with a yacht reservation, request a quote from our FronteraSky yacht charter
                    consultant. Our dedicated team is eager to book your unforgettable voyage 24/7!</h5>
            </div>
        </div>
    </div>
</div>
<div class="popular">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <p>
                    <b>*On Departure</b><br>
                    Our professional chaufzr will pick you up at your location in a premium vehicle and will take care
                    of the luggage. The passenger will arrive at the airport and will be escorted to the terminal and
                    lounge that accommodates the VIP passengers of the private charter flights. The exclusive menu of
                    beverages, fine spirits, and delicacies will be offered while the immigration formalities are
                    completed and the luggage is loaded on the private charter jet. After the announcement of boarding
                    the flight, a limousine takes our VIP passenger right to the plane ramp and the personal assistant
                    will be wishing a pleasant journey with FronteraSky.
                    <br><br>
                    Contact FronteraSky charter consultants to request a quote. We are here to listen to your wishes
                    24/7!
                </p>
            </div>
        </div>
    </div>
</div>
<div>
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <p class="textoFinal"><b>*The accessibility of the full Meet and Greet service varies from the points of departure and arrival
                    according to the local government regulations and airport facilities.</b></p>
            </div>
        </div>
    </div>
</div>