<div class="hero ">
    <img src="./assets/img/articulo-medical-tourism.png" alt="">
</div>

<div class="mediacalourism pt-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Medical Tourism Charter tours have turned into a separate full-fledged industry with various types of services, destinations, and associations and international industry exhibitions.</h1>
            </div>

            <div class="col-12 pt-5 pb-5">
                <p>
                    Medical Tourism Industry didn’t emerge at once. The great demand for foreign medical services in some countries is due to the lack of highly qualified specialists in others, the lack of equipment and specialists for the treatment of complex and rare forms
                    of oncological, neurological and some other diseases.

                </p>
            </div>
        </div>

    </div>
</div>

<div class="popular">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <p class="title"> The most popular Medical Tourism Charter destinations:
                </p>
            </div>
            <div class="col-12">
                <ul>
                    <li>Diagnostics Destinations Charter Flights</li>
                    <li>Medical Treatment Private Flights</li>
                    <li>Plastic Surgery Medical Tourism Charters</li>
                    <li>Spa Resorts Medical Tourism Charter</li>
                    <li>Birth Abroad Charter Tours</li>
                </ul>
            </div>
            <div class="col-12">
                <p>For some 15 years of medical tourism, certain stereotypes were formed about the level of medical treatment in a particular country. Brazil, Thailand and South Korea are known for their high-end plastic surgery, Germany - for the treatment
                    of the cardiovascular system, Israel - for outstanding developments in the diagnosis and treatment of infertility and oncology.
                </p>
            </div>
            <div class="col-12 pt-3">
                <p class="title">Modern medical tourism can be divided into three large groups:</p>
                <ul>
                    <li>General medical tourism combined with the stay in resort </li>
                    <li>Diagnostic - offers entire sets of medical examination for the identification of specific diseases (for example, hereditary).</li>
                    <li>Actual medical treatment: complex operations (for example, cardiological), lengthy procedures (for example, infertility treatment). All plastic surgery can also be included here.</li>
                </ul>
            </div>
            <div class="col-12">
                <p>FronteraSky is widely known among the largest medical centers worldwide. Medical Tourism Charter Tours include medical and surgical procedures, medicines, accommodation, translation services, lawyer and legal services assistance, caregivers
                    and more. And, of course, the medical tourism charter flight itself. Flight in a private jet in this case is the best and most frequently used option, as it provides not only the necessary comfort, but also a certain level of privacy
                    and security. In some cases, if the presence of medical personnel and special equipment on board is required - then a medical tourism charter flight is the only reliable option.</p>
            </div>
        </div>


    </div>
</div>

<div class="advantages pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p class="title"> The Advantages of Traveling with FronteraSky Medical Tourism Chart Flights
                </p>
            </div>
            <div class="col-12">
                <p>
                    High quality service: Medical Tourism Charter flight is an exclusive opportunity to combine the purpose of the trip with an executive comfort on the private jet. White Glove Service on arrival to your destination point will care of the airport transfer
                    on your favorite vehicle, provision of the private butler or assistant on your request, shopping manager and professional bilingual tour guide to fill your free time with the experience and good memories about the location’s best attractions.

                </p>
            </div>
            <div class="col-12">
                <ul>
                    <li>Time saving: Medical Tourism Charter is the flight without delays, tiring layovers, crowded airport terminals, waiting for baggage or long ques on immigration check points. </li>
                    <li>Opportunities for travel. You can combine business with pleasure and not only receive the medical treatment, but also see the completely new destination using the medical tourism charter flights traveling option.
                    </li>
                    <li>COVID-19 Free Flight: the use of VIP terminals contributes to reducing contacts with potentially infected passengers. Direct access to the runway allows the chauffeur to transfer VIP passenger directly to the jet. Instead of spending
                        several hours in the airport crowded by travelers from around the world, you can choose who to add to the list of passengers on your private flight and reduce the risk of coronavirus infection to zero. Furthermore, Medical Tourism
                        Charter jets are less likely to be in the air as much as commercial flight aircrafts and they carry significantly fewer passengers, which makes it easier to disinfect after each flight, eliminating the risk of potential infection.
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="popular2">
    <div class="container pt-5 pb-5">
        <div class="row">

            <div class="col-12">
                <p>
                    Patients interested in seeking medical services abroad should always consider the pros and cons before making a decision. However, when it comes to the after treatment process of the serious injury or rehabilitation of drug and alcohol addiction the answer
                    is often unambiguous – the patient has to go abroad. Medical tourism charters of FronteraSky can deliver the patient to the most exclusive rehabilitation clinics worldwide in conditions of full comfort and confidentiality.
                </p>
            </div>

        </div>
    </div>
</div>

<div class="advantages pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p class="title">
                    Rehabilitation in a clinic abroad
                </p>
            </div>
            <div class="col-12">
                <p>
                    A lot of people who experience an unfortunate case of particular addiction or the consequences of severe physical injury often do not want to undergo rehabilitation because they are concerned of the recovery process, exposure of their personal information
                    and identity as well as undergoing stressful trip on the crowded commercial flights. Medical tourism rehabilitation charter flights offer the maximized solutions to any request of particular passenger and transfers the patient to the
                    rehabilitation destinations all over the world. Detoxification and rehabilitation medical tourism charter flights guarantee an exclusive comfort of the luxury trip on the private jet in the atmosphere of full privacy combined with
                    the unique opportunities to reach the most famous therapy clinics and resorts all over the world. FronteraSky provides the chauffeur services as well as the full assistance of the medical personnel onboard on request of the patient.
                </p>
            </div>
            <div class="col-12">
                <p class="title">
                    Detoxification Treatment Abroad
                </p>
                <ul>
                    <li>
                        Medical tourism rehabilitation charter flights <br> Have always been considered as a solid traveling option for the patients with various neurological, musculo-skeletal, and orthopedic conditions. Although each rehabilitation program
                        has its own philosophy regarding treatment and care, there are three steps that play a role in almost every rehabilitation center: actual treatment, detoxification, counseling and follow-up care. Detoxification is divided into
                        two main types: natural and medical.
                    </li>
                    <li>
                        Natural detoxification <br> takes place without medical intervention, under the care and supervision of medical professionals, who should help a person cope with withdrawal of various symptoms. Withdrawal symptoms are not always
                        life threatening, but can cause discomfort, which leads trelapse (which is why medical support is so important).
                    </li>
                    <li>
                        Medical detoxification <br> Includes the treatment by the use of the prescribed medicine.
                    </li>
                    <li>
                        Therapy / Counseling <br> Counseling focuses on the psychological aspect of rehabilitation and emotional part of treating the addiction, post symptoms of the physical injury and emotional abuse. These are private, individual meetings
                        (individual counseling), group meetings (group counseling) or the counseling of the full family of the patient (family counseling). All these types of counseling have a common goal - to analyze a person’s personal history in order
                        to better understand the “triggers” of psychological symptoms or addiction and to develop a plan of the better respond to stressful situations in the future.
                    </li>
                    <li>
                        Follow-up care <br> Ss a wide range of programs aimed at helping a patient not to lose their temper after completing a program and organically integrate into society, find a job, and return to their families. These programs can
                        be very diverse and also depend on the particular case.
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>