import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-tourism',
  templateUrl: './medical-tourism.component.html',
  styleUrls: ['./medical-tourism.component.sass']
})
export class MedicalTourismComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
