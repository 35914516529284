import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AppService } from './../../../services/app.service';
//import * as $ from "jquery";
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
})
export class FooterComponent implements OnInit {
  contactFrom = new FormGroup({
    title: new FormControl(null, Validators.required),
    name: new FormControl('', [Validators.required]),
    company: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required])
  });
  cookie =  true;
  constructor(private readonly _appService: AppService, private route: Router) {
    this.route.events.subscribe((val) => {
      // see also
      this.dissmisModal(val)
    });
  }

  year = new Date().getFullYear();

  dissmisModal(route: any) {
    // console.log(route.url);
    if (route.url == '/cookies-policy') {
      setTimeout(()=> {

        $('#cookiesBar').removeClass('cookie-bar').addClass('close-cb')
        this.saveCookies()
        const cookies = localStorage.getItem('cookies')
        console.log(cookies)

      }, 20000)
    }
  }

  ngOnInit(): void {
    const cookies = localStorage.getItem('cookies')
    // console.log(cookies);
     if(cookies=='cookies'){
      this.cookie = false;
     }else{
       setTimeout(()=>{
         this.cookie = false;
         this.saveCookies();
       },20000)
     }

  }

  saveCookies():void {
    localStorage.setItem('cookies','cookies')
   // $('#staticBackdrop').modal('toggle')
  }

  sendData() {
    const form = this.contactFrom.value;
    this._appService.createFooterContact(form).then((response)=> {
      this.contactFrom.reset();
      Swal.fire(
        'Confirmation',
        'information has been sent successfully',
        'success'
      );
    }).catch((error) => {
      console.log('hay un error');
      console.log(error);
    });
  }
}
