import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { BtnWhatsappComponent } from './btn-whatsapp/btn-whatsapp.component';

@NgModule({
  declarations: [NavComponent, FooterComponent, BtnWhatsappComponent],
  imports: [CommonModule, RouterModule, ReactiveFormsModule],
  exports: [NavComponent, FooterComponent,BtnWhatsappComponent],
})
export class SharedModule {}
