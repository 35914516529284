<div class="hero">
</div>
<div class="container mt-5 mb-5 info">
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <div class="card">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <!-- <h1 style=" margin-top: 10px; text-align: center; padding: 0 30px;">Select the payment method for your ticket.</h1> -->
              <h1 style=" margin-top: 10px; text-align: center; padding: 0 30px;">Select the payment method for your
                purchase.</h1>
              <form [name]="paymentForm" class="form" [formGroup]="paymentForm" (ngSubmit)="search()"
                autocomplete="off">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <!-- <label for="bookingRef">Search your booking (using your flight reference)</label> -->
                      <label for="bookingRef">Search for your booking using your flight reference number</label>
                      <input type="text" class="form-control" id="bookingRef" formControlName="bookingRef" required>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="totalAmounthValue">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="totalAmounthValue">Total amount</label>
                      <input type="text" class="form-control" id="totalAmounthValue" [value]="'$'+totalAmounthValue"
                        disabled readonly>
                    </div>
                  </div>
                </div>
                <div class="row btn-search">
                  <div class="col-md-3"></div>
                  <div class="col-md-6">
                    <button type="submit" class="btn btn-success btn-sm btn-block" [disabled]="paymentForm.invalid">
                      Search
                    </button>
                  </div>
                  <div class="col-md-3"></div>
                </div>
              </form>
            </div>
          </div>
          <div class="row" *ngIf="payPalConfig">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <ngx-paypal [config]="payPalConfig"></ngx-paypal>
            </div>
            <div class="col-md-3"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2"></div>
  </div>
</div>