<div class="hero ">
</div>

<div class="popular">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <h4>Terms of Use</h4>
            </div>
        </div>
    </div>
</div>

<div class="">
    <div class="container pt-5 pb-5">
        <div class="row">

            <div class="col-12">
                <p>
                    <b> Legal Disclaimer</b> <br> FronteraSky's website has been compiled in good faith. It provides information of a general nature about the company, services and products. While FronteraSky has taken reasonable steps to ensure that
                    such information is accurate, the company makes no warranties or representations - expressed or implied - regarding the accuracy and completeness of this website and its information, or any websites linked to this website. Users of
                    this website, its information and any websites linked to this website must rely solely on their own assessment and verification.<br> Neither FronteraSky, nor the company's affiliates, officers and employees, shall be responsible or
                    liable for any direct, indirect, incidental, consequential or special loss or damages however arising (whether by negligent act, omission or otherwise) out of the use of, or reliance upon, this website and its information, or any websites
                    linked to this website.
                    <br><br>
                    <b> Copyright Notice</b><br> FronteraSky' website and its information are protected by copyright, trademark and other intellectual property laws unless otherwise indicated and is the sole property of FronteraSky. Any unauthorized use,
                    copying, rewriting, publication, broadcasting, distribution or exploitation of this website and its information is strictly prohibited by FronteraSky. You may display or print this website and its information solely when visiting and
                    in the usual operation of your web browser.
                    <br> All names, logos and trademarks on this website are owned by FronteraSky or by a third party which has granted FronteraSky the right to use them on this website. The use or misuse of these names, logos and trademarks is strictly
                    prohibited by FronteraSky. Nothing contained herein shall be construed as granting the user of this website the right to use any names, logos and trademarks on this website without the written permission of the owner.<br> Copyright
                    © 2020 FronteraSky LLC (USA) and FronteraSky Aviation Services LLC (UAE) All Rights Reserved.
                    <br><br>
                    <b> Acceptance</b> <br> By using this website and its information, you irrevocably and unconditionally accept and agree to be bound by this legal disclaimer and copyright notice. FronteraSky reserves the right to change, add or remove
                    any information presented on this website without notice. Your continued use of this website following the posting of such changes means that you accept such changes.<br><br>
                    <b>Cancellation Policy.</b>
                    <br>If you cancel the flight you are liable for charges as per the following timetable:
                    <br><br> • More than 30 days: 10%<br> • From 30 days to 16 days: 25%<br> • From 15 days to 3 days: 50%<br> • From 72h to 48h: 75%<br> • Less than 48h/ no show: 100%<br>
                    <br> The Passengers shall arrive at the airport sufficiently in advance to be ready at the departure time of the Flight if the Passengers are not ready at such time for any reason (including late arrival missing Passengers missing
                    documents customs formalities) the Charterer will owe the Operator a demurrage fee and shall reimburse FronteraSky for any amounts invoiced by the Operator to FronteraSky as a result of such delay. In addition shall reimburse for the
                    new departure time (take off slot) and/or the new arrival time at destination (landing slot) which will be reallocated by the relevant airports air traffic control and neither FronteraSky nor the Operator shall be liable for any damage
                    loss or delay caused by such reallocation, which can extend to the following day or the next airport opening day. Furthermore, FronteraSky shall be entitled to treat such Passengers delay as constituting a cancellation, entitling FronteraSky
                    to payment in accordance with the next paragraph.
                    <br> In case the Charterer, or the Passengers, cancel the Flight(s) the cancellation charges, listed above (the “Cancellation Charges”) shall be due by the Charterer to FronteraSky. Such Cancellation Charges shall be calculated on
                    the Charter Price. In case of cancellation of the Flight(s), the Charterer shall in addition reimburse FronteraSky for all costs incurred by FronteraSky in connection with any Additional Services related to the Flight(s). In case the
                    Price had not yet been paid by the Charterer at the time of cancellation, the Charterer shall pay the applicable Cancellation Charge and all costs to FronteraSky within two (2) business days of cancellation. If the Price was already
                    paid by the Charterer, FronteraSky shall reimburse the Price to the Charterer upon deduction of the applicable Cancellation Charge and all costs referred to in the previous paragraph and any other amounts owed by the Charterer and/or
                    the Passengers to FronteraSky. No partial cancellation (in particular if this Contract concerns several Flights, no cancellation of a single Flight) shall be possible at any time save with FronteraSky prior written consent (which shall,
                    in particular, be conditioned upon the Operators consent). In case of a partial cancellation the full Price (and any other amounts due by the Charterer and/or the Passengers hereunder) shall remain due and payable and no part thereof
                    shall be reimbursed by FronteraSky. If payment has been made by pre-Authorization credit card payment all cancellation fees will be deducted accordingly from that pre-authorized payment.
                </p>
            </div>
        </div>
    </div>
</div>