import { Component, OnInit } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-booking-payment',
  templateUrl: './booking-payment.component.html',
  styleUrls: ['./booking-payment.component.sass']
})
export class BookingPaymentComponent implements OnInit {

  cliendId: any;
  passengers: any;
  departure: any;
  date: any;
  totalAmounth: any;
  carryOnBagage: any;
  checkedBagage: any;
  objToSend: any;
  seats: any;
  gouvernmet = false;
  public payPalConfig?: IPayPalConfig;
  constructor(private readonly router: Router, private readonly service: AppService) {
    this.cliendId = environment.paypalcliend;
  }

  async ngOnInit(): Promise<void> {
    const paymentdocument = JSON.parse(localStorage.getItem('BOOKYNGSYSTEM::paymentInfo'));
    const fligthId = paymentdocument.departureSchedule;
    const toSchedule = paymentdocument.returnSchedule;
    this.objToSend = paymentdocument;
    this.passengers = paymentdocument.passengers;
    this.seats = paymentdocument.seats;
    this.departure = paymentdocument.locationCombination;
    this.date = paymentdocument.fromDate;
    this.totalAmounth = paymentdocument.totalAmounth;
    this.carryOnBagage = paymentdocument.carryOnBagageFrom;
    this.checkedBagage = paymentdocument.checkedBagageFrom;
    const isGovernment: any = await this.service.isGovernment(fligthId);
    let isGovernmentReturn: any = false;
    if (toSchedule) {
      isGovernmentReturn = await this.service.isGovernment(toSchedule);
    }
    if (isGovernment.governmentContractorFlight || isGovernmentReturn.governmentContractorFlight) {
      this.gouvernmet = true;
      for (var j of this.passengers) {
        if (!j.nameOfEmployer || !j.POCPhone || !j.POCEmail || !j.POCName) {
          this.router.navigate([`/booking/`]);
          Swal.fire('Error', 'Government contractor flight, all fields is required, please try again', 'error');
          return
        }
      }
    }
    this.payPalConfig = {
      currency: 'USD',
      clientId: this.cliendId,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: this.totalAmounth,
                breakdown: {
                  item_total: {
                    currency_code: 'USD',
                    value: this.totalAmounth,
                  },
                },
              },
              items: [
                {
                  name: 'Frontera SKY Booking' ,
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'USD',
                    value: this.totalAmounth,
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onApprove: (data, actions) => {
        console.log(
          'onApprove - transaction was approved, but not authorized',
          data,
          actions
        );
        actions.order.get().then((details) => {
          console.log(
            'onApprove - you can get full order details inside onApprove: ',
            details
          );
          if (details.status === "APPROVED") {
            this.objToSend.paymentReference = details.id;
            this.objToSend.seats = this.seats;
            this.service.createBooking(this.objToSend).then(()=> {
              this.router.navigate([`/booking/`]);
              Swal.fire('Success', 'Your payment has been success', 'success');
            });
          }
        });
      },
      onClientAuthorization: (data) => {
        console.log(
          'onClientAuthorization - you should probably inform your server about completed transaction at this point',
          data
        );
        //this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        //this.showCancel = true;
      },
      onError: (err) => {
        console.log('OnError', err);
        //Swal.fire();
        //this.showError = true;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        //this.resetStatus();
      },
    };
  }

  formatSeats(seats: any) {
    const arraySeats = [];
    for (const i in seats) {
      const newObj: any = new Object();
      newObj.code = seats[i];
      arraySeats.push(newObj);
    }
    return arraySeats;
  }

}
