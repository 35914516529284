<section class="hero">
    <div class="container">
        <div class="title">
            <h1>
                <!-- We understand our customers and offer the service they deserve -->
                Book our preselected <br> destinations <br class="responsive-br"> and flights here.
            </h1>
            <br>
            <br>
            <br>
            <br>
            <div class="booking">
                <div class="fomss">
                    <h6>Select your travel experience</h6>
                    <form name="elemForm" class="form" [formGroup]="elemForm" (ngSubmit)="send()" autocomplete="off">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="oneway" id="oneway" value="onewway" (click)="changeType('oneWay')" checked>
                            <label class="form-check-label" for="oneway">
                                One Way
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="oneway" id="return" value="return" (click)="changeType('returnWay')">
                            <label class="form-check-label" for="return">
                                Round Trip
                            </label>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="fromLocationId">Departure airport</label>
                                <select class="form-control" id="fromLocationId" name="fromLocationId" formControlName="fromLocationId">
                                    <option disabled>Select a location</option>
                                    <option *ngFor="let elem of elements" value="{{elem._id}}">{{elem.code}} - airport {{elem.airportName}}</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="toLocationId">Arrival airport</label>
                                <select class="form-control" id="toLocationId" name="toLocationId" formControlName="toLocationId">
                                    <option disabled>Select a location</option>
                                    <option *ngFor="let elem of elements" value="{{elem._id}}">{{elem.code}} - airport {{elem.airportName}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="departureDate">Departure Date</label>
                                <input
                                [owlDateTime]="dt1"
                                [owlDateTimeTrigger]="dt1"
                                class="form-control"
                                placeholder="Select departure date"
                                formControlName="departureDate"
                                />
                                <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                                <!-- <input type="date" class="form-control" id="departureDate" name="departureDate" formControlName="departureDate" required> -->
                            </div>
                            <div class="form-group col-md-6" *ngIf="!typeOneWay">
                                <label for="returnDate">Return Date</label>
                                <input
                                [owlDateTime]="dt2"
                                [owlDateTimeTrigger]="dt2"
                                class="form-control"
                                placeholder="Select return date"
                                formControlName="returnDate"
                                />
                                <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                                <!-- <input type="date" class="form-control" id="returnDate" name="returnDate" formControlName="returnDate" typeOneWay> -->
                            </div>
                            <div class="form-row col-md-6" *ngIf="typeOneWay">
                                <div class="form-group col-md-4">
                                    <label for="adult">Adult</label>
                                    <input type="number" class="form-control" id="adult" name="adult" min="0" formControlName="adult" required>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="child">Child (2-12)</label>
                                    <input type="number" class="form-control" id="child" name="child" min="0" formControlName="child" typeOneWay>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="infant">Infant (&lt;2)</label>
                                    <input type="number" class="form-control" id="infant" name="infant" min="0" formControlName="infant" typeOneWay>
                                </div>
                            </div>
                        </div>
                        <div class="form-row" *ngIf="!typeOneWay">
                            <div class="form-group col-md-4">
                                <label for="adult">Adult</label>
                                <input type="number" class="form-control" id="adult" name="adult" min="0" formControlName="adult" required>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="child">Child (2-12)</label>
                                <input type="number" class="form-control" id="child" name="child" min="0" formControlName="child" typeOneWay>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="infant">Infant (&lt;2)</label>
                                <input type="number" class="form-control" id="infant" name="infant" min="0" formControlName="infant" typeOneWay>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 ml-auto">
                                <button type="submit" class="btn btn-success btn-sm btn-block" [disabled]="elemForm.invalid" [routerLink]="['/booking']" [fragment]="'travelDetails'">
                                  Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- -----------------------------------CAMBIO INICIO-------------------------------------------- -->
        <h6 class="texto">Our scheduled flights are provided by FronteraSky Aviation Services LLC (Dubai).</h6>
        <!-- -----------------------------------CAMBIO FIN (+ CSS)-------------------------------------------- -->
    </div>
</section>
<section class="container" id="travelDetails">
    <div class="row" *ngIf="showReturn">
        <div class="col-md-12">
            <ul class="nav nav-tabs">
                <li class="nav-item" (click)="changeView('Departure')">
                    <a class="nav-link" [ngClass]="selectedOneOption">Departure</a>
                </li>
                <li class="nav-item" (click)="changeView('Return')">
                    <a class="nav-link" [ngClass]="selectedReturnOption">Return</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="row mb-5">
        <div class="d-block col-md-12 col-sm-12" *ngIf="!showReturnContainer">
            <!-- Items -->
            <div class="row pt-4 pl-4 pr-2" *ngFor="let item of departureSchedules">
                <div class="d-block col-md-8 col-sm-12 fligth-item">
                    <div cass="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-10">
                                    <h4>{{item.fromLocation.code}} - {{item.toLocation.code}}</h4>
                                </div>
                                <div class="col-md-2">
                                    <button type="button" class="btn-select" (click)="selectFromSchedule(item)">
                                        Select
                                    </button>
                                </div>
                            </div>
                            <br>
                            <div class="row" *ngIf="showReturn">
                                <div class="col-md-12" *ngIf="selectedFromSchedule?._id == item._id">
                                    <button type="button" class="btn-select" (click)="changeView('Return')" [routerLink]="['/booking']" [fragment]="'travelDetails'">
                                        Select return flight
                                    </button>
                                </div>
                            </div>
                            <div class="row d-block d-md-none" *ngIf="selectedFromSchedule?._id == item._id">
                                <div class="col-sm-12">
                                    <div *ngIf="priceCalculate">
                                        Count seats: {{countSeats}} Total price: ${{priceCalculate}}
                                    </div>
                                    <div *ngIf="priceCalculate || priceCalculate==0">
                                        <button type="button" class="btn btn-success btn-sm btn-block" (click)="continue()">
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="container">
                                <h5>From:</h5>
                                <h5>{{item.fromLocation.airportName}}</h5>
                                <h6>{{item.date | date:'medium'}}</h6>
                                <p>Airplane: {{item.airplaneDoc.name}} - Duration {{minutesToHours(item.durationAprox)}}</p>
                                <h5>To:</h5>
                                <h5>{{item.toLocation.airportName}}</h5>
                                <h6>{{calculateHour(item.date, item.durationAprox) | date:'medium'}}</h6>
                            </div>
                            <hr>
                            <h4>Price economy ${{item.priceEconomy}} - Price business ${{item.priceBusiness}}</h4>
                        </div>
                    </div>
                </div>
                <div class="d-none d-md-block col-md-4" *ngIf="selectedFromSchedule?._id == item._id">
                    <div class="col-12 select-fligth">
                        <div class="container-details">
                            <div class="row" *ngIf="selectedFromSchedule">
                                <div class="col-md-12" style="margin-top: 20px;">
                                    <h4>Departure:</h4>
                                    <h5>{{selectedFromSchedule.date | date:'medium'}}</h5>
                                    <h5>{{selectedFromSchedule.fromLocation.code}} - {{selectedFromSchedule.toLocation.code}}</h5>
                                    <h5 *ngIf="seatsType == 'economy'">Price: ${{selectedFromSchedule.priceEconomy}}</h5>
                                    <h5 *ngIf="seatsType == 'business'">Price: ${{selectedFromSchedule.priceBusiness}}</h5>
                                </div>
                            </div>
                            <hr *ngIf="selectedToSchedule">
                            <div class="row" *ngIf="selectedToSchedule">
                                <div class="col-md-12">
                                    <h4>Return:</h4>
                                    <h5>{{selectedToSchedule.date | date:'medium'}}</h5>
                                    <h5>{{selectedToSchedule.fromLocation.code}} - {{selectedToSchedule.toLocation.code}}</h5>
                                    <h5 *ngIf="seatsType == 'economy'">Price: ${{selectedToSchedule.priceEconomy}}</h5>
                                    <h5 *ngIf="seatsType == 'business'">Price: ${{selectedToSchedule.priceBusiness}}</h5>
                                </div>
                            </div>
                            <hr>
                            <div *ngIf="priceCalculate">
                                Count seats: {{countSeats}} Total price: ${{priceCalculate}}
                            </div>
                            <div *ngIf="priceCalculate || priceCalculate==0">
                                <button type="button" class="btn btn-success btn-sm btn-block" (click)="continue()">
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-block col-md-12 col-sm-12" *ngIf="showReturnContainer">
            <!-- Items -->
            <div class="row pt-4 pl-4 pr-2" *ngFor="let item of returnSchedule">
                <div class="d-block col-md-8 col-sm-12 fligth-item">
                    <div cass="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-10">
                                    <h4>{{item.fromLocation.code}} - {{item.toLocation.code}}</h4>
                                </div>
                                <div class="col-md-2">
                                    <button type="button" class="btn-select" (click)="selectToSchedule(item)">
                                        Select
                                    </button>
                                </div>
                            </div>
                            <br>
                            <div class="row" *ngIf="showReturn">
                                <div class="col-md-12" *ngIf="selectedToSchedule?._id == item._id">
                                    <button type="button" class="btn-select" (click)="changeView('Departure')" [routerLink]="['/booking']" [fragment]="'travelDetails'">
                                        Select departure flight
                                    </button>
                                </div>
                            </div>
                            <div class="row d-block d-md-none" *ngIf="selectedToSchedule?._id == item._id">
                                <div class="col-sm-12">
                                    <div *ngIf="priceCalculate">
                                        Count seats: {{countSeats}} Total price: ${{priceCalculate}}
                                    </div>
                                    <div *ngIf="priceCalculate || priceCalculate==0">
                                        <button type="button" class="btn btn-success btn-sm btn-block" (click)="continue()">
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="container">
                                <h5>From:</h5>
                                <h5>{{item.fromLocation.airportName}}</h5>
                                <h6>{{item.date | date:'medium'}}</h6>
                                <p>Airplane: {{item.airplaneDoc.name}} - Duration {{minutesToHours(item.durationAprox)}}</p>
                                <h5>To:</h5>
                                <h5>{{item.toLocation.airportName}}</h5>
                                <h6>{{calculateHour(item.date, item.durationAprox) | date:'medium'}}</h6>
                            </div>
                            <hr>
                            <h4>Price economy ${{item.priceEconomy}} - Price business ${{item.priceBusiness}}</h4>
                        </div>
                    </div>
                </div>
                <div class="d-none d-md-block col-md-4" *ngIf="selectedToSchedule?._id == item._id">
                    <div class="col-12 select-fligth">
                        <div class="container-details">
                            <div class="row" *ngIf="selectedFromSchedule">
                                <div class="col-md-12" style="margin-top: 20px;">
                                    <h4>Departure:</h4>
                                    <h5>{{selectedFromSchedule.date | date:'medium'}}</h5>
                                    <h5>{{selectedFromSchedule.fromLocation.code}} - {{selectedFromSchedule.toLocation.code}}</h5>
                                    <h5 *ngIf="seatsType == 'economy'">Price: ${{selectedFromSchedule.priceEconomy}}</h5>
                                    <h5 *ngIf="seatsType == 'business'">Price: ${{selectedFromSchedule.priceBusiness}}</h5>
                                </div>
                            </div>
                            <hr *ngIf="selectedToSchedule">
                            <div class="row" *ngIf="selectedToSchedule">
                                <div class="col-md-12">
                                    <h4>Return:</h4>
                                    <h5>{{selectedToSchedule.date | date:'medium'}}</h5>
                                    <h5>{{selectedToSchedule.fromLocation.code}} - {{selectedToSchedule.toLocation.code}}</h5>
                                    <h5 *ngIf="seatsType == 'economy'">Price: ${{selectedToSchedule.priceEconomy}}</h5>
                                    <h5 *ngIf="seatsType == 'business'">Price: ${{selectedToSchedule.priceBusiness}}</h5>
                                </div>
                            </div>
                            <hr>
                            <div *ngIf="priceCalculate">
                                Count seats: {{countSeats}} Total price: ${{priceCalculate}}
                            </div>
                            <div *ngIf="priceCalculate || priceCalculate==0">
                                <button type="button" class="btn btn-success btn-sm btn-block" (click)="continue()">
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
