import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainRoutingModule } from './main-routing.module';
import { HomeComponent } from './home/home.component';
import { MainComponent } from './main.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [HomeComponent, MainComponent],
  imports: [CommonModule, MainRoutingModule, SharedModule],
  exports: [HomeComponent],
})
export class MainModule {}
