<div class="hero ">
    <img src="./assets/img/WhiteGloves/es_bodyguard.png" alt="">
    <div class="container">
        <h1>Bodyguard and <br> close protection services</h1>
    </div>
</div>

<div class="">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <p>
                    FronteraSky provides professional bodyguard services and close protection as a part of White Glove Service. We are ready to provide personal protection for our clients on a permanent or temporary basis. Among our clients are politicians, business leaders,
                    representatives of show business, and people who are simply concerned about their safety under the certain circumstances.charter flights can really reduce the risks of infection and minimize contact with people.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="popular">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <h4>Bodyguard</h4>
                <p>
                    A personal bodyguard is a specially trained professional, with practical combat experience or experience of working in the special units. The main outstanding quality of the close protection bodyguard is not only impeccable physical training, but also
                    the ability to quickly analyze the situation, the level of threat and to make the only right decision that can ensure the safety of the client specified for close protection. An experienced personal bodyguard is always on the alert,
                    constantly observing the conditions of security and assessing the sources of potential danger.
                </p>
                <p>
                    Executives, public figures and private entrepreneurs, who are concerned not only with their own safety, but also with the safety of their family members, resort to the type of security service, such as personal bodyguard and close protection. The presence
                    of security can be a matter of status and prestige as well as due to the solid reason of protecting the safety of family and children.
                </p>
                <p>
                    Professional bodyguards are able to provide close protection in all conditions and situations i.e. work ahead of schedule and prevent the possibility of a threat to the client in advance. Ensuring the personal security of our clients, depending on the
                    form of cooperation with FronteraSky, may take the form of a one-time service, or may be carried out on an ongoing basis.
                </p>
                <p>
                    FronteraSky has expanded the primary functions and the image of the personal bodyguard beyond the common standards. We are instantly upgrading and customizing the services in accordance with the demands of time, location, and society. Bodyguards, carefully
                    selected by our partner companies worldwide, do not only provide close protection in an extreme situation, but also guarantee your self-confidence and the safety of your beloved ones.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="">
    <div class="container pt-5 pb-5">
        <div class="row">

            <div class="col-12">
                <ul>
                    <li>
                        Close protection escort during the trip abroad. The possible risks for the client are closely investigated and analyzed by the professional bilingual bodyguard who accompanies the client during the trip in another country.
                    </li>
                    <li>
                        Bodyguard drivers are in great demand among our VIP clients. Close protection driver with the vast experience in security service as well as the armed vehicle can be provided as a part of our white glove services at the client’s request during the trip
                        with FronteraSky, meeting at the airport, particular event, etc.
                    </li>
                    <li>
                        Bilingual close protection for foreign clients. FronteraSky provides the services of bodyguards who speak foreign languages ​​at intermediate to advanced levels.
                    </li>
                    <li>
                        Child close protection. The responsibilities of the bodyguard include escorting the child during the trip, transfer to/from the airport or full schedule security services on request of the client.
                    </li>
                    <li>
                        Female bodyguards. Our dedicated team provides female bodyguard services in cases when additional attention is preferred to be decreased or in case of intensified security of your children.
                    </li>
                </ul>
            </div>

        </div>
    </div>
</div>