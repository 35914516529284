<nav class="navbar fixed-top navbar-expand-md" [ngClass]="{ navbarwhite: pageScrollChange === true }">
  <div class="col-6 col-xl-3 col-lg-3 col-md-3 offset-xl-1 offset-lg-1 offset-md-1">
    <a class="navbar-brand d-none d-md-block" [routerLink]="'/'">
      <img src="/assets/img/logo_navbar-inicio.png" alt="" *ngIf="!bookingImg" class="img-fluid" />
      <img src="/assets/img/logo_navbar-secciones.png" *ngIf="bookingImg" alt="" class="img-fluid" />
    </a>
    <a class="navbar-brand d-block d-md-none" [routerLink]="'/'">
      <img src="/assets/img/logo_navbar-secciones.png" class="img-fluid" />
    </a>
  </div>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
    aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon">
      <img src="./assets/img/menu.png" alt="" class="img-fluid" />
    </span>
  </button>
  <div class="collapse navbar-collapse justify-content-between" id="navbarTogglerDemo01">
    <div class="menu-parent">
      <div class="social justify-content-between d-xs-none d-sm-none d-md-block">
        <a href="https://www.linkedin.com/company/fronterasky/about/" target="_blank" rel="noopener noreferrer">
          <img src="/assets/img/ico_in-navbar.png" alt="" *ngIf="!pageScrollChange" />
          <img src="/assets/img/ico_in-navbar-2.png" alt="" *ngIf="pageScrollChange" />
        </a>
        <a href="https://www.facebook.com/FronteraSky/" target="_blank" rel="noopener noreferrer">
          <img src="/assets/img/ico_fb-navbar.png" alt="" *ngIf="!pageScrollChange" />
          <img src="/assets/img/ico_fb-navbar-2.png" alt="" *ngIf="pageScrollChange" />
        </a>
        <a href="https://www.youtube.com/channel/UCbzcY1ps2LsCoBLem_Ys7Sw/videos" target="_blank"
          rel="noopener noreferrer">
          <img src="/assets/img/ico_yt-navbar.png" alt="" *ngIf="!pageScrollChange" />
          <img src="/assets/img/ico_yt-navbar-2.png" alt="" *ngIf="pageScrollChange" />
        </a>
        <a href="https://www.instagram.com/fronterasky/" target="_blank" rel="noopener noreferrer">
          <img src="/assets/img/ico_ig-navbar.png" alt="" *ngIf="!pageScrollChange" />
          <img src="/assets/img/ico_ig-navbar-2.png" alt="" *ngIf="pageScrollChange" />
        </a>
      </div>
      <div class="menu">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item">
            <a class="nav-link" href="/">HOME </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="'/aboutus'">ABOUT US
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="'/services'">SERVICES</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="'/air-comparator'">AIRCRAFT COMPARATOR</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="'/pricing'">PRICING
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="'/booking'">SCHEDULED FLIGHTS
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="'/payment'">PAYMENT
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="'/contact'">CONTACT
            </a>
          </li>
          <li class="social-menu-mobile">
            <a href="https://www.linkedin.com/company/fronterasky/about/" target="_blank" rel="noopener noreferrer">
              <img src="/assets/img/ico_in-navbar-2.png" alt="" />
            </a>
            <a href="https://www.facebook.com/FronteraSky/" target="_blank" rel="noopener noreferrer">
              <img src="/assets/img/ico_fb-navbar-2.png" />
            </a>
            <a href="https://www.youtube.com/channel/UCbzcY1ps2LsCoBLem_Ys7Sw/videos" target="_blank"
              rel="noopener noreferrer">
              <img src="/assets/img/ico_yt-navbar-2.png" alt="" />
            </a>
            <a href="https://www.instagram.com/fronterasky/" target="_blank" rel="noopener noreferrer">
              <img src="/assets/img/ico_ig-navbar-2.png" alt="" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
