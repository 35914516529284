<section class="hero img-fluid">
  <div class="item1">
    <h1 class="offset-xl-1 offset-lg-1 offset-md-1">
      Journeys <br> that transform <br> the destination.
    </h1>
    <br>
    <h2 class="offset-xl-1 offset-lg-1 offset-md-1">
      The premier private <br> aviation experts.
    </h2>
  </div>
</section>

<section>
  <div class="container pt-5">
    <div class="row">
      <div class="col-xl-10 offset-xl-1">
        <h1 class="title-center">Flying soon? Request a quote below.</h1>
      </div>
    </div>
  </div>
  <div class="container">
    <div id="avinodeApp" class="avinode"></div>
  </div>
</section>

<section>
  <div #imageContainer class="container container-image-iframe" (click)="changeIframeVideo()">
    <i class="bi bi-play-btn-fill icon-image-container"></i>
    <img class="image-iframe-video" src="./assets/img/FS-thumb_video1.png" />
  </div>
  <div #videoContainer class="container video-responsive img-responsive">
    <iframe width="560" height="315" src="https://www.youtube.com/embed/B7q0r0OPA1s" title="YouTube video player"
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen>
    </iframe>
  </div>
</section>

<section class="info mt-5 mb-5">
  <div class="container">
    <div class="row">
      <div class="col-xl-5 col-lg-5  offset-lg-1 offset-xl-1  col-md-5 offset-md-1 sede">
        <br>
        <div class="text americas">
          <h5>AMERICAS</h5>
          <p class="g1">
            Our Americas HQ, in South Florida, coordinates our flights in the U.S. as well as Central and
            South America. Our trained consultants are well versed in the intricacies of those destinations
            and ready to support your needs in the local languages.
          </p>
        </div>
        <br>
        <div class="imagen">
          <picture>
            <source type="image/png" srcset="./assets/img/inicio_palmbeach.png">
            <source type="image/webp" srcset="./assets/img/Escritorio/Inicio/WebP/inicio_palmbeach.webp">
            <img src="/assets/img/Imágenes JPG 2/Escritorio/Inicio/inicio_palmbeach.jp2" alt="FronteraSky Image">
          </picture>
        </div>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 sede ">
        <br>
        <div class="text europe">
          <h5>EUROPE</h5>
          <p>
            Our team of travel consultants, based in Lisbon, Portugal, manage all travel requests across
            continental Europe and the United Kingdom.
          </p>
        </div>
        <br>
        <div class="imagen">
          <picture>
            <source type="image/png" srcset="./assets/img/inicio_lisbon.png">
            <source type="image/webp" srcset="./assets/img/Escritorio/Inicio/WebP/inicio_lisbon.webp">
            <img src="/assets/img/Imágenes JPG 2/Escritorio/Inicio/inicio_lisbon.jp2" alt="FronteraSky Image">
          </picture>
        </div>
      </div>
    </div>
    <div class="row padding-5">
      <div class="col-xl-5 offset-xl-1 col-lg-5  offset-lg-1  col-md-5 offset-md-1 sede">
        <br>
        <div class="text middle">
          <h5>MIDDLE EAST</h5>
          <p class="g1">
            Our Middle East operation is based in Dubai, United Arab Emirates. The team of experienced
            consultants can support your needs when travelling to or from any of the countries in the region
            including Turkey, Iraq, Egypt, Afghanistan, and many others.
          </p>
        </div>
        <br>
        <div class="imagen">
          <picture>
            <source type="image/png" srcset="./assets/img/inicio_dubai.png">
            <source type="image/webp" srcset="./assets/img/Escritorio/Inicio/WebP/inicio_dubai.webp">
            <img src="/assets/img/Imágenes JPG 2/Escritorio/Inicio/inicio_dubai.jp2" alt="FronteraSky Image">
          </picture>
        </div>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 sede">
        <br>
        <div class="text east">
          <h5>EAST ASIA</h5>
          <p>
            Conveniently located in Manila, the Philippines, our team covers all travel requests in East and
            Southeast Asia as well as Oceania.
          </p>
        </div>
        <br>
        <div class="imagen asia">
          <picture>
            <source type="image/png" srcset="./assets/img/inicio_manila.png">
            <source type="image/webp" srcset="./assets/img/Escritorio/Inicio/WebP/inicio_manila.webp">
            <img src="/assets/img/Imágenes JPG 2/Escritorio/Inicio/inicio_manila.jp2" alt="FronteraSky Image">
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="info2 mt-5 mb-5">
  <div class="container">
    <div class="row pt-5 align-items-center">
      <div class="col-xl-6 col-lg-6 col-md-6">
        <picture>
          <source type="image/png" srcset="./assets/img/inicio_noticia1.png">
          <source type="image/webp" srcset="./assets/img/Escritorio/Inicio/WebP/inicio_noticia1.webp">
          <img src="/assets/img/Imágenes JPG 2/Escritorio/Inicio/inicio_noticia1.jp2" alt="FronteraSky Image"
            class="img-fluid">
        </picture>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6">
        <h5>
          How flying on private aircraft reduces the dissemination of infectious diseases such as coronavirus
          covid-19
        </h5>
        <p>
          Flights on private jets minimize the spread of disease, as take-off and landings take place at the
          least busy airports. Travelers and tourists significantly reduce the number of contacts with
          potentially infected people, since they do not need to go through
          crowded checkpoints at airports, limited to special passport checks, and the planes themselves are
          much easier to disinfect after each flight.
        </p>
        <p>
          <a routerLink="/private-aircraft">Read More...</a>
        </p>
      </div>
    </div>
  </div>
</section>
<section class="info2 mt-5 mb-5">
  <div class="container">
    <div class="row pt-5 align-items-center">
      <div class="col-xl-6 col-lg-6 col-md-6">
        <picture>
          <source type="image/png" srcset="./assets/img/es-inicio_noticia2.png">
          <source type="image/webp" srcset="./assets/img/Escritorio/Inicio/WebP/es-inicio_noticia2.webp">
          <img src="/assets/img/Imágenes JPG 2/Escritorio/Inicio/es-inicio_noticia2.jp2" alt="FronteraSky Image"
            class="img-fluid">
        </picture>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6">
        <h5>
          Our white glove service
        </h5>
        <p>
          Whether requesting an aircraft for an intercontinental business trip with multiple destinations or a
          quaint, romantic weekend away, FronteraSky outclasses the competition in all aspects of customer
          service. Our private charter consultants will lead you
          through the entire charter booking process, attend to all your requests, anticipate future needs and
          offer personalized service.
        </p>
        <p>
          <a href="./assets/FronteraSky.pdf" target="_blank">Read More...</a>
        </p>
      </div>
    </div>
  </div>
</section>
