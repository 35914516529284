<div class="banner">
</div>
<div class="container p-5">
  <div class="row">
    <div class="col-md-12" *ngIf="arrayForms">
      <div class="card" *ngFor="let item of arrayForms; index as i">
        <div class="container">
          <h1 style="text-align: center; margin-top: 10px;">Passenger personal information {{i+1}}</h1>
          <form [name]="item['form'+(i+1)]" class="form" [formGroup]="item['form'+(i+1)]" (ngSubmit)="saveForm('form'+(i+1))" autocomplete="off">
            <div class="form-row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label [for]="'passport'+i">Passport</label>
                  <input type="text" class="form-control" [id]="'passport'+i" formControlName="passport" required>
                  <span *ngIf="!messageErrors[i].passport" class="badge badge-danger" style="font-size: medium;">
                    The passport is required
                  </span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label [for]="'firstName'+i">First name</label>
                  <input type="text" class="form-control" [id]="'firstName'+i" formControlName="firstName" required>
                  <span *ngIf="!messageErrors[i].firstName" class="badge badge-danger" style="font-size: medium;">
                    The name is required
                  </span>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label [for]="'lastName'+i">Last name</label>
                  <input type="text" class="form-control" [id]="'lastName'+i" formControlName="lastName" required>
                  <span *ngIf="!messageErrors[i].lastName" class="badge badge-danger" style="font-size: medium;">
                    The last name is required
                  </span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label [for]="'mobile'+i">Mobile phone</label>
                  <input type="text" class="form-control" [id]="'mobile'+i" formControlName="mobile" required>
                  <span *ngIf="!messageErrors[i].mobile" class="badge badge-danger" style="font-size: medium;">
                    The mobile phone is required
                  </span>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label [for]="'email'+i">Email</label>
                  <input type="text" class="form-control" [id]="'email'+i" formControlName="email" required>
                  <span *ngIf="!messageErrors[i].email" class="badge badge-danger" style="font-size: medium;">
                    You need a valid email
                  </span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label [for]="'other'+i">Other</label>
                  <input type="text" class="form-control" [id]="'other'+i" formControlName="other">
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label [for]="'emergencyName'+i">Emergency contact name</label>
                  <input type="text" class="form-control" [id]="'emergencyName'+i" formControlName="emergencyName" required>
                  <span *ngIf="!messageErrors[i].emergencyName" class="badge badge-danger" style="font-size: medium;">
                    The emergency contact name is required
                  </span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label [for]="'emergencyPhone'+i">Emergency contact phone</label>
                  <input type="text" class="form-control" [id]="'emergencyPhone'+i" formControlName="emergencyPhone" required>
                  <span *ngIf="!messageErrors[i].emergencyPhone" class="badge badge-danger" style="font-size: medium;">
                    The emergency contact phone is required
                  </span>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label [for]="'dateOfBirth'+i">Date of birth</label>
                  <input type="date" class="form-control" [id]="'dateOfBirth'+i" formControlName="dateOfBirth" required>
                  <span *ngIf="!messageErrors[i].dateOfBirth" class="badge badge-danger" style="font-size: medium;">
                    You need a valid date of birth
                  </span>
                </div>
              </div>
              <div class="col-sm-6">
                <label for="nationalityId">Nationality</label>
                <select class="form-control" id="nationalityId" name="nationalityId" formControlName="nationalityId">
                  <option>Select a country</option>
                  <option *ngFor="let elem of nationalityList" value="{{elem._id}}">{{elem.code}} - {{elem.name}}</option>
                </select>
                <span *ngIf="!messageErrors[i].nationalityId" class="badge badge-danger" style="font-size: medium;">
                  You need a valid nationality</span>
              </div>
            </div>
            <hr class="divider-color">
            <!-- inicio de los nuevos campos -->
            <div class="form-row" *ngIf="governmentContractorFlight">
              <div class="col-sm-6">
                <div class="form-group">
                  <label [for]="'nameOfEmployer'+i">Name of employer</label>
                  <input type="text" class="form-control" [id]="'nameOfEmployer'+i" formControlName="nameOfEmployer" required>
                  <span *ngIf="!messageErrors[i].nameOfEmployer" class="badge badge-danger" style="font-size: medium;">
                    The name of employer is required
                  </span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label [for]="'POCPhone'+i">Employer point of contact phone</label>
                  <input type="text" class="form-control" [id]="'POCPhone'+i" formControlName="POCPhone" required>
                  <span *ngIf="!messageErrors[i].POCPhone" class="badge badge-danger" style="font-size: medium;">
                    The employer point of contact phone is required
                  </span>
                </div>
              </div>
            </div>
            <div class="form-row" *ngIf="governmentContractorFlight">
              <div class="col-sm-6">
                <div class="form-group">
                  <label [for]="'POCEmail'+i">Employer point of contact email</label>
                  <input type="email" class="form-control" [id]="'POCEmail'+i" formControlName="POCEmail" required>
                  <span *ngIf="!messageErrors[i].POCEmail" class="badge badge-danger" style="font-size: medium;">
                    The employer point of contact email is required
                  </span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label [for]="'POCName'+i">Employer point of contact name</label>
                  <input type="text" class="form-control" [id]="'POCName'+i" formControlName="POCName" required>
                  <span *ngIf="!messageErrors[i].POCName" class="badge badge-danger" style="font-size: medium;">
                    The employer point of contact name is required
                  </span>
                </div>
              </div>
            </div>
            <!-- fin de los nuevos campos -->
            <div class="form-row">
              <div class="col">
              </div>
              <div class="col-md" *ngIf="!selectedArray['form'+(i+1)]">
                <div class="row">
                  <div class="col-6">
                    <button type="reset" class="btn btn-sm btn-block">Reset</button>
                  </div>
                  <div class="col-6">
                    <!-- <button type="submit" class="btn btn-success btn-sm btn-block" [disabled]="item['form'+(i+1)].invalid">
                      Save
                    </button> -->
                    <button type="submit" class="btn btn-success btn-sm btn-block">
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-12" *ngIf="sendFormValidate">
                <button type="button" class="btn btn-info btn-blue" (click)="saveTheForm()">Select seats</button>
              </div>
              <div class="col" *ngIf="selectedArray['form'+(i+1)] && !sendFormValidate">
                <button type="button" class="btn btn-success btn-sm btn-block btn-red">
                  Please complete all forms
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>