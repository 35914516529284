<div class="banner">
</div>
<div class="container p-5">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h1 class="text-center">Booking Summary</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div style="margin: 0 auto;">
                <div class="row">
                  <div class="col-sm">
                    <h6>Fligth</h6>
                    <hr>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm">

                    <table class="table table-bordered desktop ">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Departure</th>
                          <th scope="col">Date</th>
                          <th scope="col">Total amounth</th>
                          <th scope="col">Carry on bagage</th>
                          <th scope="col">Checked bagage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="align-middle">{{departure}}</td>
                          <td class="align-middle">{{date | date:'medium'}}</td>
                          <td class="align-middle">${{totalAmounth}}</td>
                          <td class="align-middle">{{carryOnBagage}}</td>
                          <td class="align-middle">{{checkedBagage}}</td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="table table-bordered mobile">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Departure</th>
                          <th colspan="2" scope="col">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="align-middle">{{departure}}</td>
                          <td colspan="2" class="align-middle">{{date | date:'medium'}}</td>
                        </tr>
                      </tbody>
                      <thead class="thead-dark" style="width: 100%;">
                        <tr>
                          <th scope="col">Total amounth</th>
                          <th scope="col">Carry on bagage</th>
                          <th scope="col">Checked bagage</th>
                        </tr>
                      </thead>
                      <tbody style="width: 100%;">
                        <tr>
                          <td class="align-middle">${{totalAmounth}}</td>
                          <td class="align-middle">{{carryOnBagage}}</td>
                          <td class="align-middle">{{checkedBagage}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div style="margin: 0 auto;">
                <div class="row">
                  <div class="col-sm">
                    <h6>Passengers</h6>
                    <hr>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm">
                    <table class="table table-bordered desktop">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col" *ngIf="gouvernmet">Name of employer</th>
                          <th scope="col" *ngIf="gouvernmet">POC phone</th>
                          <th scope="col" *ngIf="gouvernmet">POC email</th>
                          <th scope="col" *ngIf="gouvernmet">POC name</th>
                          <th scope="col">Passenger name</th>
                          <th scope="col">Passport</th>
                          <th scope="col">Email</th>
                          <th scope="col">Mobile</th>
                          <th scope="col">Seat</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="passengers">
                        <tr *ngFor="let item of passengers; index as i">
                          <td class="align-middle" *ngIf="gouvernmet">{{item.nameOfEmployer}}</td>
                          <td class="align-middle" *ngIf="gouvernmet">{{item.POCPhone}}</td>
                          <td class="align-middle" *ngIf="gouvernmet">{{item.POCEmail}}</td>
                          <td class="align-middle" *ngIf="gouvernmet">{{item.POCName}}</td>
                          <td class="align-middle">{{item.firstName}} {{item.lastName}}</td>
                          <td class="align-middle">{{item.passport}}</td>
                          <td class="align-middle">{{item.email}}</td>
                          <td class="align-middle">{{item.mobile}}</td>
                          <td class="align-middle">{{seats[i].code}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div *ngIf="passengers">
                      <table class="table table-bordered mobile" *ngFor="let item of passengers; index as i">
                        <thead class="thead-dark">
                          <tr>
                            <th scope="col" *ngIf="gouvernmet">Name of employer</th>
                            <th scope="col" *ngIf="gouvernmet">POC phone</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="align-middle" *ngIf="gouvernmet">{{item.nameOfEmployer}}</td>
                            <td class="align-middle" *ngIf="gouvernmet">{{item.POCPhone}}</td>
                          </tr>
                        </tbody>
                        <thead>
                          <tr>
                            <th scope="col" *ngIf="gouvernmet">POC email</th>
                            <th scope="col" *ngIf="gouvernmet">POC name</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="align-middle" *ngIf="gouvernmet">{{item.POCEmail}}</td>
                            <td class="align-middle" *ngIf="gouvernmet">{{item.POCName}}</td>
                          </tr>
                        </tbody>
                        <thead class="thead-dark">
                          <tr>
                            <th colspan="2" scope="col">Passenger name</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="2" class="align-middle">{{item.firstName}} {{item.lastName}}</td>
                          </tr>
                        </tbody>
                        <thead class="thead-dark">
                          <tr>
                            <th colspan="2" scope="col">Passport</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="2" class="align-middle">{{item.passport}}</td>
                          </tr>
                        </tbody>
                        <thead class="thead-dark">
                          <tr>
                            <th colspan="2" scope="col">Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="2" class="align-middle">{{item.email}}</td>
                          </tr>
                        </tbody>
                        <thead class="thead-dark">
                          <tr>
                            <th scope="col">Mobile</th>
                            <th scope="col">Seat</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="align-middle">{{item.mobile}}</td>
                            <td class="align-middle">{{seats[i].code}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
            </div>
            <div class="col-md-4">
              <ngx-paypal [config]="payPalConfig"></ngx-paypal>
            </div>
            <div class="col-md-4">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>