import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.sass'],
})
export class NavComponent implements OnInit {
  pageScrollChange = false;
  bookingImg = false;

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    switch (this.route.url) {
      case '/':
          if (window.pageYOffset > 10) {
            this.pageScrollChange = true;
            this.bookingImg = true;
          } else {
            this.pageScrollChange = false;
            this.bookingImg = false;
          }
          break;
    default:
      this.bookingImg = true;
      this.pageScrollChange = true;
      break;
    }
  }
  constructor(private route: Router) {
      route.events.subscribe((val) => {
        // see also
        this.renderNav(val)
    });
   }


  ngOnInit(): void {  }

  renderNav(route):void {
    switch (route.url) {
      case '/':
        this.bookingImg = false;
        this.pageScrollChange = false;
        break;
      default:
        this.bookingImg = true;
        this.pageScrollChange = true;
        break;
    }
  }

  async goHome() {
    await this.route.navigateByUrl('/', { skipLocationChange: true });
    this.route.navigateByUrl('/');
  }

}
