import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ServicesComponent } from './services/services.component';
import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { PricingComponent } from './pricing/pricing.component';
import { AirComparatorComponent } from './air-comparator/air-comparator.component';
import { ContactComponent } from './contact/contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MedicalTourismComponent } from './medical-tourism/medical-tourism.component';
import { HelicopterReservationComponent } from './helicopter-reservation/helicopter-reservation.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { WhiteGlobeServicesComponent } from './white-globe-services/white-globe-services.component';
import { PrivateAircraftComponent } from './private-aircraft/private-aircraft.component';
import { BookingComponent } from './booking/booking.component';
import { BookingDetailsComponent } from './booking-details/booking-details.component';
import { BookingChairSelectionComponent } from './booking-chair-selection/booking-chair-selection.component';
import { BookingPaymentComponent } from './booking-payment/booking-payment.component';
import { BodyguardComponent } from './bodyguard/bodyguard.component';
import { ExclusiveCateringComponent } from './exclusive-catering/exclusive-catering.component';
import { YachtReservationComponent } from './yacht-reservation/yacht-reservation.component';
import { VipAirportComponent } from './vip-airport/vip-airport.component';
import { PaymentComponent } from './payment/payment.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsUseComponent } from './terms-use/terms-use.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';
import { PalmbeachComponent } from './palmbeach/palmbeach.component';

@NgModule({
  declarations: [
    PagesComponent,
    PricingComponent,
    AboutusComponent,
    ServicesComponent,
    AirComparatorComponent,
    ContactComponent,
    MedicalTourismComponent,
    HelicopterReservationComponent,
    WhiteGlobeServicesComponent,
    PrivateAircraftComponent,
    BookingComponent,
    BookingDetailsComponent,
    BookingChairSelectionComponent,
    BookingPaymentComponent,
    BodyguardComponent,
    ExclusiveCateringComponent,
    YachtReservationComponent,
    VipAirportComponent,
    PaymentComponent,
    PrivacyPolicyComponent,
    TermsUseComponent,
    CookiesPolicyComponent,
    PalmbeachComponent
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    ReactiveFormsModule,
    NgxPayPalModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule
  ],
})
export class PagesModule {}
