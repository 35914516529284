<section class="hero">
    <picture>
        <source type="image/png" srcset="/assets/img/services_hero-banner.png">
        <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Services/services_hero-banner.jp2">
        <img src="./assets/img/Escritorio/Services/WebP/services_hero-banner.webp" alt="a butterfly"  class="img-fluid">
    </picture>
</section>
<section class="container title">
    <div class="row">
        <div class="col-xl-9 col-lg-10 col-md-11 col-sm-12 col-12 frase">
            <h1>
                From trips to challenging environments, to unforgettable journeys with family and friends,
            <br/> we have you covered.
            </h1>
        </div>
    </div>
</section>
<section class="container lines">
    <div class="line"></div>
</section>
<section class="charters">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Charters</h1>
            </div>
        </div>
        <div class="items">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 order-1 order-lg-0">
                    <h2>
                        Private
                    </h2>
                    <p>
                        While a lot of commercial flights are fully booked or completely suspended with the spread of
                        the new coronavirus COVID-19, travel restrictions rarely apply to private charters. We are still
                        flying for you! FronteraSky can book private jets for you and
                        your family at a convenient time and take you to any destination on the same day. We provide an
                        exclusive atmosphere onboard, full range of customized services during the flight, and enhanced
                        privacy; only you and the crew.
                        <br /><br />The main advantage of a private flight, in addition to obvious accessibility and
                        convenience, is minimal contact with people. The business aviation terminals, located separately
                        from the main halls of the airports,
                        accelerate and simplify security and immigration procedures. Also, intensive disinfection of the
                        aircraft, can be easily performed due to the limited number of the passengers. We can arrange an
                        executive vehicle or limousine that
                        can take you right to the aircraft in order to minimize contact with other people.
                    </p>
                </div>
                <div class="col-6 col-lg-6 col-md-12 col-sm-12 col-12 charter-image order-0 order-lg-1">
                    <picture>
                        <source type="image/png" srcset="/assets/img/services_private.png">
                        <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Services/services_private.jp2">
                        <img src="/assets/img/Escritorio/Services/WebP/services_private.webp" alt="a butterfly" class="img-fluid ">
                    </picture>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 order-1 order-lg-0 TexFix">
                    <h2>
                        Challenging Environments
                    </h2>
                    <p>
                        Some of our clients operate in challenging or difficult environments. Whether you are flying to
                        or from Iraq, Colombia, Afghanistan, or similar destinations, FronteraSky is an expert in
                        coordinating and conducting successful aerial operations in such
                        locations. Our team is versed in diverse international and local regulations and will be able to
                        locate the right aircraft for the mission as well as finding security, logistics, and
                        immigration support.
                    </p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 charter-image order-0 order-lg-1">
                    <picture>
                        <source type="image/png" srcset="/assets/img/services_challenging-environments.png">
                        <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Services/es-services_challenging-environments.jp2">
                        <img src="/assets/img/Escritorio/Services/WebP/es-services_challenging-environments.webp" alt="a butterfly" class="img-fluid">
                    </picture>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 order-1 order-lg-0">
                    <h2>
                        Group
                    </h2>
                    <p>
                        Allow your guests to make individual flights to different parts of the globe. Save time and
                        money by deciding on your destination and departure time. Our experienced team is available 24/7
                        to book group charter flights and can help you with comfortable
                        transfers from the airport of your destination. FronteraSky is ready to provide you with
                        competent assistance in organizing charter flights of any category: business, private, VIP, etc.
                        <br /><br />For small
                        groups, we can offer customized solutions. For participation in international conferences with
                        hundreds of participants, our team can arrange several simultaneous flights or charter
                        large-capacity aircraft. The main advantage of
                        a group charter is that all passengers arrive at their destination at once and can immediately
                        begin quick and easy immigration procedures. Convenience and timing benefits are complemented by
                        a significant price advantage that
                        is applicable even for smaller group charters. Individual group charter flight fares are often
                        more affordable than tickets purchased with traditional airlines. In addition, the corporate
                        identity of the entire aircraft can be
                        customized as per the client’s instructions with corporate brochures or other informational or
                        promotional items. This tremendously increases brand awareness during the journey.
                    </p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 charter-image order-0 order-lg-1">
                    <picture>
                        <source type="image/png" srcset="/assets/img/services_group.png">
                        <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Services/services_group.jp2">
                        <img src="/assets/img/Escritorio/Services/WebP/services_group.webp" alt="a butterfly" class="img-fluid imgFix">
                    </picture>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 order-1 order-lg-0">
                    <h2>
                        Cargo / Air freight charter / Delivery of vital goods and equipment
                    </h2>
                    <p>
                        International and domestic expedited transport and freight cargo flights require quick response
                        and solid experience. The specialists of our cargo transportation department team will provide
                        you with competent assistance in all matters related to the
                        movement of goods by air. Our goal is to ensure an uninterrupted process in which the highest
                        level of service is combined with the efficient use of all resources. We will compare and check
                        all possible flights for you to ensure
                        the best solution for your requirements. The determining factors for your cargo operation
                        include the size, weight, number of packages, their contents, and flight distance.
                        <br /><br /> FronteraSky can support private, governmental, and non-governmental organizations
                        and volunteers on humanitarian missions and offers solutions for delivering vital equipment and
                        supplies to areas affected by military
                        operations, epidemics, or various natural disasters. Vital medical supplies and relief supplies
                        such as food, water, tents, blankets, power generators and much more can be delivered via
                        private charter flights in no time.
                    </p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 charter-image order-0 order-lg-1">
                    <picture>
                        <source type="image/png" srcset="/assets/img/services_cargo.png">
                        <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Services/services_cargo.jp2">
                        <img src="/assets/img/Escritorio/Services/WebP/services_cargo.webp" alt="a butterfly" class="img-fluid">
                    </picture>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="container lines">
    <div class="line"></div>
</section>
<section class="others">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Other services</h1>
            </div>
        </div>
        <div class="items">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 order-1 order-lg-0">
                    <!-- <a routerLink="/white-globe-services"> -->
                    <a href="./assets/FronteraSky.pdf" target="_blank">
                        <h2>
                            White-glove services
                        </h2>
                    </a>
                    <p>
                        FronteraSky provides chauffeured transfer services at departure and destination points or any
                        other locations specified by our VIP client. Access a comprehensive suite of services including
                        premium charter, concierge, exclusive in-flight catering, personal
                        butler, and ground transportation. Transportation is carried out by luxury vehicles, including
                        limousines, retro cars and rare vehicles. The outstanding services provided by our partners all
                        over the globe include: VIP and VVIP
                        airport meet and greet, cross-border chauffer services, instant access to the most exclusive
                        local events, yacht and helicopter reservation, bodyguard and security services, close
                        protection, provision of armored vehicles, executive
                        assistants, private guides, and care/transfer services of exotic pets.
                    </p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 other-image order-0 order-lg-1">
                    <picture>
                        <source type="image/png" srcset="/assets/img/services_white-gloves.png">
                        <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Services/services_white-gloves.jp2">
                        <img src="/assets/img/Escritorio/Services/WebP/services_white-gloves.webp" alt="a butterfly" class="img-fluid">
                    </picture>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 order-1 order-lg-0">
                    <h2>
                        Medical Evacuation
                    </h2>
                    <p>
                        We offer 24-hour service of medical evacuation and transportation for patients, their families,
                        health care providers and private insurance companies. Our experienced team is ready to assist
                        at any time. <br />Medical aviation
                        can be divided into two types - medical evacuation and medical transportation. In the first
                        case, people have to be saved from disaster zones (natural or social disasters, places of
                        avalanches, fire, etc.) Patients are typically
                        evacuated on aircraft such as Lear 35 A, Lear 55, Lear 60, Challenger 604, etc. <br />The second
                        type of medical aviation services is the transportation of the patients to another country (or
                        within the country) with the assistance
                        of foreign or local specialists. FronteraSky offers an immediate access to the network of our
                        certified operators providing specialized medical transportation and ambulance services
                        worldwide. In each specific situation, we select
                        the most suitable medical evacuation aircraft and provide additional evacuation services,
                        including:
                    </p>
                    <ul style="padding-left: 15px;">
                        <li>Immediate 24/7 response</li>
                        <li>Ambulance Charter</li>
                        <li>
                            A full range of “bed to bed” medical transportation services worldwide
                        </li>
                        <li>
                            Helicopters and aircraft with the most advanced medical equipment on board.
                        </li>
                        <li>
                            Access to teams of specialized doctors, certified nurses, pediatricians, and paramedics.
                        </li>
                    </ul>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 other-image order-0 order-lg-1">
                    <picture>
                        <source type="image/png" srcset="/assets/img/services_medevac.png">
                        <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Services/es-services_medevac.jp2">
                        <img src="/assets/img/Escritorio/Services/WebP/es-services_medevac.webp" alt="a butterfly" class="img-fluid">
                    </picture>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 order-1 order-lg-0">
                    <h2>
                        Repatriation Services
                    </h2>
                    <p>
                        How to fly back home when the borders are still sealed? Our team provides emergency response
                        solutions for individuals, governmental, non-governmental and humanitarian organizations. We
                        offer urgent booking of the aircraft for the repatriation and evacuation
                        of civilians at risk, as well as the victims of natural and man-made disasters or passengers in
                        need of urgent relocation.
                    </p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 other-image order-0 order-lg-1">
                    <picture>
                        <source type="image/png" srcset="/assets/img/services_repatriation.png">
                        <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Services/services_repatriation.jp2">
                        <img src="/assets/img/Escritorio/Services/WebP/services_repatriation.webp" alt="a butterfly" class="img-fluid">
                    </picture>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 order-1 order-lg-0">
                    <h2>
                        Medical Tourism Charter
                    </h2>
                    <!-- <p>
                        Tours have turned into a separate full-fledged industry with various types of services, destinations, and associations and international industry exhibitions. Medical Tourism Industry didn’t emerge at once. The great demand for foreign medical services
                        in some countries is due to the lack of highly qualified specialists in others, the lack of equipment and specialists for the treatment of complex and rare forms of oncological, neurological and some other diseases.
                    </p> -->
                    <p>
                        Medical Tourism Charters have evolved into an independent, full-fledged industry with various
                        types of services, destinations, associations, and international industry exhibitions. Medical
                        Tourism emerged for very specific reasons. The great demand for foreign medical services in some
                        countries is due to the lack of highly qualified specialists in others, or because of the
                        outrageously high costs of some types of treatments, such as cosmetic surgery, in many.
                    </p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 other-image order-0 order-lg-1">
                    <picture>
                        <source type="image/png" srcset="/assets/img/es-services_medical-tourism.png">
                        <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Services/es-services_medical-tourism.jp2">
                        <img src="/assets/img/Escritorio/Services/WebP/es-services_medical-tourism.webp" alt="a butterfly" class="img-fluid">
                    </picture>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 order-1 order-lg-0">
                    <h2>
                        Helicopters
                    </h2>
                    <p>
                        Our offering is not limited to fixed wing aircraft. FronteraSky also offers executive helicopter
                        service in many cities around the world for those passengers that need to travel between cities
                        or within the city.
                    </p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 other-image order-0 order-lg-1">
                    <picture>
                        <source type="image/png" srcset="/assets/img/es-services_helicopter.png">
                        <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Services/es-services_helicopter.jp2">
                        <img src="/assets/img/Escritorio/Services/WebP/es-services_helicopter.webp" alt="a butterfly" class="img-fluid">
                    </picture>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 order-1 order-lg-0">
                    <h2>
                        Butler and Shopping Assistant
                    </h2>
                    <p>
                        The service of the shopping assistant is in demand today more than ever. The FronteraSky team is
                        happy to offer the services of certified shopping assistants and butlers according to your
                        personal requests, as part of FronteraSky’s White Glove Service
                        Program. Clients who wish to create a wardrobe in accordance with the latest trends at their
                        destination or hire a highly qualified professional butler for organizing, planning and full
                        management of private jet reservation, yacht,
                        luggage, chauffeur service, and all aspects of the trip, including time management, can book
                        this service by <b>contacting FronteraSky’s White Glove Service consultants.</b>
                    </p>
                    <p>
                        **The services of a personal butler are available on request in case of roundtrip booking with
                        FronteraSky and six days advance notice minimum.
                    </p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 other-image order-0 order-lg-1">
                    <img src="/assets/img/es-services_butler.png" alt="" class="img-fluid" />
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 order-1 order-lg-0">
                    <h2>
                        Luxury Accommodation Reservation
                    </h2>
                    <p>
                        FronteraSky's White Glove Service Program provides our VIP and VVIP clients with instant access
                        to a luxurious lifestyle that follows the most demanding requirements. We aim to meet your exact
                        expectations of a unique journey, starting from booking the
                        private jet for you, to bringing your dream escapade to life. Booking a villa by the sea, a
                        penthouse to enjoy the dynamic skyline, or an exclusive chalet in your favorite ski resort is
                        our specialty! The butler and chef provided
                        by FronteraSky's White Glove Platinum Program may create an additional chic atmosphere at home
                        away from home at your request.
                    </p>
                    <p>
                        All you need is to decide on your destination and contact one of our private charter
                        consultants, and FronteraSky will arrange every element of your exclusive itinerary, ensuring
                        that the most memorable experiences of your vacation or business trip go
                        flawlessly!
                    </p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 other-image order-0 order-lg-1">
                    <img src="/assets/img/es-services_luxury-accommodation.png" alt="" class="img-fluid" />
                </div>
            </div>
        </div>
    </div>
</section>