<div class="hero">
</div>

<div class="container mt-5 mb-5 info">
    <div class="row">
        <div class="col-xl-6">
            <h1>Feel free to contact our charter consultants</h1>
        </div>
    </div>
    <div class="row pt-5 contCart">
        <div class="contItem">
            <div class="item">
                <div class="card">
                    <div class="card-body">
                        <h6>
                            AMERICAS
                        </h6>
                        <p class="card-text">
                            <br /> <a href="tel:+15619029035">
                                +1 561 902 9035
                            </a><br />
                            <a href="mailto:info@fronterasky.com">Contact Us </a>
                        </p>
                    </div>
                    <div class="image">
                        <picture>
                            <source type="image/png" srcset="./assets/img/contact_palmbeach.png">
                            <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Contact/contact_palmbeach.jp2">
                            <img src="./assets/img/Escritorio/Contact/WebP/contact_palmbeach.webp" alt="FronteraSky Image">
                        </picture>
                    </div>
                </div>
            </div>
        </div>
        <div class="contItem">
          <div class="item">
              <div class="card">
                  <div class="card-body">
                      <h6>
                          MIDDLE EAST
                      </h6>
                      <p class="card-text">
                          <br /> <a href="tel:+971044253385">+971 04 425 3385</a> <br />
                          <a href="mailto:info@fronterasky.com">Contact Us</a>
                      </p>
                  </div>
                  <div class="image">
                      <picture>
                          <source type="image/png" srcset="./assets/img/contact_dubai.png">
                          <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Contact/contact_dubai.jp2">
                          <img src="./assets/img/Escritorio/Contact/WebP/contact_dubai.webp" alt="FronteraSky Image">
                      </picture>
                  </div>
              </div>
          </div>
      </div>
        <div class="contItem">
            <div class="item">
                <div class="card">
                    <div class="card-body">
                        <h6>
                          EUROPE AND U.K.
                        </h6>
                        <p class="card-text">
                            <br/> <a href="tel:+15619029040">+1 561 902 9040</a> <br />
                            <a href="mailto:info@fronterasky.com">Contact Us</a>
                        </p>
                    </div>
                    <div class="image">
                        <picture>
                            <source type="image/png" srcset="./assets/img/contact_lisbon.png">
                            <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Contact/contact_lisbon.jp2">
                            <img src="./assets/img/Escritorio/Contact/WebP/contact_lisbon.webp" alt="FronteraSky Image">
                        </picture>
                    </div>
                </div>
            </div>
        </div>
        <div class="contItem">
            <div class="item">
                <div class="card">
                    <div class="card-body">
                        <h6>
                            EAST ASIA
                        </h6>
                        <p class="card-text">
                            <br /> <a href="tel:+63282713040">+63 28 271 3040</a><br />
                            <a href="mailto:info@fronterasky.com">Contact Us</a>
                        </p>
                    </div>
                    <div class="image">
                        <picture>
                            <source type="image/png" srcset="./assets/img/contact_manila.png">
                            <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Contact/contact_manila.jp2">
                            <img src="./assets/img/Escritorio/Contact/WebP/contact_manila.webp" alt="FronteraSky Image">
                        </picture>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container mt-5 mb-5">
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12">
            <h1>Request a quote</h1>
            <p>
                Please complete the following fields to let us know more about your flight. A FronteraSky charter
                consultant will contact you shortly to discuss the final details.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="container">
            <form [formGroup]="contactFrom" (ngSubmit)="sendData()">
                <div class="col-xl-6 col-lg-6 col-md-6 verticalAlign">
                    <select class="custom-select custom-select-sm mb-3" formControlName="title">
                        <option value="null" disable selected>Title</option>
                        <option value="Mr">Mr.</option>
                        <option value="Mrs">Mrs.</option>
                        <option value="Ms">Ms.</option>
                        <option value="Dr">Dr.</option>
                        <option value="Prof">Prof.</option>
                    </select>
                    <input type="text" formControlName="fistName" placeholder="First Name" class="mb-3" />
                    <input type="text" formControlName="lastName" placeholder="Last Name" class="mb-3" />
                    <input type="email" formControlName="email" placeholder="Email" class="mb-3" />
                    <input type="text" formControlName="phone" placeholder="Phone number" class="mb-3" />
                    <select class="custom-select custom-select-sm mb-3" formControlName="office">
                        <option value="" disable selected>Location</option>
                        <option value="Americas">Americas</option>
                        <option value="Europe">Europe</option>
                        <option value="Middle East">Middle East</option>
                        <option value="East Asia">East Asia</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 verticalAlign">
                    <div class="contactAsap">
                        <label class="mb-3">Do you want to be contacted ASAP?</label>
                        <div class="form-check form-check-inline ">
                            <input class="form-check-input" type="radio" name="asap" formControlName="asap"
                                id="inlineRadio1" value="Yes" />
                            <label class="form-check-label" for="inlineRadio1">Yes</label>
                        </div>
                        <div class="form-check form-check-inline ">
                            <input class="form-check-input" type="radio" name="asap" formControlName="asap"
                                id="inlineRadio2" value="No" />
                            <label class="form-check-label" for="inlineRadio2">No</label>
                        </div>
                    </div>
                    <label class="mb-3">If not, please let us know a good time to call.</label>
                    <textarea class="mb-3" name="" id="" cols="50" rows="2" formControlName="text1"></textarea>
                    <label class="mb-3">Any additional information?</label>
                    <textarea class="mb-3" name="" id="" cols="50" rows="3" formControlName="text2"></textarea>
                    <div class="boton">
                        <button type="submit" [disabled]="!contactFrom.valid">Confirm</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
