import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private readonly http: HttpClient) { }

  getList() {
    return this.http.get(`${environment.url}/location`).toPromise();
  }

  search(search: any) {
    return this.http.post(`${environment.url}/flight-schedule/search`, search).toPromise();
  }


  getNationalityList() {
    return this.http.get(`${environment.url}/nationality`).toPromise();
  }

  createBooking(body: any) {
    return this.http.post(`${environment.url}/booking`, body).toPromise();
  }

  getSeatsAvailable(fligthId) {
    return this.http.get(`${environment.url}/flight-schedule/available/${fligthId}`).toPromise();
  }

  getValuePaymentByBookingRef(bookingRef: string) {
    return this.http.get(`${environment.url}/booking/booking-ref/${bookingRef}`).toPromise();
  }

  isGovernment(id:string) {
    return this.http.get(`${environment.url}/flight-schedule/government/${id}`).toPromise();
  }

  isGovernmentSchedule(id:string) {
    return this.http.get(`${environment.url}/flight-schedule/government-schedule/${id}`).toPromise();
  }

  paymentbooking(body: any) {
    return this.http.post(`${environment.url}/booking/payment-booking`, body).toPromise();
  }

  createFooterContact(body: any) {
    return this.http.post(`${environment.url}/contact/footer`, body).toPromise();
  }

  createContact(body: any) {
    return this.http.post(`${environment.url}/contact`, body).toPromise();
  }
}
