<div class="hero ">
    <img src="./assets/img/WhiteGloves/es_yacht.png" class="img-fluid" alt="">
</div>

<div class="privateAviation pt-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Yacht reservation</h1>
            </div>

            <div class="col-12 pt-5 pb-5 parrafo">
                <p class="tituloSecundario">For our clients who are in love with the ocean.</p>
                <p>
                    FronteraSky offers an unforgettable experience of sea voyage -  sailing or motor yachts accompanied by a skipper to explore the islands, picturesque bays, traditional fishing villages, sandy beaches, archaeological sites and the most beautiful fishing
                    and diving across the globe.
                    <br><br> The level of service on the yachts is bespoke and surpasses the service in the most luxurious hotels in the world. The multilingual crew is selected to accomodate all possible requests of our clients and features  the most
                    competent captains and chefs with numerous culinary awards to satisfy even your most sophisticated needs. Luxury yachts can be delivered to the shores of any country in the world and sail from any port of origin. The route will be
                    exclusively designed according to your wishes.
                    <br><br> VIP motor yachts are equipped with the latest advanced technology to guarantee the safety of the voyage: GPS, radar, echosounder, satellite communications, and Internet connection. The yachts are disinfected via the air-conditioning
                    system 24 hours a day!
                    <br><br> The guests may enjoy the private cinemas and executive coffee lounge rooms on board the vessels.  There are fitness centers onboard to charge our clients with vivacity and energy for the whole day, jacuzzi, spacious pools
                    with sea and fresh water, saunas with steam rooms, and spacious areas on the deck to relax after dinner in the company of the family and best friends.
                </p>
                <h5>In order to proceed with a yacht reservation, request a quote from our FronteraSky yacht charter consultant. Our dedicated team is eager to book your unforgettable voyage 24/7!</h5>
            </div>
        </div>
    </div>
</div>