import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-use',
  templateUrl: './terms-use.component.html',
  styleUrls: ['./terms-use.component.sass']
})
export class TermsUseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
