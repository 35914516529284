<div class="hero ">
    <picture>
        <source type="image/png" srcset="./assets/img/covid-notice1.png">
        <source type="image/jp2" srcset="/assets/img/Imágenes JPG 2/Escritorio/Noticia Covid 19/es-noticias-covid19.jp2">
        <img src="./assets/img/Escritorio/Noticia Covid 19/es-noticias-covid19.webp" alt="a butterfly">
    </picture>
</div>

<div class="mediacalourism pt-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>How private charter flights minimize contact with people and the risks of becoming infected with the COVID-19 coronavirus.</h1>
            </div>

            <div class="col-12 pt-5 pb-5">
                <p>
                    Despite attempts to localize the highly contagious coronavirus within China and Asia, the World Health Organization (WHO) has announced that an increase in the number of confirmed cases and the number of affected countries is “cause of concern”. It is
                    not surprising that amid this news, frequent travelers are interested in whether private.charter flights can really reduce the risks of infection and minimize contact with people.
                </p>
            </div>
        </div>

    </div>
</div>


<div class="advantages pb-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h4 class="title">How flying on private aircraft reduces the dissemination of infectious diseases such as Coronavirus COVID-19</h4>
            </div>
            <div class="col-12">
                <p>
                    Flights on private jets minimize the spread of disease, as take-off and landings take place at the least busy airports. Travelers and tourists significantly reduce the number of contacts with potentially infected people, since they do not need to go through
                    crowded checkpoints at airports, limited to special passport checks, and the planes themselves are much easier to disinfect after each flight. 
                </p>
            </div>
            <div class="col-12">
                <span>Flying with private jets reduces the speed of distribution of coronavirus in the following way:</span>
                <ul>
                    <li style="padding-top: 0;">
                        Private charter flights avoid the busiest airports. WHO recommends keeping the social distancing i.e. staying at least 1 meter away from those who sneeze or cough. This is one of the "main protective measures against the new coronavirus." However, it
                        is unfortunately impossible to maintain such a distance at the most congested airports that are used by the most of commercial airlines. And since private planes can use almost any airport (including local ones with shorter runways),
                        the passengers can avoid contact with potential carriers of the disease (for example, landing in New York not at Kennedy or LaGuardia airports, but at Teterboro).
                    </li>
                    <li>
                        Private flight passengers do not pass the scan. All passengers on commercial flights pass through checkpoints in one way or another. However, this rule does not apply to passengers of private flights, so they do not have to take off their shoes during
                        check-in, use common containers for their belongings and contact other travelers.

                    </li>
                    <li>One of the main advantages of private flights is VIP terminals, which significantly contributes to reducing contacts with potentially infected passengers. The passengers arrive directly to the specified terminal and relax before the
                        flight in an exclusive lounge that has been disinfected right before their arrival.
                    </li>
                    <li>
                        Direct access to the runway allows the chauffeur to transfer the VIP passenger directly to the jet. Many private charter flight companies provide this exclusive benefit to their passengers. This is done through an agreement with aviation consultants.
                        When flying on a private charter flight, you can independently choose those who fly with you. Instead of spending several hours in the airport crowded by travelers from around the world, you can choose who to add to the list of
                        passengers on your private flight and reduce the risk of coronavirus infection to zero.
                    </li>
                    <li>
                        Private flight passengers pass immigration and security checks at the separate airport facilities. At some airports, passport verification check is carried out on board of the aircraft itself.

                    </li>
                    <li>
                        A small number of the passengers and the size of the aircraft make disinfection easier. WHO has issued the updated guidelines for the regular cleaning of commercial aircraft, which, however, has little to do with the private aircraft. Private jets are
                        less likely to be in the air as much as commercial flight aircrafts and they carry significantly fewer passengers, which makes it easier to disinfect after each flight, eliminating the risk of potential infection.

                    </li>
                </ul>
            </div>
            <div class="col-12">
                <span>Aircraft disinfection procedures</span>
                <p>
                    The cabin is an enclosed space with the optimal conditions for the spread of the viruses. In flight, the air recirculates through a filtration system. The more people get on board a commercial aircraft, the higher the risk of catching an infection (not
                    necessarily a coronavirus) left by a previous passenger. The private jet has enough time on the ground for services to carry out detailed processing. COVID-19 can remain on surfaces for up to 9 days. During the preparation of the salon,
                    all hard and soft surfaces are thoroughly wiped with disinfectant compounds. Particular attention is given to the disinfection of the lavatory, handles, armrests, folding tables, and seat belts. The general hygiene
                    protocols system operating for the benefit of business aviation is also excellent for commercial flights. However, the passengers on private flights surely have more options of maintaining personal hygiene as well as reducing the
                    risk of being infected with COVID-19.
                </p>
                <p>
                    <span>Effective coronavirus prevention measures for private flights
                    </span>
                    <br> "The National Institutes of Allergenic and Infectious Diseases, in cooperation with the National Institutes of Health, are currently exploring new ways to treat and prevent COVID-19 and MERS infections by developing new antibodies,
                    medicine, and vaccines. While scientists are exploring potential treatment options, WHO is also issuing travel repatriation and quarantine recommendations for travelers, as well as general recommendations for passengers on safe flights.
                    The World Health Organization calls on everyone to take six steps to protect against coronavirus and other infectious diseases"
                </p>
                <ul>
                    <li class="color">
                        Wash your hands regularly in medium to large-sized aircraft with integrated restrooms. Regular hand washing with soap (or using sanitary napkins and antiseptics) is the easiest and most affordable way to prevent the contamination with virus.
                    </li>
                    <li class="color">
                        Avoid touching your eyes, nose, and mouth. Following this rule during flights is quite simple if you have a convenient climate control system installed on board, such as on board the Gulfstream G550, where you can customize it for your comfort.
                    </li>
                    <li class="color">
                        Observe respiratory hygiene. WHO representatives note that respiratory hygiene means the need to “cover your nose and mouth with handkerchief or mask while coughing or sneezing." It is quite difficult to get a new mask in the cramped seat of the commercial
                        aircraft, which cannot be said about the comfortable conditions of private planes with wide armchairs and sofas.

                    </li>
                    <li class="color">
                        Contact the local COVID-19 hotline if you feel unwell. If you have a fever, cough, or difficulty breathing, you should immediately consult a doctor. However, the Centers for Disease Control reports that symptoms may not appear within 14 days after infection,
                        which means that some infected travelers may do not show any symptoms. This is another reason why you should avoid crowds, especially at airports.

                    </li>
                    <li class="color">
                        Follow the news and COVID-19 updates by WHO in both departure point and your final destination. Any additional information can be provided by the carefully selected and professionally trained crew on your flight.
                    </li>
                </ul>
                <p>
                    Flying on private jets, as well as following hygiene rules, can minimize the spread of the disease. The benefits of flying private jets, which were designed for convenience, are now used to prevent the highly contagious disease.
                </p>
            </div>
        </div>
    </div>
</div>