import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PricingComponent } from './pricing/pricing.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ServicesComponent } from './services/services.component';
import { MainComponent } from '../main/main.component';
import { AirComparatorComponent } from './air-comparator/air-comparator.component';
import { ContactComponent } from './contact/contact.component';
import { HelicopterReservationComponent } from './helicopter-reservation/helicopter-reservation.component';
import { MedicalTourismComponent } from './medical-tourism/medical-tourism.component';

import { WhiteGlobeServicesComponent } from './white-globe-services/white-globe-services.component';
import { PrivateAircraftComponent } from './private-aircraft/private-aircraft.component';
import { BookingComponent } from './booking/booking.component';
import { BookingDetailsComponent } from './booking-details/booking-details.component';
import { BookingChairSelectionComponent } from './booking-chair-selection/booking-chair-selection.component';
import { BookingPaymentComponent } from './booking-payment/booking-payment.component';
import { BodyguardComponent } from './bodyguard/bodyguard.component';
import { ExclusiveCateringComponent } from './exclusive-catering/exclusive-catering.component';
import { YachtReservationComponent } from './yacht-reservation/yacht-reservation.component';
import { VipAirportComponent } from "./vip-airport/vip-airport.component";
import { PaymentComponent } from './payment/payment.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component'
import { TermsUseComponent } from './terms-use/terms-use.component'
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';
import { PalmbeachComponent } from './palmbeach/palmbeach.component';

const routes: Routes = [
  {
    path: 'pricing',
    component: MainComponent,
    children: [
      {
        path: '',
        component: PricingComponent,
      },
    ],
  },
  {
    path: 'aboutus',
    component: MainComponent,
    children: [
      {
        path: '',
        component: AboutusComponent,
      },
    ],
  },
  {
    path: 'services',
    component: MainComponent,
    children: [
      {
        path: '',
        component: ServicesComponent,
      },
    ],
  },
  {
    path: 'air-comparator',
    component: MainComponent,
    children: [
      {
        path: '',
        component: AirComparatorComponent,
      },
    ],
  },
  {
    path: 'contact',
    component: MainComponent,
    children: [
      {
        path: '',
        component: ContactComponent,
      },
    ],
  },
  {
    path: 'article-helicopter-reservation',
    component: MainComponent,
    children: [
      {
        path: '',
        component: HelicopterReservationComponent,
      },
    ],
  },
  {
    path: 'article-medical.tourism',
    component: MainComponent,
    children: [
      {
        path: '',
        component: MedicalTourismComponent,
      },
    ],
  },
  {
    path: 'article-Bodyguard',
    component: MainComponent,
    children: [
      {
        path: '',
        component: BodyguardComponent,
      },
    ],
  },
  {
    path: 'article-Exclusive-catering',
    component: MainComponent,
    children: [
      {
        path: '',
        component: ExclusiveCateringComponent,
      },
    ],
  },
  {
    path: 'article-Yacht-reservation',
    component: MainComponent,
    children: [
      {
        path: '',
        component: YachtReservationComponent,
      },
    ],
  },
  {
    path: 'article-Vip-airport',
    component: MainComponent,
    children: [
      {
        path: '',
        component: VipAirportComponent,
      },
    ],
  },
  {
    path: 'private-aircraft',
    component: MainComponent,
    children: [
      {
        path: '',
        component: PrivateAircraftComponent
      },
    ],
  },

  {
    path: 'white-globe-services',
    component: MainComponent,
    children: [
      {
        path: '',
        component: WhiteGlobeServicesComponent,
      },
    ],
  },
  {
    path: 'booking',
    component: MainComponent,
    children: [
      {
        path: '',
        component: BookingComponent
      },
    ],
  },
  {
    path: 'booking-datails',
    component: MainComponent,
    children: [
      {
        path: '',
        component: BookingDetailsComponent
      },
    ],
  },
  {
    path: 'booking-chair',
    component: MainComponent,
    children: [
      {
        path: '',
        component: BookingChairSelectionComponent
      },
    ],
  },
  {
    path: 'payment',
    component: MainComponent,
    children: [
      {
        path: '',
        component: PaymentComponent
      }
    ]
  },
  {
    path: 'booking-pay',
    component: MainComponent,
    children: [
      {
        path: '',
        component: BookingPaymentComponent
      },
    ],
  },
  {
    path: 'policy-privacy',
    component: MainComponent,
    children: [
      {
        path: '',
        component: PrivacyPolicyComponent
      },
    ],
  },
  {
    path: 'cookies-policy',
    component: MainComponent,
    children: [
      {
        path: '',
        component: CookiesPolicyComponent
      },
    ],
  },
  {
    path: 'terms-of-use',
    component: MainComponent,
    children: [
      {
        path: '',
        component: TermsUseComponent
      },
    ],
  },
  {
    path: 'palmbeach1.html',
    component: PalmbeachComponent,
    // children: [
    //   {
    //     path: '',
    //     component: PalmbeachComponent
    //   },
    // ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule { }
