import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AppService } from './../../../services/app.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass'],
})
export class ContactComponent implements OnInit {
  contactFrom = new FormGroup({
    title: new FormControl(null, [Validators.required]),
    fistName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required]),
    office: new FormControl(''),
    asap: new FormControl('', [Validators.required]),
    text1: new FormControl(''),
    text2: new FormControl(''),
  });

  constructor(private readonly _appService: AppService) {}

  ngOnInit(): void {}

  sendData() {
    const form = this.contactFrom.value;
    this._appService.createContact(form).then((response) => {
      console.log(response);
      this.contactFrom.reset();
      Swal.fire(
        'Confirmation',
        'information has been sent successfully',
        'success'
      );
    }).catch((error) => {
      console.log(error);
    });
  }
}
