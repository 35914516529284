<div class="hero ">
    <img src="./assets/img/bannerHelicopter.png" class="img-fluid" alt="">

    <div class="container">
        <h1>Helicopter reservation</h1>
    </div>
</div>

<div class="privateAviation pt-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Using private aviation is a convenient travel option that saves time.</h1>
            </div>

            <div class="col-12 pt-5 pb-5">
                <h5>
                    Our company has made private helicopter rental more affordable and easier. FronteraSky is a professional private aviation booking platform with access to over 5000 companies worldwide that provides the services of helicopter reservation and private jet
                    flights. We offer domestic flights and international trips with full support for each flight. Private helicopter transfer with FronteraSky comes with a guarantee of comfort, safety and unforgettable experience. of the aerial panoramic
                    flight.

                </h5>
            </div>
        </div>
        <div class="row pt-5 pb-5">
            <div class="col-12">
                <p class="title"> When is it convenient to use a helicopter?</p>
            </div>
            <div class="col-12">
                <ul>
                    <li>Business trip, airport transfer of the employees to particular location of the conference or business meeting.</li>
                    <li>Helicopter flight on tourist routes. </li>
                    <li>Private helicopter flight to exclusive hunting or fishing location.</li>
                    <li>Medical evacuation from remote locations. </li>
                    <li>Patrolling and guarding territories.</li>
                    <li>Aerial photography and video shooting helicopter flight.</li>
                    <li> Mountain Tourism helicopter charter flight. In recent years, the popularity of unusual extreme entertainment - heli-skiing - has been growing. Private helicopter takes our VIP passengers to the most exclusive areas of skiing and snowboarding
                        to experience the descending along the beautiful slopes of the mountains.</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="benefits popular">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <p class="title"> Benefits of helicopter reservation service with FronteraSky</p>
            </div>
            <div class="col-12">
                <ul>
                    <li>FronteraSky private helicopter flight is a comfortable trip with the professional crew for personal and corporate purposes: intercity flights, airport business transfer, helicopter trips to the mountains and remote areas. In some cases,
                        helicopter charter remains the only way to get to the right place. The helicopter has many advantages over the car.</li>
                    <li>Available routes of any complexity and range.</li>
                    <li>Up to six passengers onboard in one trip.</li>
                    <li>One of the biggest selections of private helicopters charter options worldwide, helicopters with different capacities and technical specifications. Convenience. The comfort of the helicopter charter depends solely on the preferences
                        of the passengers and the purpose of the flight.
                    </li>
                    <li>Maneuverability. For takeoff and landing, the helicopter does not need a runway, as for an airplane and it can fly just a few meters from the ground. Landing is possible in any permitted place.</li>
                    <li>Safety and reliability. We offer only safe VIP transportation at the highest level of comfort and quality. All vehicles are certified and regularly undergo technical inspections. The crew has medical examinations on regular basis.
                        Each route is pre-worked out by the dispatcher, and then agreed with the passengers.</li>
                </ul>
            </div>
        </div>


    </div>
</div>