<div class="hero ">
</div>

<div class="popular">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <h4>Cookies Policy.</h4>
            </div>
        </div>
    </div>
</div>

<div class="">
    <div class="container pt-5 pb-5">
        <div class="row">

            <div class="col-12">
                <p class="parrafos">
                    To make this site work properly, we sometimes place small data files called cookies on your device. Most big websites do this too.
                    <br><br>
                    <b> What are cookies?</b><br> A cookie is a small text file that a website saves on your computer or mobile device when you visit the site. It enables the website to remember your actions and preferences
                    (such as login, language, font size and other display preferences) over a period of time, so you don’t have to keep re-entering them whenever you come back to the site or browse from one page to another.
                    <br><br>
                    <b> How do we use cookies?</b><br> FronteraSky uses or may use cookies and/or web beacons to help us identify repeat visitors, the time users spend on different areas of our Site, as well as specific functionalities
                    that users choose to use. If cookies data constitutes personally identifiable information, we process such data on the basis of your consent.
                    <br><br>
                </p>
                <p class="textoAzul">
                    <b> Example:</b><br> A number of our pages use cookies to remember:<br> • your display preferences, such as contrast color settings or font size.
                    <br> • If you have already replied to a survey pop-up that asks you if the content was helpful or not (so you won't be asked again).<br> • If you have agreed (or not) to our use of cookies on this site.
                    Also, some videos embedded in our pages use a cookie to anonymously gather statistics on how you got there and what videos you visited.<br> Enabling these cookies is not strictly necessary for the website
                    to work but it will provide you with a better browsing experience. You can delete or block these cookies, but if you do that some features of this site may not work as intended.<br> The cookie-related information is
                    not used to identify you personally and the pattern data is fully under our control. These cookies are not used for any purpose other than those described here.
                </p>
                <p class="parrafos">
                    <b> How to control cookies</b><br> You can control and/or delete cookies as you wish – for details, see aboutcookies.org. You can delete all cookies that are already on your computer and you can set most
                    browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.
                </p>
                <button type="button" class="btn btn-primary" (click)="saveCookies()">Accept</button>
            </div>
        </div>
    </div>
</div>