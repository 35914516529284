import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from '../../../services/app.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { addMinutes } from 'date-fns';
import { format } from 'date-fns';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.sass']
})
export class BookingComponent implements OnInit {

  elements: any;
  typeOneWay: boolean = true;
  departureSchedules: any;
  returnSchedule: any;
  showReturn = false;
  showReturnContainer = false;
  selectedOneOption = 'active';
  selectedReturnOption = '';
  selectedFromSchedule: any;
  selectedToSchedule: any;
  priceCalculate: any;
  countSeats = 0;
  seatsType = 'economy';
  calendary: any;
  closeResult = '';
  minDate: any;

  elemForm = new FormGroup({
    fromLocationId: new FormControl('', Validators.required),
    toLocationId: new FormControl('', Validators.required),
    departureDate: new FormControl('', Validators.required),
    returnDate: new FormControl(''),
    adult: new FormControl(1),
    child: new FormControl(0),
    infant: new FormControl(0),
    type: new FormControl(''),
    seatsT: new FormControl('economy')
  })

  constructor(private readonly service: AppService, private readonly router: Router) { }

  async ngOnInit() {
    this.elements = await this.service.getList();
    this.minDate = new Date();
    this.minDate.setMinutes(0);
    this.minDate.setHours(8);
  }

  async send() {
    const form = this.elemForm.value;
    form.type = (this.typeOneWay) ? 'oneWay' : 'returnWay';
    form.departureDate = format(new Date(form.departureDate), 'yyyy-MM-dd');
    form.returnDate = (form.returnDate) ? format(new Date(form.returnDate), 'yyyy-MM-dd') : form.returnDate;
    const doc: any = await this.service.search(form);
    if (doc.departureSchedules.length <= 0) {
      Swal.fire({
        title:'',
        text: 'No results found',
        icon: 'info'
      });
    } else {
      this.departureSchedules = doc.departureSchedules;
    }
    this.countSeats = parseInt(form.adult) + parseInt(form.child) + parseInt(form.infant)
    if (!this.typeOneWay) {
      if (doc.returnSchedules.length <= 0) {
        Swal.fire({
          title:'',
          text: 'No results found',
          icon: 'info'
        });
      } else {
        this.returnSchedule = doc.returnSchedules;
        this.showReturn = true;
      }
    } else {
      this.returnSchedule = null;
      this.showReturn = false;
      this.showReturnContainer = false;
    }
  }

  changeType(type: string) {
    if ( type === 'oneWay' ) {
      this.typeOneWay = true;
    } else if ( type === 'returnWay' ) {
      this.typeOneWay = false;
    }
  }

  create() {
    this.router.navigate([`/booking-ticket/create`]);
  }

  modify(id: string) {
    this.router.navigate([`/booking-ticket/modify/${id}`]);
  }

  calculateHour(date: string, minutes: string) {
    const dateString = addMinutes(new Date(date), parseInt(minutes))
    return dateString
  }

  changeView(type: string) {
    if (type == 'Departure') {
      this.showReturnContainer = false;
      this.selectedOneOption = 'active';
      this.selectedReturnOption = '';
    } else {
      this.showReturnContainer = true;
      this.selectedReturnOption = 'active';
      this.selectedOneOption = '';
    }
  }

  changeTypeSeats(seatsT: string) {
    this.seatsType = seatsT;
    this.calculatePrice();
  }

  selectFromSchedule(item: any) {
    this.selectedFromSchedule = item;
    this.calculatePrice()
  }

  selectToSchedule(item: any) {
    this.selectedToSchedule = item;
    this.calculatePrice()
  }

  calculatePrice() {
    console.log(this.selectedFromSchedule);
    if (this.typeOneWay) {
      if (this.selectedFromSchedule) {
        if (this.seatsType == 'economy') {
          this.priceCalculate = this.countSeats * parseInt(this.selectedFromSchedule.priceEconomy)
        } else if (this.seatsType == 'business') {
          this.priceCalculate = this.countSeats * parseInt(this.selectedFromSchedule.priceBusiness)
        }
      }
    } else {
      if (this.selectedFromSchedule && this.selectedToSchedule) {
        if (this.seatsType == 'economy') {
          const temp1 = this.countSeats * parseInt(this.selectedFromSchedule.priceEconomy)
          const temp2 = this.countSeats * parseInt(this.selectedToSchedule.priceEconomy)
          this.priceCalculate = temp1+temp2
        } else if (this.seatsType == 'business') {
          const temp1 = this.countSeats * parseInt(this.selectedFromSchedule.priceBusiness)
          const temp2 = this.countSeats * parseInt(this.selectedToSchedule.priceBusiness)
          this.priceCalculate = temp1+temp2
        }
      }
    }
  }

  clearSelected() {
    this.selectedFromSchedule = null;
    this.selectedToSchedule = null;
  }

  minutesToHours(m: string):string {
    const num = m;
    var hours = (parseInt(num) / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + "h : " + rminutes + "min";
  }

  resetAll() {
    this.departureSchedules = null;
    this.returnSchedule =  null;
    this.priceCalculate = null;
    this.clearSelected()
    this.typeOneWay = true;
    this.elemForm.reset();
  }


  continue() {
    const { carryOnBaggage, checkedBaggage, date } = this.selectedFromSchedule;
    const airplaneTo = (this.selectedToSchedule) ? this.selectedToSchedule : {};
    const newObj = {
      seats: this.countSeats,
      type: (this.typeOneWay) ? 'oneWay' : 'returnWay',
      fromSchedule: this.selectedFromSchedule._id,
      locationCombination: (this.selectedFromSchedule.locationCombinationDoc) ? this.selectedFromSchedule.locationCombinationDoc.code : this.selectedFromSchedule.fromLocation.airportName ,
      fromDate: date,
      toDate: airplaneTo.date,
      carryOnBagageFrom: carryOnBaggage,
      checkedBagageFrom: checkedBaggage,
      carryOnBagageTo: airplaneTo.carryOnBaggage,
      checkedBagageTo: airplaneTo.checkedBaggage,
      toSchedule: (this.selectedToSchedule) ? this.selectedToSchedule._id: null,
      totalAmounth: this.priceCalculate
    }
    this.router.navigate([`/booking-datails/`, newObj]);
  }



}
